import React, { useEffect, useState } from "react";
import uuid4 from "uuid4";

import axios from "axios";

import { Box, Button, FormControl } from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DataTable } from "elements/dataTable";

import {
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const ConsultaLimite = () => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              allColumns: true,
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [dateFrom, setDateFrom] = useState(dayjs());
  const handleChangeDateFrom = (newValue) => {
    setDateFrom(dayjs(newValue));
  };

  const [dateTo, setDateTo] = useState(dayjs());
  const handleChangeDateTo = (newValue) => {
    setDateTo(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState("");
  const handleChangePaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
    console.log(event.target.value);
  };

  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const handleChangeAssignee = (event) => {
    setSelectedAssignee(event.target.value);
  };

  const [issuers, setIssuers] = useState([]);

  const [objetoLimites, setObjetosLimites] = useState([]);

  const columns = [
    {
      field: "cessionario",
      headerName: "Cessionário",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ica",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "emissor",
      headerName: "Emissor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "reservado",
      headerName: "Reservado",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        // Arredonda o valor de string para inteiro para poder formatar

        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "limite",
      headerName: "Limite",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }

        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "disponivel",
      headerName: "Disponivel",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }

        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "observacao",
      headerName: "Observação",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const getName = (aId, aList) => {
    for (const a of aList) {
      if (a.value === aId) {
        return a.label;
      }
    }
    return "";
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  const fetchContracts = async (aPage) => {
    try {
      setLoading(true);

      // Opcional: bandeira
      let params = {};
      if (currentPaymentNetwork.length > 0) {
        params = {
          paymentNetwork: currentPaymentNetwork,
        };
      }

      /*  const result = await fetchData(
               `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservations/${dateFrom.format(
                 'YYYY-MM-DD'
               )}/${dateTo.format('YYYY-MM-DD')}`,
               params
             ); */

      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          `/tr/depositary-bank/credit-reservations/${dateFrom.format(
            "YYYY-MM-DD"
          )}/${dateTo.format("YYYY-MM-DD")}`,
        params
      );

      const groupedData = {};

      result.forEach((item) => {
        const key = `${item.body.assigneeAccountId}-${item.body.cardIssuerId}`;

        if (!groupedData[key]) {
          groupedData[key] = {
            id: uuid4(),
            cessionario: item.body.assigneeAccountId,
            ica: item.body.cardIssuerId,
            emissor: item.body.cardIssuerId,
            reservado: item.body.amount,
          };
        } else {
          // Verifique se a propriedade "reservado" existe antes de somar
          if (!groupedData[key].reservado) {
            groupedData[key].reservado = 0;
          }
          groupedData[key].reservado += item.body.amount;
        }
      });

      const resultado = Object.values(groupedData);
      console.log("FETCH TODOS OS CONTRATOS:", resultado);
      return resultado;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLimiteCessionario = async (aPage) => {
    setData([]);
    try {
      const params = {
        method: "GET",
        /* url: 'https://backend.entrepay.com.br/integrations-homolog/limite/limiteCessionario', */
        url:
          process.env.REACT_APP_CLIENT_INTEGRATION_URL +
          "/limite/limiteCessionario",
        params: {
          page: 100000,
          token: "",
          /* createTimestamp: dateTo.format('YYYY-MM-DD'), */
          cessionario: selectedAssignee,
        },
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios(params);
      console.log(
        "RESULTADO FETCH LIMITE CESSIONARIO DO INTEGRATIONS:",
        res.data
      );
      return res.data;
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks' */
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees' */
        process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees"
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      console.log(d);
      setAssignees(d);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores
  const fetchCardcardIssuers = async () => {
    try {
      let responseGetCardIssuers = [];
      setLoading(true);

      for (let i = 0; i < paymentNetworks.length; i++) {
        console.log("payment network varrido:", paymentNetworks[i]);
        /* const result = await fetchData(
                  'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
                  {
                    paymentNetwork: paymentNetworks[i].value,
                  }
                ); */
        const result = await fetchData(
          process.env.REACT_APP_CLIENT_BTG +
            "/tr/depositary-bank/card-issuer/search",
          {
            paymentNetwork: paymentNetworks[i].value,
          }
        );
        responseGetCardIssuers.push(result);
        console.log(responseGetCardIssuers);
      }
      for (let i = 0; i < responseGetCardIssuers.length; i++) {
        const d = responseGetCardIssuers[i].map((item) => {
          return {
            value: item.cardIssuerId,
            label: `${item.name} - ${item.paymentNetwork}`,
          };
        });

        setIssuers((prevCardIssuers) => [...prevCardIssuers, ...d]);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      setIssuers([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCardIssuersByPaymentNetwork = async (aPaymentNetwork) => {
    /* const result = await fetchData(
          'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
          {
            paymentNetwork: aPaymentNetwork,
          }
        ); */
    const result = await fetchData(
      process.env.REACT_APP_CLIENT_BTG +
        "/tr/depositary-bank/card-issuer/search",
      {
        paymentNetwork: aPaymentNetwork,
      }
    );

    const d = result.map((item) => {
      return {
        value: item.cardIssuerId,
        label: item.name,
      };
    });
    return d;
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleSearch = async (event) => {
    try {
      setLoading(true);
      const resultFetchLimiteCessionario = await fetchLimiteCessionario();
      const resultFetchContracts = await fetchContracts();

      const arrayResultados = [];

      resultFetchContracts.forEach((dataContratos) => {
        // Verificar se há um registro correspondente em resultFetchLimiteCessionario
        const matchingCessionarioData = resultFetchLimiteCessionario.filter(
          (item) =>
            (item.cessionario === dataContratos.cessionario &&
              item.emissor === dataContratos.emissor) ||
            (item.cessionario === dataContratos.cessionario &&
              item.emissor === "Todos")
        );

        if (matchingCessionarioData.length > 0) {
          // Encontrou correspondência, encontre o objeto com lastUpdateTimestamp mais recente
          const latestCessionarioData = matchingCessionarioData.reduce(
            (prev, current) =>
              prev.lastUpdateTimestamp > current.lastUpdateTimestamp
                ? prev
                : current
          );

          console.log(latestCessionarioData);

          const novoObjetoData = {
            cessionario: dataContratos.cessionario,
            ica: dataContratos.emissor,
            emissor: dataContratos.emissor,
            limite: latestCessionarioData.limite,
            observacao: latestCessionarioData.observacao,
            disponivel: latestCessionarioData.limite - dataContratos.reservado,
            reservado: dataContratos.reservado,
          };

          arrayResultados.push(novoObjetoData);
        } else {
          // Se não houver correspondência, adicionar diretamente
          const novoObjetoData = {
            cessionario: dataContratos.cessionario,
            ica: dataContratos.emissor,
            emissor: dataContratos.emissor,
            reservado: dataContratos.reservado,
          };

          arrayResultados.push(novoObjetoData);
        }
      });

      console.log("Array final:", arrayResultados);
      setObjetosLimites(arrayResultados);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * useEffect
   */
  // Inicial
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardcardIssuers();
  }, [paymentNetworks]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"De*"}
              value={dateFrom}
              onChange={handleChangeDateFrom}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"Até*"}
              value={dateTo}
              onChange={handleChangeDateTo}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        {/* <FormControl fullWidth>
          <InputLabel>Bandeira</InputLabel>
          <Select
            value={currentPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        {/* <FormControl fullWidth>
          <InputLabel>Cessionário</InputLabel>
          <Select
            required
            value={selectedAssignee}
            label="Emissor"
            onChange={(e) => handleChangeAssignee(e)}
            sx={{
              width: '100%',
            }}
          >
            {assignees.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              minWidth: 50,
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Pesquisar
          </Button>
        </FormControl>
      </Box>

      <DataTable
        rows={
          objetoLimites.map((item, index) => ({
            ...item,
            id: item.id,
            cessionario: getName(item.cessionario, assignees),
            emissor:
              item.emissor === "Todos"
                ? item.emissor
                : getName(item.emissor, issuers),
            Limite: item.limite,
            disponivel: item.disponivel,
            observacao: item.observacao,
          })) ?? []
        }
        columns={columns}
        getRowId={(row) => generateRandomId()}
      />
    </Box>
  );
};
