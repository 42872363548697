import axios from "axios";

async function request(url, method, aToken, body, config) {
  const configHeaders = config && config.headers ? config.headers : undefined;
  let configCopy =
    config && config.headers ? Object.assign({}, config) : undefined;
  if (configCopy) delete configCopy.headers;

  const params = {
    method: method,
    url: url,
    data: body,
    headers: configHeaders
      ? {
          Authorization: `Bearer ${aToken}`,
          ...configHeaders,
        }
      : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${aToken}`,
        },
    ...configCopy,
  };
  const res = await axios(params);
  return res.data;
}

export default request;
