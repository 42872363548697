import React from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline";
import { Signin } from "pages/auth/signin";
import { Fragment } from "react";
import { createMsalInstance } from "@crema/utility/AuthHooks";
import { ToastContainer } from "react-toastify";
import { tokenRequest } from "shared/constants/msalConfig";
import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Contents } from "elements/contents";
import "@fontsource-variable/inter";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const App = () => {
  const msalInstance = createMsalInstance();
  const myTheme = require("./theme.json");

  // This is weird but Microsoft login redirects back to the site and expects it to create the object before it closes the popup.
  // See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
  if (window.location.hash.includes("id_token")) {
    try {
      msalInstance.loginPopup(tokenRequest).then((response) => {
        window.close();
      });
    } catch (error) {
      console.log(error);
    }
  }

  const theme = createTheme(myTheme);

  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <CssBaseline />
          <Contents />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Signin />
        </UnauthenticatedTemplate>
      </MsalProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
