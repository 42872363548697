import React, { useEffect, useState } from "react";
import { plain } from "shared/utils/plain";
import uuid4 from "uuid4";

import axios from "axios";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataTable } from "elements/dataTable";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const Saldo = () => {
  const [loading, setLoading] = useState(false);

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState("");
  const handleChangeCurrentPaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
  };

  const [cardIssuers, setCardIssuers] = useState([]);
  const [currentCardIssuer, setCurrentCardIssuer] = useState("");
  const handleChangeCurrentCardIssuer = (event) => {
    setCurrentCardIssuer(event.target.value);
  };

  // Data Limite
  const [dateTo, setDateTo] = React.useState(dayjs()); // Data Inicio
  const handleChangeDate = (newValue) => {
    setDateTo(newValue);
  };

  const [data, setData] = React.useState([]);

  const columns = [
    {
      field: "emissor",
      headerName: "Emissor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ica",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "data",
      headerName: "Data",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "bandeira",
      headerName: "Bandeira",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "provisionado",
      headerName: "Provisionado",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "liquidado",
      headerName: "Reservado",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "aberto",
      headerName: "Em Aberto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  // token da autenticação
  const { getToken } = useAuthMethod();

  const handleSearch = async () => {
    fetchBalance();
  };

  // Função de comparação pelo nome
  // usada para ordenar a lista
  function compare(a, b) {
    if (plain(a.label) < plain(b.label)) {
      return -1;
    }
    if (plain(a.label) > plain(b.label)) {
      return 1;
    }
    return 0;
  }

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchCardIssuers = async (aPaymentNetwork) => {
    try {
      setLoading(true);

      /* const result = await fetchData(
              'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
              {
                paymentNetwork: aPaymentNetwork,
              }
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/card-issuer/search",
        {
          paymentNetwork: aPaymentNetwork,
        }
      );

      const d = result.map((item) => {
        return {
          value: item.cardIssuerId,
          label: item.name,
        };
      });

      console.log(d);

      return d;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      /* const result = await fetchData(
              'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks'
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCardIssuerName = (id) => {
    for (const i of cardIssuers) {
      if (id === i.value) {
        return i.label;
      }
    }
    return "";
  };

  const fetchBalance = async () => {
    try {
      setLoading(true);

      let params = {};
      if (currentPaymentNetwork.length > 0) {
        params = {
          paymentNetwork: currentPaymentNetwork,
        };
      }

      /* const result = await fetchData(
              `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservation/balance/${dateTo.format(
                'YYYY-MM-DD'
              )}`,
              params
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          `/tr/depositary-bank/credit-reservation/balance/${dateTo.format(
            "YYYY-MM-DD"
          )}`,
        params
      );
      // console.log(params);
      // console.log(result);

      const d = result.map((item) => {
        return {
          id: uuid4(),
          emissor: getCardIssuerName(item.cardIssuerId),
          ica: item.cardIssuerId,
          data: item.date,
          bandeira: item.paymentNetwork,
          provisionado: item.provisioned / 100,
          liquidado: item.reserved / 100,
          aberto: item.balance / 100,
        };
      });
      console.log(result.errors);
      setData(d);
    } catch (error) {
      console.log(error.message);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateCardIssuers = async () => {
    try {
      // // se não há dados, não precisa puxar os emissores
      // if (data.length === 0) {
      //   return;
      // }

      // // se tem bandeira selecionada, então puxa os emissores dessa bandeira
      // if (currentPaymentNetwork.length > 0) {
      //   const d = await fetchCardIssuers(currentPaymentNetwork);
      //   setCardIssuers(d);
      // } else {
      // se não seleciona bandeira

      // Tem que trazer a lista completa de todos os emissores
      const issuers = [];
      for (const pn of paymentNetworks) {
        const d = await fetchCardIssuers(pn.value);

        // Para não adicionar os repetidos
        for (const i of d) {
          let found = false;
          for (const issuer of issuers) {
            if (i.value === issuer.value) {
              found = true;
            }
          }
          if (!found) {
            issuers.push(i);
          }
        }
      }
      setCardIssuers(issuers);
      // }
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
    }
  };

  // atualiza lista de emissores quando busca dados
  useEffect(() => {
    updateCardIssuers();
  }, [paymentNetworks]);

  useEffect(() => {
    setCurrentCardIssuer("");
  }, [cardIssuers]);

  // pega lista de bandeiras
  useEffect(() => {
    fetchPaymentNetworks();
  }, []);

  const fetchData = async (aUrl, aParams = {}) => {
    try {
      setLoading(true);
      // token do ad
      const token = await getToken();

      const params = {
        method: "GET",
        url: aUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: aParams,
      };
      const res = await axios(params);
      return res.data.body;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        padding: 2,
        width: "100%",
        gap: 2,
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          width: "100%",
          gap: 10,
        }}
      >
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 120 } }}
              inputFormat="DD/MM/YYYY"
              label="Até*"
              value={dateTo}
              onChange={(value) => handleChangeDate(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl>
          <InputLabel>Bandeira</InputLabel>
          <Select
            value={currentPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangeCurrentPaymentNetwork(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {paymentNetworks.sort(compare).map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            m: 1,
            minWidth: 150,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "secondary.contrastText",
            bgcolor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
        >
          Pesquisar
        </Button>
      </Box>

      {/* {cardIssuers.length > 0 && (
        <Box>
          <FormControl>
            <InputLabel>Emissor</InputLabel>
            <Select
              value={currentCardIssuer}
              label="Emissor"
              onChange={(e) => handleChangeCurrentCardIssuer(e)}
              sx={{
                width: 200,
              }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {cardIssuers.sort(compare).map((item, index) => (
                <MenuItem key={index} value={item.cardIssuerId}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )} */}

      <DataTable rows={data} columns={columns} />
    </Box>
  );
};
