import React, { useState } from "react";
import { DataTable } from "elements/dataTable";

import dayjs from "dayjs";
import { Box, Button, Card, FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const ConsultaConfirmacaoAgenda = () => {
  // token da autenticação
  const { getToken } = useAuthMethod();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // Data Início
  const [valueDataInicio, setDataInicio] = React.useState(dayjs()); // Data Inicio
  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue);
  };

  // Data Fim
  const [valueDataFim, setDataFim] = React.useState(dayjs()); // Data Fim
  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue);
  };

  ///DATA GRID
  const columnsRecebimento = [
    {
      field: "pactualId",
      headerName: "pactualId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "clientRequestId",
      headerName: "clientRequestId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createTimestamp",
      headerName: "createTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs.utc(value).format("DD-MM-YYYY HH:mm:ss");
      },
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).utc().format("DD-MM-YYYY HH:mm:ss");
      },
    },
    {
      field: "entity",
      headerName: "entity",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "description",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "importType",
      headerName: "importType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fileName",
      headerName: "fileName",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "importSubType",
      headerName: "importSubType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      // Pega o token do login para passar na chamada
      const token = await getToken();
      const resultImportFile = await sendRequest(token);
      console.log("RESULT IMPORT FILE:", resultImportFile);

      const groupedByPactualId = resultImportFile.reduce((acc, current) => {
        acc[current.pactualId] = acc[current.pactualId] || [];
        acc[current.pactualId].push(current);
        return acc;
      }, {});

      // Filtra os itens mantendo apenas o mais recente por pactualId
      const filteredData = Object.values(groupedByPactualId).map((group) => {
        return group.reduce((prev, current) =>
          new Date(prev.lastUpdateTimestamp) >
          new Date(current.lastUpdateTimestamp)
            ? prev
            : current
        );
      });

      console.log("Filtered Data:", filteredData);

      setData(filteredData);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // TODO: mudar e pegar do nosso banco de dados, alimentado pelo webhook
  const sendRequest = async (aToken, aPage = 0) => {
    setData([]);
    try {
      setLoading(true);
      let array = [];

      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity: "listImportFile" + process.env.REACT_APP_ENVIROMENT,
            dataInicial: valueDataInicio.format("'YYYY-MM-DD'"),
            dataFinal: valueDataFim.format("'YYYY-MM-DDT23:59:59[Z]'"),
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      return newArray;
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      id="CONTAINER"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        padding: 2,
        borderBottom: `dashed 1px `,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        id="CONTROLE"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "left",
          textAlign: "left",
          padding: 2,
          borderBottom: `dashed 1px `,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
            padding: 2,
            width: "100%",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                label="Data de início"
                value={valueDataInicio}
                onChange={(value) => handleChangeDataInicio(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                value={valueDataFim}
                label="Data final"
                onChange={(value) => handleChangeDataFim(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            m: 1,
            minWidth: 50,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "secondary.contrastText",
            backgroundColor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
        >
          Pesquisar
        </Button>
      </Box>

      {/* tabela com as transações */}
      <Card
        sx={{
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          padding: 2,
          borderRadius: "16px",
          width: "100%",
          height: "100%",
        }}
      >
        <DataTable
          rows={
            data.map((item, index) => ({
              ...item,
              pactualId: item.pactualId,
              clientRequestId: item.clientRequestId,
              createTimestamp: item.createTimestamp,
              lastUpdateTimestamp: item.lastUpdateTimestamp,
              entity: item.entity,
              status: item.status,
              description: item.description,
              importType: item.importType,
              fileName: item.fileName,
              importSubType: item.importSubType,
            })) ?? []
          }
          columnVisibilityModel={{
            pactualId: true,
            clientRequestId: true,
            createTimestamp: true,
            lastUpdateTimestamp: true,
            entity: false,
            status: true,
            description: true,
            importType: true,
            fileName: false,
            importSubType: true,
          }}
          columns={columnsRecebimento}
          getRowId={(row) => generateRandom()}
          pageSize={15}
        />
      </Card>
    </Box>
  );
};
