import React from "react";
import { useField } from "formik";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";

const FormikSelect = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <>
      <Select {...props} {...field} error={!!errorText}>
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {!props.disabled && (
        <FormHelperText style={{ color: "#f44336" }}>
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default FormikSelect;

FormikSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
