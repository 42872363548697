import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import formatCnpj from "shared/utils/formatCnpj";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const columns = [
  { id: "CNPJ", label: "CNPJ", minWidth: 170 },
  { id: "Nome", label: "Nome", minWidth: 100 },
  { id: "Status", label: "Status", minWidth: 100 },
  { id: "Ações", label: "Ações", minWidth: 100 },
];

const ListProposal = () => {
  const [proposalList, setProposalList] = useState([]);
  const [countPage, setCountPage] = React.useState({ length: 0 });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    listProposal(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  // vai buscar na api a lista das análises de pricing
  const getSearchProposal = async function () {
    let leads;
    const token = await getToken();
    try {
      if (search == "") {
        listProposal(0);
      } else {
        setLoading(true);
        setPage(0);
        const result = await request(
          process.env.REACT_APP_API_URL + `/cnpj/${search}/${"onboarding"}`,
          "get",
          token
        );
        leads = result;
        setProposalList(leads);
      }
    } catch (error) {
      console.error(error);
      setProposalList([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataForPage = async (page) => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/leads/onboading?page=${page}`,
        "get",
        token
      );
      ///const leads = sortBy(result, (v) => v.analysis?.statusRisk);
      const resultCount = await request(
        process.env.REACT_APP_API_URL + `/leads/onboarding/count`,
        "get",
        token
      );
      setCountPage({ length: resultCount });
      setProposalList(result);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  // vai buscar na api a lista das análises de pricing
  const listProposal = async function (page) {
    const token = await getToken();
    try {
      setLoading(true);
      await fetchDataForPage(page);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setProposalList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (id, status) => {
    setLoading(true);
    const token = await getToken();
    try {
      await request(
        process.env.REACT_APP_API_URL + `/proposal/${id}`,
        "put",
        token,
        {
          status,
        }
      );
      toast.success("Status atualizado com sucesso.");
      listProposal(0);
    } catch (error) {
      console.error(error);
      toast.error("Error ao atualizar status");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal(0);
  }, []);

  const handleRowClick = function (aId) {
    navigate(`/onboarding/proposals/currentProposal/${aId}`);
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Consultar propostas
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <PatternFormat
            required
            customInput={TextField}
            style={{ height: "30px", marginRight: "10px" }}
            value={search}
            allowLeadingZeros
            format="##.###.###/####-##"
            allowEmptyFormatting
            onValueChange={(values, sourceInfo) => {
              setSearch(values.value);
            }}
            mask="_"
          />

          <Button
            onClick={getSearchProposal}
            sx={{ backgroundColor: "#000536", mb: 5, mt: 1 }}
            variant="contained"
            small
          >
            Buscar
          </Button>
        </Box>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#000536",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {proposalList
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const analysis = row?.analysis;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                      <TableCell align="center">
                        {formatCnpj(row?.cnpj)}
                      </TableCell>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">
                        {analysis?.status?.toUpperCase()}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Button
                          onClick={() => handleRowClick(analysis?.id)}
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                          }}
                          variant="contained"
                          size="small"
                          disabled={
                            analysis?.statusForecastCompleted?.toUpperCase() !==
                              "COMPLETO" ||
                            (analysis?.statusRisk?.toUpperCase() !==
                              "APROVADO" &&
                              analysis?.statusRisk?.toUpperCase() !==
                                "AJUSTAR GARANTIA")
                          }
                        >
                          Visualizar
                        </Button>{" "}
                        <Button
                          onClick={() =>
                            handleChangeStatus(row?.analysis?.id, "Aprovado")
                          }
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                          disabled={
                            analysis?.statusForecastCompleted?.toUpperCase() !==
                              "COMPLETO" ||
                            (analysis?.statusRisk?.toUpperCase() !==
                              "APROVADO" &&
                              analysis?.statusRisk?.toUpperCase() !==
                                "AJUSTAR GARANTIA") ||
                            analysis.statusProposal !== null
                          }
                        >
                          Aprovado
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleChangeStatus(row?.analysis?.id, "Reprovado")
                          }
                          disabled={
                            analysis?.statusForecastCompleted?.toUpperCase() !==
                              "COMPLETO" ||
                            (analysis?.statusRisk?.toUpperCase() !==
                              "APROVADO" &&
                              analysis?.statusRisk?.toUpperCase() !==
                                "AJUSTAR GARANTIA") ||
                            analysis.statusProposal !== null
                          }
                        >
                          Reprovado
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={countPage.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default ListProposal;
