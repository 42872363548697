import AppAnimate from "@crema/core/AppAnimate";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { format, parseISO } from "date-fns";
import { sortBy } from "lodash";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import formatCnpj from "shared/utils/formatCnpj";
import Grid from "@mui/material/Grid";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import { PatternFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import { useAuthUser } from "@crema/utility/AuthHooks";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize } from "@mui/material";

const columns = [
  { id: "cnpj", label: "CNPJ", minWidth: 170 },
  { id: "name", label: "Nome", minWidth: 100 },
  { id: "statusRisk", label: "Status Risco", minWidth: 100 },
  { id: "comment", label: "Comentario", minWidth: 100 },
  { id: "user", label: "Responsavel", minWidth: 100 },
  { id: "Data", label: "Data", minWidth: 100 },
  { id: "Ações", label: "", minWidth: 100 },
];

const ListAnalysis = () => {
  const [proposalList, setProposalList] = useState([]);
  const [countPage, setCountPage] = React.useState({ length: 0 });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const { user } = useAuthUser();
  const [comment, setComment] = React.useState("");

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    listProposal(newPage);
    setPage(newPage);
  };

  const handleClickReactivateProposal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChangeComment = (event) => {
    setComment(event.target.value);
  };

  const reactivateProposal = async (aId) => {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/analysis/reactivate/${aId}`,
        "post",
        token,
        {
          comment: comment,
        }
      );
      toast.success("Proposta posta em análise com sucesso");
      setLoading(false);
      setOpen(false);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setLoading(false);
      setOpen(false);
    } finally {
      setLoading(false);
      setOpen(false);
      // listProposal(0);
    }
  };
  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = +event.target.value;
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const getListProposal = async function () {
    let leads;
    const token = await getToken();
    try {
      if (search == "") {
        listProposal(0);
      } else {
        setLoading(true);
        setPage(0);
        const result = await request(
          process.env.REACT_APP_API_URL + `/cnpj/${search}/${"credenciamento"}`,
          "get",
          token
        );
        leads = result;
        console.log(result, "result");
        setProposalList(leads);
      }
    } catch (error) {
      console.error(error);
      setProposalList([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchDataForPage = async (page) => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL +
          `/leads/accreditation?page=${page}&size=${rowsPerPage}`,
        "get",
        token
      );
      const resultCount = await request(
        process.env.REACT_APP_API_URL + `/leads/accreditation/count`,
        "get",
        token
      );
      setCountPage({ length: resultCount });
      setProposalList(result);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchDataForPage(page);
  }, [page]);

  // vai buscar na api a lista das análises de pricing
  const listProposal = async function (page) {
    try {
      setLoading(true);
      const token = await getToken();
      const result = await request(
        process.env.REACT_APP_API_URL +
          `/leads/accreditation?page=${page}&size=${rowsPerPage}`,
        "get",
        token
      );

      setProposalList(result);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setProposalList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    rowsPerPage;
  }, [rowsPerPage]);

  useEffect(() => {
    listProposal(page);
  }, [rowsPerPage]);

  const handleRowClick = function (aId) {
    navigate(`/accreditation/risk/analysis/detail/${aId}`);
  };

  const handleClickCompliance = function (aId) {
    navigate(`/accreditation/risk/compliance/${aId}`);
  };

  const handleClickStartProcess = async function (uuid) {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/processanalysis/${uuid}`,
        "post",
        token
      );
      toast.success("Reiniciando APIs");
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Hover loading={loading} />

      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Lista de analises
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>

          <Box sx={{ justifyContent: "end", display: "flex" }}>
            <PatternFormat
              required
              customInput={TextField}
              style={{ height: "30px", marginRight: "10px" }}
              value={search}
              allowLeadingZeros
              format="##############"
              allowEmptyFormatting
              onValueChange={(values, sourceInfo) => {
                setSearch(values.value);
              }}
              mask=""
            />

            <Button
              onClick={getListProposal}
              sx={{ backgroundColor: "#000536", mb: 5, mt: 1 }}
              variant="contained"
              small
            >
              Buscar
            </Button>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: "#000536", color: "#ffffff" }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalList
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const analysis = row?.analysis;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell> {formatCnpj(row?.cnpj)}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          {analysis?.statusRisk?.toUpperCase()}
                        </TableCell>
                        <TableCell>{analysis?.comment}</TableCell>
                        <TableCell>{analysis?.user}</TableCell>
                        <TableCell>
                          {analysis?.analysisDate
                            ? format(
                                parseISO(analysis.analysisDate),
                                "dd-MM-yyyy HH:mm:ss"
                              )
                            : ""}
                        </TableCell>
                        <TableCell sx={{ display: "flex" }}>
                          <Button
                            onClick={() => handleRowClick(row?.analysis?.id)}
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            size="small"
                          >
                            Relatorio
                          </Button>
                          <Box
                            sx={{
                              justifyContent: "flex-end",
                              display: "flex",
                              ml: 3,
                            }}
                          >
                            {
                              <>
                                <Button
                                  onClick={handleClickReactivateProposal}
                                  variant="contained"
                                  component="label"
                                  size="small"
                                  disabled={
                                    analysis?.statusRisk.toUpperCase() ==
                                      "EM ANALISE" ||
                                    analysis?.statusRisk.toUpperCase() ==
                                      "APROVADO" ||
                                    analysis?.statusRisk.toUpperCase() ==
                                      "SISTEMICO"
                                  }
                                  sx={{
                                    backgroundColor: "#000536",
                                    borderRadius: "10px",
                                  }}
                                >
                                  Retomar proposta
                                </Button>
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      backgroundColor: "white", // ou qualquer outra cor de fundo desejada
                                      borderRadius: "8px", // ajuste conforme necessário
                                      boxShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.1)", // ajuste conforme necessário
                                    },
                                  }}
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    },
                                  }}
                                >
                                  <DialogTitle>Confirmacao</DialogTitle>
                                  <DialogContent>
                                    <FormControl fullWidth size="small">
                                      <TextField
                                        size="small"
                                        id="min"
                                        label="Responsavel"
                                        variant="outlined"
                                        sx={{ mb: 4, mt: 3 }}
                                        value={user?.displayName}
                                        disabled={true}
                                      />
                                    </FormControl>

                                    <TextareaAutosize
                                      onChange={handleOnChangeComment}
                                      aria-label="minimum height"
                                      minRows={3}
                                      placeholder=" Descreva o motivo para tal acao."
                                      style={{ width: "100%" }}
                                    />
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose}>
                                      Cancelar
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        reactivateProposal(row?.analysis?.id)
                                      }
                                    >
                                      Salvar
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </>
                            }
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            //rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={countPage.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </Fragment>
  );
};

export default ListAnalysis;
