import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Fonts } from "../../shared/constants/AppEnums";
import Translation from "../../shared/localization/locales/pt_BR.json";

export const ObjectView = ({ detail }) => {
  let data;
  try {
    data = JSON.parse(detail);
  } catch (error) {
    data = detail;
  }

  const tipo = typeof data;

  if (tipo === "undefined" || tipo === "null") {
    return <Box />;
  }

  if (tipo === "string" || tipo === "number" || tipo === "boolean") {
    return (
      <Typography
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontWeight: Fonts.MEDIUM,
          fontSize: { xs: 12 },
        }}
      >
        {data.toString()}
      </Typography>
    );
  }

  if (Array.isArray(data)) {
    return (
      <Fragment>
        {data.map((item, idx) => (
          <Card key={idx} sx={{ mb: 2, borderRadius: 3 }} elevation={2}>
            <CardContent>
              <ObjectView detail={item} />
            </CardContent>
          </Card>
        ))}
      </Fragment>
    );
  }

  if (tipo === "object" && data) {
    const keys = Object.keys(data || {});

    return (
      <Fragment>
        {keys.map((k, idx) => {
          const value = data[k];
          const valueType = typeof value;

          if (value === null) {
            return null;
          }

          if (
            valueType === "string" ||
            valueType === "number" ||
            valueType === "boolean"
          ) {
            return (
              <Box
                key={idx}
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontWeight: Fonts.BOLD,
                    fontSize: { xs: 16 },
                    mr: 1,
                  }}
                >
                  {Translation["bigdata." + k] ?? "bigdata." + k}:
                </Typography>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontWeight: Fonts.MEDIUM,
                    fontSize: { xs: 14 },
                  }}
                >
                  {value.toString()}
                </Typography>
              </Box>
            );
          }

          return (
            <Box key={idx} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: Fonts.BOLD,
                  fontSize: { xs: 16 },
                  mb: 1,
                }}
              >
                {Translation["bigdata." + k] ?? "bigdata." + k}
              </Typography>
              <ObjectView detail={value} />
            </Box>
          );
        })}
      </Fragment>
    );
  }

  return <Box>{JSON.stringify(data)}</Box>;
};

ObjectView.propTypes = {
  detail: PropTypes.any,
};
