import React, { useEffect, useState } from "react";
import uuid4 from "uuid4";

import axios from "axios";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DataTable } from "elements/dataTable";

import {
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const ConsultaContratoCessao = () => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              allColumns: true,
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [tokenPagination, setTokenPagination] = useState("");

  const [displayRegistro, setDisplayRegistro] = useState("none");

  const [displayLiquidacao, setDisplayLiquidacao] = useState("block");

  const [dateFromReserva, setDateFromReserva] = useState(dayjs());
  const handleChangeDateFromReserva = (newValue) => {
    setDateFromReserva(dayjs(newValue));
  };

  const [dateToReserva, setDateToReserva] = useState(dayjs());
  const handleChangeDateToReserva = (newValue) => {
    setDateToReserva(dayjs(newValue));
  };

  const [dateFromLiquidacao, setDateFromLiquidacao] = useState(dayjs());
  const handleChangeDateFromLiquidacao = (newValue) => {
    setDateFromLiquidacao(dayjs(newValue));
  };

  const [dateToLiquidacao, setDateToLiquidacao] = useState(dayjs());
  const handleChangeDateToLiquidacao = (newValue) => {
    setDateToLiquidacao(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState("");
  const handleChangePaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
    console.log(event.target.value);
  };

  const [filter, setFilter] = useState("dataLiquidacao");
  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    console.log(event.target.value);
    if (event.target.value === "dataLiquidacao") {
      setDisplayRegistro("none");
      setDisplayLiquidacao("block");
    } else if (event.target.value === "dataReserva") {
      setDisplayLiquidacao("none");
      setDisplayRegistro("block");
    }
    setFilter(event.target.value);
    console.log(event.target.value);
  };

  const [issuers, setIssuers] = useState([]);
  const [assignees, setAssignees] = useState([]);

  const columns = [
    { field: "idCessionario", headerName: "ID Cessionário", hide: true },
    {
      field: "cessionario",
      headerName: "Cessionário",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dataLiquidacao",
      headerName: "Data Liquidação",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }

        return dayjs(value).utc().format("DD-MM-YYYY");
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        // Arredonda o valor de string para inteiro para poder formatar

        let valor = value / 100;

        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "bandeira",
      headerName: "Bandeira",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ica",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "emissor",
      headerName: "Emissor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pactualId",
      headerName: "PactualId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dataRegistro",
      headerName: "Data Registro",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "codErro",
      headerName: "Cod Erro",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "descErro",
      headerName: "Descrição",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [rows, setRows] = useState([]);

  const getName = (aId, aList) => {
    for (const a of aList) {
      if (a.value === aId) {
        return a.label;
      }
    }
    return "";
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // const fetchContracts = async (aPage) => {
  //   try {
  //     setLoading(true);

  //     // Opcional: bandeira
  //     let params = {};
  //     if (currentPaymentNetwork.length > 0) {
  //       params = {
  //         paymentNetwork: currentPaymentNetwork,
  //       };
  //     }

  //     /* const result = await fetchData(
  //       `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservations/${dateFrom.format(
  //         'YYYY-MM-DD'
  //       )}/${dateTo.format('YYYY-MM-DD')}`,
  //       params
  //     ); */

  //     /* const result = await fetchData(
  //       process.env.REACT_APP_CLIENT_BTG + `/tr/depositary-bank/credit-reservations/${dateFrom.format(
  //         'YYYY-MM-DD'
  //       )}/${dateTo.format('YYYY-MM-DD')}`,
  //       params
  //     ); */

  //     const result = await fetchData(
  //       `https://backend.entrepay.com.br/btg/tr/depositary-bank/credit-reservations/${dateFrom.format(
  //         'YYYY-MM-DD'
  //       )}/${dateTo.format('YYYY-MM-DD')}`,
  //       params
  //     );

  //     console.log("RESULT CONTRACTS:", result)

  //     const d = result.map((item) => {
  //       return {
  //         id: uuid4(),
  //         idCessionario: item.body.assigneeId,
  //         cessionario: getName(item.body.assigneeAccountId, assignees),
  //         dataLiquidacao: item.body.reservationDate,
  //         valor: item.body.amount,
  //         bandeira: getName(item.body.paymentNetwork, paymentNetworks),
  //         ica: item.body.cardIssuerId,
  //         emissor: getName(item.body.cardIssuerId, issuers),
  //         pactualId: item.pactualId,
  //         status: item.status,
  //         dataRegistro: item.createTimestamp,
  //         codErro: item.errorCode,
  //         descErro: item.errorDescription,
  //       };
  //     });
  //     setRows(d);
  //   } catch (error) {
  //     console.log(error.response);
  //     if(error.response.data.errors) {
  //       toast.error(error.response.data.errors[0].message)
  //     }
  //     setLoading(false);
  //   }
  // };

  const fetchContracts = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];
      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity: "listCreditReservation" + process.env.REACT_APP_ENVIROMENT,
            filterCreditReservation: filter,
            dataInicial:
              filter === "dataLiquidacao"
                ? dateFromLiquidacao.format("'YYYY-MM-DD'")
                : dateFromReserva.format("'YYYY-MM-DD'"),
            dataFinal:
              filter === "dataLiquidacao"
                ? dateToLiquidacao.format("'YYYY-MM-DDT23:59:59[Z]'")
                : dateToReserva.format("'YYYY-MM-DDT23:59:59[Z]'"),
            paymentNetwork: !currentPaymentNetwork
              ? "'%'"
              : `"${currentPaymentNetwork}"`,
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      const d = newArray.map((item) => {
        return {
          id: uuid4(),
          idCessionario:
            item?.body?.assigneeAccountId ?? item.assigneeAccountId,
          cessionario: getName(
            item?.body?.assigneeAccountId ?? item.assigneeAccountId,
            assignees
          ),
          dataLiquidacao: item?.body?.reservationDate ?? item.reservationDate,
          valor: item?.body?.amount ?? item.amount,
          bandeira: getName(
            item?.body?.paymentNetwork ?? item.paymentNetwork,
            paymentNetworks
          ),
          ica: item?.body?.cardIssuerId ?? item.cardIssuerId,
          emissor: getName(
            item?.body?.cardIssuerId ?? item.cardIssuerId,
            issuers
          ),
          pactualId: item.pactualId,
          status: item.status,
          dataRegistro: item.createTimestamp,
          codErro: item.errorCode,
          descErro: item.errorDescription,
        };
      });

      function filterCancelled() {
        const cancelledIds = new Set(
          d
            .filter((item) => item.status === "CANCELLED")
            .map((item) => item.pactualId)
        );
  
        return d.filter(
          (item) => !cancelledIds.has(item.pactualId)
        );
      }

      const result = filterCancelled();
  
      const dadosFiltrados = result.filter(
        (objeto) => objeto.status === "COMPLETED" || objeto.status === "CREATED"
      );

      setRows(dadosFiltrados);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks' */
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees' */
        process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees"
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      console.log(d);
      setAssignees(d);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores
  const fetchCardIssuers = async () => {
    try {
      const emissores = [];
      setLoading(true);

      // a api só lista por bandeira
      for (const p of paymentNetworks) {
        const i = await fetchCardIssuersByPaymentNetwork(p.value);
        emissores.push(...i);
      }
      console.log(emissores);
      setIssuers(emissores);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCardIssuersByPaymentNetwork = async (aPaymentNetwork) => {
    /* const result = await fetchData(
          'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
          {
            paymentNetwork: aPaymentNetwork,
          }
        ); */

    const result = await fetchData(
      process.env.REACT_APP_CLIENT_BTG +
        "/tr/depositary-bank/card-issuer/search",
      {
        paymentNetwork: aPaymentNetwork,
      }
    );

    const d = result.map((item) => {
      return {
        value: item.cardIssuerId,
        label: item.name,
      };
    });
    return d;
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const handleSearch = async (event) => {
    setRows([]);
    // Pega o token do login para passar na chamada
    const token = await getToken();
    /* const contratos = await fetchContracts(); */
    const contratosFailed = await fetchContracts(token);
  };

  const handleCellClick = (e) => {
    //    const document = e.row;
  };

  /**
   * useEffect
   */
  // Inicial
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardIssuers();
  }, [paymentNetworks]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl fullWidth sx={{ display: displayRegistro }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"De (Data de Registro)*"}
              value={dateFromReserva}
              onChange={handleChangeDateFromReserva}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth sx={{ display: displayRegistro }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"Até (Data de Registro)*"}
              value={dateToReserva}
              onChange={handleChangeDateToReserva}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth sx={{ display: displayLiquidacao }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"De (Data de Liquidação)*"}
              value={dateFromLiquidacao}
              onChange={handleChangeDateFromLiquidacao}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth sx={{ display: displayLiquidacao }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"Até (Data de Liquidação)*"}
              value={dateToLiquidacao}
              onChange={handleChangeDateToLiquidacao}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Bandeira</InputLabel>
          <Select
            value={currentPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Filtro de data</InputLabel>
          <Select
            value={filter}
            label="Bandeira"
            onChange={(e) => handleChangeFilter(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="dataLiquidacao">
              <em>Por liquidação</em>
            </MenuItem>
            <MenuItem value="dataReserva">
              <em>Por reserva</em>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              minWidth: 50,
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Pesquisar
          </Button>
        </FormControl>
      </Box>

      <DataTable
        rows={rows}
        columns={columns}
        onCellClick={handleCellClick}
        columnVisibilityModel={{
          idCessionario: false,
          idEmissor: false,
        }}
      />
    </Box>
  );
};
