import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {
  AppBar,
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
// import * as yup from "yup";
import { styled } from "@mui/system";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from "react-icons/fa";
import { Fonts } from "shared/constants/AppEnums";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import elo from "../../../../../assets/icon/elo.png";
import hipercard from "../../../../../assets/icon/hipercard.png";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";
import FallbackSpinner from "elements/fallbackSpinner";
import { groupBy } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FileUploader } from "react-drag-drop-files";
import FormControl from "@mui/material/FormControl";

const GridItem = styled(Grid)({
  background: "#000536",
  color: "white",
  paddingTop: "1.2em",
});

const GridContainer = styled(Grid)({
  border: "1px solid rgb(39 38 38 / 20%)",
  borderRadius: "10px",
});

const GreyTypography = styled(Typography)({
  background: "#000536",
  color: "#ffffff",
});

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const SmallCard = styled(Card)({
  background: "#000536",
  color: "#ffffff",
  width: "400px",
  height: "78px",
  borderRadius: "0px",
  marginBottom: "30px",
});

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const StandardMdr = () => {
  const { id } = useParams();
  const [proposal, setProposal] = useState();
  const [comission, setComission] = useState(0);
  const [status, setStatus] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState({});
  const [description, setDescription] = useState();
  const [special, setSpecial] = useState("yes");
  const [files, setFiles] = useState({});

  const { getToken } = useAuthMethod();

  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    console.log(file);
    temp[name] = file;
    setFiles(temp);
  };

  const handleClickSave = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          comission,
        }
      );
      toast.success(" Salvo com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
      setLoading(false);
    }
  };

  const listProposal = async function () {
    try {
      setLoading(true);
      const token = await getToken();
      const result = await request(
        process.env.REACT_APP_API_URL + `/feemdr/${id}`,
        "get",
        token
      );
      setProposal(result);
      setComission(result?.analysis?.comission ?? 0);
      setCards(groupBy(result?.analysisMdr, (v) => v.cardBrand));
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar Tabela MDR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal();
  }, []);

  const onClickSendProposal = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      if ((!files.Pricing || description === "") && special != "no") {
        return toast.error(
          "Os campos referentes a condicoes especiais sao necessários para irmos para próxima fase."
        );
      }
      for (const key of Object.keys(files)) {
        const uploadFiles = files[key];
        const data = new FormData();
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }

        const resultUpload = await request(
          process.env.REACT_APP_API_URL + "/documentupload/" + id + "/" + key,
          "post",
          token,
          data
        );
      }

      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          specialDescriptionPricing: description,
          specialConditionPricing: special,
        }
      );

      const result = await request(
        process.env.REACT_APP_API_URL + `/sendproposal/${id}`,
        "post",
        token
      );

      toast.success("Enviado com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar proposta");
    } finally {
      setLoading(false);
    }
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeSpecial = (event) => {
    setSpecial(event.target.checked === true ? "no" : "yes");
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Proposta MDR
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container>
          <Grid item xs={6}>
            <Typography>
              Digite abaixo a condição especial já aprovada e anexe a evidência
              de aprovação dela*
            </Typography>
            <TextareaAutosize
              onChange={onChangeDescription}
              value={description}
              aria-label="minimum height"
              minRows={4}
              placeholder=" Preenchimento obrigatório."
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={5} sx={{ ml: 5, mt: 2 }}>
            <div style={{ border: "1px dotted blue", padding: "14px" }}>
              <FileUploader
                handleChange={(file) => handleUploadChange("Pricing", file)}
                name="file"
                multiple={true}
                children={
                  <div
                    style={{
                      textAlign: "left",
                      color: "#2C90D8",
                      padding: 0,
                    }}
                  >
                    {files?.Pricing?.length > 0 ? (
                      Object.keys(files?.Pricing).map((v) => {
                        const uploadedFile = files?.Pricing[v];
                        return <p key={v}>{uploadedFile.name}</p>;
                      })
                    ) : (
                      <p>
                        <CloudUploadOutlinedIcon /> Arraste e solte o arquivo
                        preenchido aqui
                      </p>
                    )}
                  </div>
                }
              />
            </div>
            <Grid item xs={8}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={special === "no"}
                      onChange={handleChangeSpecial}
                    />
                  }
                  label="Não possui condição especial"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 5 }}></Grid>

          <Grid item>
            <SmallCard>
              <Typography>{`Tabela MDR`}</Typography>
              <Typography>
                Faturamento mensal: Até{" "}
                {currencyLocale.format(proposal?.pricingPartner?.tpvAverage)}
              </Typography>
              <Typography>
                Antecipacao{" "}
                {proposal?.pricingPartner?.prepayment < 50 ? "ABAIXO" : "ACIMA"}{" "}
                50% do volume
              </Typography>
            </SmallCard>
          </Grid>
          <Grid item sx={{ ml: 10, mb: 5 }}>
            <GreyTypography>Comissão</GreyTypography>
            <TextField
              small
              id="outlined-basic"
              // label="Taxa"
              variant="filled"
              value={comission}
              onChange={(e) => setComission(e.target.value)}
            />
          </Grid>
          <Grid item sx={{ ml: 10 }}>
            <Button
              sx={{ backgroundColor: "navy", borderRadius: "10px", ml: 2 }}
              variant="contained"
              disabled={loading}
              onClick={handleClickSave}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>

        {proposal?.forecastMccs?.map((mcc) => {
          return (
            <Box sx={{ mb: 10 }}>
              <GridContainer container key={mcc.id}>
                <GridItem xs={2} sx={{ borderRadius: "4px 0px 0px 4px" }} item>
                  <Typography>MCC</Typography>
                </GridItem>
                <Grid xs={2} item>
                  <TextField
                    disabled={true}
                    small
                    id="outlined-basic"
                    value={mcc?.mcc ?? 0}
                  />
                </Grid>
                <GridItem xs={2} item>
                  <Typography>DESCRIÇÃO</Typography>
                </GridItem>
                <Grid item sx={{ paddingTop: "1.2em", width: "47%" }}>
                  <Typography>{mcc?.mccDescription?.mccName}</Typography>
                </Grid>
              </GridContainer>

              {Object.keys(cards)?.map((brand) => {
                const mdrValues = cards[brand]?.filter(
                  (v) => v.mcc.mcc == mcc.mcc
                );
                return (
                  (mdrValues.length > 0 && (
                    <GridContainer
                      key={`${brand}-${mcc?.mcc}`}
                      container
                      sx={{ marginTop: 1 }}
                    >
                      {brand.toLowerCase() == "visa" && (
                        <GridItem
                          xs={2}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <FaCcVisa size="3.5em" />
                        </GridItem>
                      )}
                      {brand.toLowerCase() == "mastercard" && (
                        <GridItem
                          xs={2}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <FaCcMastercard size="3.5em" />
                        </GridItem>
                      )}
                      {brand.toLowerCase() == "elo" && (
                        <GridItem
                          xs={2}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <img
                            src={elo}
                            style={{ width: "120px", height: "49px" }}
                          />
                        </GridItem>
                      )}
                      {brand.toLowerCase() == "cabal" && (
                        <GridItem
                          xs={2}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <FaCreditCard size="3.5em" />
                        </GridItem>
                      )}
                      {brand.toLowerCase() == "hipercard" && (
                        <GridItem
                          xs={4}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <img
                            src={hipercard}
                            style={{ width: "120px", height: "49px" }}
                          />
                        </GridItem>
                      )}
                      {brand.toLowerCase() == "amex" && (
                        <GridItem
                          xs={4}
                          sx={{ borderRadius: "4px 0px 0px 4px" }}
                          item
                        >
                          <FaCcAmex size="3.5em" />
                        </GridItem>
                      )}

                      {mdrValues?.map((fee) => {
                        return (
                          <Grid xs={2} item key={mdrValues.id}>
                            <GreyTypography>
                              {fee.product.toUpperCase()}
                            </GreyTypography>
                            {fee.mdr}
                          </Grid>
                        );
                      })}
                    </GridContainer>
                  )) ||
                  null
                );
              })}
            </Box>
          );
        })}
        <Footer>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickSendProposal}
              >
                Enviar para Proposta Comercial
              </Button>
            </Grid>
          </Grid>
        </Footer>
      </Card>
      {loading && <FallbackSpinner />}
    </Box>
  );
};

export default StandardMdr;
