import React, { useState } from "react";

import { Box, Button, FormControl } from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DataTable } from "elements/dataTable";
import {
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export const FinancialTransaction = () => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              allColumns: true,
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [dateFrom, setDateFrom] = useState(dayjs());
  const handleChangeDateFrom = (newValue) => {
    setDateFrom(dayjs(newValue));
  };

  const [dateTo, setDateTo] = useState(dayjs());
  const handleChangeDateTo = (newValue) => {
    setDateTo(dayjs(newValue));
  };

  const columns = [
    {
      field: "pactualId",
      headerName: "pactualId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "clientRequestId",
      headerName: "clientRequestId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createTimestamp",
      headerName: "createTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).utc().format("DD-MM-YYYY");
      },
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).utc().format("DD-MM-YYYY");
      },
    },
    {
      field: "entity",
      headerName: "entity",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "movementId",
      headerName: "movementId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditAccount",
      headerName: "creditAccount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditAccountType",
      headerName: "creditAccountType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditBank",
      headerName: "creditBank",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditBranch",
      headerName: "creditBranch",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditName",
      headerName: "creditName",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditPersonType",
      headerName: "creditPersonType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditTaxId",
      headerName: "creditTaxId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitAccount",
      headerName: "debitAccount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitAccountType",
      headerName: "debitAccountType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitBank",
      headerName: "debitBank",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitBranch",
      headerName: "debitBranch",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitName",
      headerName: "debitName",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitPersonType",
      headerName: "debitPersonType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitTaxId",
      headerName: "debitTaxId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "financialType",
      headerName: "financialType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "amount",
      headerName: "amount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const getFinancialTransactions = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];
      console.log(aToken)
      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity:
              "listFinancialTransaction" + process.env.REACT_APP_ENVIROMENT,
            dataInicial: dateFrom.format("'YYYY-MM-DD'"),
            dataFinal: dateTo.format("'YYYY-MM-DDT23:59:59[Z]'"),
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      setData(newArray);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleSearch = async (event) => {
    try {
      setLoading(true);
      const token = await getToken();
      const resultFetchLimiteCessionario = await getFinancialTransactions(token);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"De*"}
              value={dateFrom}
              onChange={handleChangeDateFrom}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"Até*"}
              value={dateTo}
              onChange={handleChangeDateTo}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              minWidth: 50,
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Pesquisar
          </Button>
        </FormControl>
      </Box>

      <DataTable
        rows={
          data.map((item, index) => ({
            ...item,
            pactualId: item.pactualId,
            clientRequestId: item.clientRequestId,
            createTimestamp: item.createTimestamp,
            entity: item.entity,
            status: item.status,
            financialType: item.financialType,
            creditAccountType: item.creditAccountType,
            creditBank: item.creditBank,
            creditBranch: item.creditBranch,
            creditName: item.creditName,
            creditPersonType: item.creditPersonType,
            creditTaxId: item.creditTaxId,
            debitAccount: item.debitAccount,
            debitAccountType: item.debitAccountType,
            debitBank: item.debitBank,
            debitBranch: item.debitBranch,
            debitName: item.debitName,
            debitPersonType: item.debitPersonType,
            debitTaxId: item.debitTaxId,
            lastUpdateTimestamp: item.lastUpdateTimestamp,
            movementId: item.movementId,
            amount: item.amount,
            cashDirection: item.cashDirection,
            creditAccount: item.creditAccount,
          })) ?? []
        }
        columnVisibilityModel={{
          pactualId: true,
          clientRequestId: true,
          createTimestamp: false,
          entity: false,
          status: true,
          financialType: true,
          creditAccountType: false,
          creditBank: false,
          creditBranch: false,
          creditName: true,
          creditPersonType: false,
          creditTaxId: true,
          debitAccount: false,
          debitAccountType: false,
          debitBank: false,
          debitBranch: false,
          debitName: true,
          debitPersonType: false,
          debitTaxId: true,
          lastUpdateTimestamp: false,
          movementId: true,
          amount: true,
          cashDirection: true,
          creditAccount: true,
        }}
        columns={columns}
        getRowId={(row) => generateRandomId()}
      />
    </Box>
  );
};
