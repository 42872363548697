import React, { useEffect, useState } from "react";
import { plain } from "shared/utils/plain";
import uuid4 from "uuid4";

import { pages } from "pages/pages.js";

import { Box } from "@mui/material";

import { DataTable } from "elements/dataTable";

import { Hover } from "elements/hover";

export const Grupos = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = React.useState([]);

  const columns = [
    { field: "path", headerName: "Página", width: 440 },
    { field: "groups", headerName: "Grupos", width: 220 },
  ];

  // Função de comparação pelo nome
  // usada para ordenar a lista
  function compare(a, b) {
    if (plain(a.path) < plain(b.path)) {
      return -1;
    }
    if (plain(a.path) > plain(b.path)) {
      return 1;
    }
    return 0;
  }

  const getMatrix = (aPages, aParentPath = "") => {
    const children = [];
    for (const p of aPages) {
      const path = `${aParentPath}/${p.path}`;
      if (p.children && p.children.length > 0) {
        const c = getMatrix(p.children, path);
        children.push(...c);
      } else {
        children.push({
          id: uuid4(),
          groups: p.allowedGroups.sort().join(", "),
          path: path,
        });
      }
    }

    return children;
  };

  useEffect(() => {
    const d = getMatrix(pages);
    setData(d.sort(compare));
  }, [pages]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        padding: 2,
        width: "100%",
        gap: 2,
      }}
    >
      <Hover loading={loading} />

      <DataTable rows={data} columns={columns} />
    </Box>
  );
};
