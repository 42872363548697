import React, { useState } from "react";
import uuid4 from "uuid4";
import { formatDocument } from "shared/utils/formatDocument";

import axios from "axios";

import { Box, Button, FormControl } from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataTable } from "elements/dataTable";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const ConsultarTed = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const [loading, setLoading] = useState(false);

  // Data Início
  const [valueDataInicio, setDataInicio] = React.useState(dayjs()); // Data Inicio
  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue);
  };

  // Data Fim
  const [valueDataFim, setDataFim] = React.useState(dayjs()); // Data Fim
  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue);
  };

  const [data, setData] = React.useState([]);

  const columns = [
    {
      field: "createTimestamp",
      headerName: "createTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "creditAccount",
      headerName: "creditAccount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditAccountType",
      headerName: "creditAccountType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditBank",
      headerName: "creditBank",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditBranch",
      headerName: "creditBranch",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "creditName",
      headerName: "creditName",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "creditPersonType",
      headerName: "creditPersonType",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "creditTaxId",
      headerName: "creditTaxId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return formatDocument(value);
      },
    },
    {
      field: "debitAccount",
      headerName: "debitAccount",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitAccountType",
      headerName: "debitAccountType",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitBank",
      headerName: "debitBank",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitBranch",
      headerName: "debitBranch",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitName",
      headerName: "debitName",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitPersonType",
      headerName: "debitPersonType",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "debitTaxId",
      headerName: "debitTaxId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return formatDocument(value);
      },
    },
    {
      field: "clientId",
      headerName: "clientId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "clientRequestId",
      headerName: "clientRequestId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "entity",
      headerName: "entity",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "pactualId",
      headerName: "pactualId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "movementId",
      headerName: "movementId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "reversalMovementId",
      headerName: "reversalMovementId",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "amount",
      headerName: "amount",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "errorCode",
      headerName: "errorCode",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "errorDescription",
      headerName: "errorDescription",
      flex: 1,
      align: "center",
      headerAlign: "center",
      width: 150,
    },
  ];

  // token da autenticação
  const { getToken } = useAuthMethod();

  const fetchTedsWebhook = async (aToken, aPage = 0) => {
    setData([]);
    let array = [];
    try {
      setLoading(true);
      const token = await getToken();
      const params = {
        method: "GET",
        /* url: 'https://backend.entrepay.com.br/integrations/webhooks/listExternalTransfer', */
        url:
          process.env.REACT_APP_CLIENT_INTEGRATION_URL +
          "/webhooks/listExternalTransfer",
        params: {
          page: 100000,
          token: "",
          dataInicial: valueDataInicio.format("YYYY-MM-DD"),
          dataFinal: valueDataFim
            .add(1, "day")
            .hour(0)
            .minute(0)
            .second(0)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios(params);
      console.log(res);

      if (res.headers["x-target-token"]) {
        while (res.headers["x-target-token"]) {
          const resLoop = await axios(params);
          console.log(resLoop);
          array.push(resLoop.data);
          params.params.token = resLoop.headers["x-target-token"]; // Atualiza o token nos parâmetros
          if (!resLoop.headers["x-target-token"]) {
            break; // Sai do loop se o token estiver vazio
          }
        }
      } else {
        array = res.data;
      }
      return array;
    } catch (error) {
      toast.error(
        `Erro na chamada do banco external transfer: ${error.message}`
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTedsRequests = async (aToken, aPage = 0) => {
    setData([]);
    let array = [];
    try {
      setLoading(true);
      const token = await getToken();
      const params = {
        method: "GET",
        /* url: 'https://backend.entrepay.com.br/integrations/webhooks/listExternalTransfer', */
        url:
          process.env.REACT_APP_CLIENT_INTEGRATION_URL +
          "/tedRequests/listTedRequests",
        params: {
          page: 100000,
          token: "",
          dataInicial: valueDataInicio.format("YYYY-MM-DD"),
          dataFinal: valueDataFim
            .add(1, "day")
            .hour(0)
            .minute(0)
            .second(0)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios(params);
      console.log(res);

      if (res.headers["x-target-token"]) {
        while (res.headers["x-target-token"]) {
          const resLoop = await axios(params);
          console.log(resLoop);
          array.push(resLoop.data);
          params.params.token = resLoop.headers["x-target-token"]; // Atualiza o token nos parâmetros
          if (!resLoop.headers["x-target-token"]) {
            break; // Sai do loop se o token estiver vazio
          }
        }
      } else {
        array = res.data;
      }
      return array;
    } catch (error) {
      toast.error(
        `Erro na chamada do banco external transfer: ${error.message}`
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (event) => {
    const resultTedsWebhook = await fetchTedsWebhook();
    const resultTedsRequests = await fetchTedsRequests();
    console.log("RESULT TedsWebhook:", resultTedsWebhook);
    console.log("RESULT TedsRequests:", resultTedsRequests);

    const arrayResultados = [];

    resultTedsWebhook.forEach((TedsWebhooks) => {
      // Verificar se há um registro correspondente em resultFetchLimiteCessionario
      const matchingTeds = resultTedsRequests.filter(
        (TedsRequests) =>
          TedsRequests.clientRequestId === TedsWebhooks.clientRequestId &&
          TedsRequests.clientRequestId === TedsWebhooks.clientRequestId
      );

      if (matchingTeds.length > 0) {
        // Encontrou correspondência, encontre o objeto com lastUpdateTimestamp mais recente
        const latestTeds = matchingTeds.reduce((prev, current) =>
          prev.lastUpdateTimestamp > current.lastUpdateTimestamp
            ? prev
            : current
        );

        console.log(latestTeds);

        const novoObjetoData = {
          amount: TedsWebhooks.amount,
          clientRequestId: TedsWebhooks.clientRequestId,
          createTimestamp: TedsWebhooks.createTimestamp,
          creditAccount: TedsWebhooks.creditAccount,
          creditAccountType: TedsWebhooks.creditAccountType,
          creditBank: TedsWebhooks.creditBank,
          creditBranch: TedsWebhooks.creditBranch,
          creditPersonType: TedsWebhooks.creditPersonType,
          creditTaxId: TedsWebhooks.creditTaxId,
          debitAccount: TedsWebhooks.debitAccount,
          debitAccountType: TedsWebhooks.debitAccountType,
          debitBranch: TedsWebhooks.debitBranch,
          debitPersonType: TedsWebhooks.debitPersonType,
          entity: TedsWebhooks.entity,
          errorCode: TedsWebhooks.errorCode,
          errorDescription: TedsWebhooks.errorDescription,
          lastUpdateTimestamp: TedsWebhooks.lastUpdateTimestamp,
          pactualId: TedsWebhooks.pactualId,
          status: TedsWebhooks.status,
          email: latestTeds.email,
        };

        arrayResultados.push(novoObjetoData);
      }
    });

    console.log("Array final:", arrayResultados);
    setData(arrayResultados);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        padding: 2,
        width: "100%",
        gap: 2,
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          width: "100%",
          gap: 10,
        }}
      >
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 120 } }}
              inputFormat="DD/MM/YYYY"
              label="De*"
              value={valueDataInicio}
              onChange={(value) => handleChangeDataInicio(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 120 } }}
              inputFormat="DD/MM/YYYY"
              label="Até*"
              value={valueDataFim}
              onChange={(value) => handleChangeDataFim(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            m: 1,
            minWidth: 150,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "secondary.contrastText",
            bgcolor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
        >
          Pesquisar
        </Button>
      </Box>
      <DataTable
        rows={
          data.map((item, index) => ({
            ...item,
            id: uuid4(),
            email: item.email,
            createTimestamp: item.createTimestamp,
            clientId: item.clientId,
            clientRequestId: item.clientRequestId,
            createTimestamp: item.createTimestamp,
            entity: item.entity,
            lastUpdateTimestamp: item.lastUpdateTimestamp,
            pactualId: item.pactualId,
            status: item.status,
            amount: item.amount,
            creditAccount: item.creditAccount,
            creditAccountType: item.creditAccountType,
            creditBank: item.creditBank,
            creditBranch: item.creditBranch,
            creditName: item.creditName,
            creditPersonType: item.creditPersonType,
            creditTaxId: item.creditTaxId,
            debitAccount: item.debitAccount,
            debitAccountType: item.debitAccountType,
            debitBank: item.debitBank,
            debitBranch: item.debitBranch,
            debitName: item.debitName,
            debitPersonType: item.debitPersonType,
            debitTaxId: item.debitTaxId,
            movementId: item.movementId,
            reversalMovementId: item.reversalMovementId,
          })) ?? []
        }
        columns={columns}
      />
    </Box>
  );
};
