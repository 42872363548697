import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import AppScrollbar from "../../@crema/core/AppScrollbar";
import SidebarItem from "./SideBarItem";
import AppList from "../../@crema/core/AppList";
import ListEmptyResult from "../../@crema/core/AppList/ListEmptyResult";
import SidebarPlaceholder from "../../@crema/core/AppSkeleton/SidebarListSkeleton";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

export const Sidebar = ({ report, changeTopic, currentTopic }) => {
  const [topics, setTopics] = useState([]);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    if (!!report.responses) {
      setEntities(
        report.responses
          .map((response, id) => ({
            id,
            name: response.Result[0].MatchKeys,
          }))
          .filter(({ name }) => !!name)
      );
    } else {
      const reportObj = report.bigData ?? report;
      const reportData = Object.keys(reportObj);
      console.log("reportData", reportData);
      const t = reportData
        .map((name, id) => ({
          id,
          name,
        }))
        .filter(({ name }) => {
          console.log(name, reportObj);
          return !!reportObj[name];
        });
      setTopics(t);
    }
  }, [report]);

  function parseEntityName(id) {
    const entityResult = report.responses[id]?.Result[0];
    if (!entityResult) return "Loading...";

    const documentId = getEntityDocument(id);
    const pfName = entityResult.BasicData.Name;
    const pjName = entityResult.BasicData.OfficialName;

    if (documentId.length === 11)
      return `${documentId.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      )} ${pfName}`;
    if (documentId.length === 14)
      return `${documentId.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      )} ${pjName}`;
    return documentId;
  }

  function getEntityDatasets(entityIndex) {
    console.log("TESTE");
    const entity = report.responses[entityIndex];
    const datasets = {};
    entity.Result.forEach((result) => {
      const resultDatasets = Object.keys(result);
      resultDatasets.forEach((dataset) => {
        if (dataset !== "MatchKeys") {
          datasets[dataset] = result[dataset];
        }
      });
    });
    return datasets;
  }

  function getEntityDocument(id) {
    const matchKeys = report.responses[id]?.Result[0]?.MatchKeys;
    if (!matchKeys) return "Loading...";
    const regex = /doc\{(\d+)}/;
    const match = matchKeys.match(regex);
    const document = match ? match[1] : "Documento Desconhecido";
    return document;
  }

  function handleChangeTopic(id, topic) {
    changeTopic(topic, id);
  }

  if (!!report.responses)
    return (
      <Fragment>
        <AppScrollbar
          sx={{
            height: "calc(100% - 76px)",
          }}
        >
          <Box width="fit-content">
            <List component="nav" aria-label="main mailbox folders">
              {entities.map(({ name, id }) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {parseEntityName(id)}
                  </AccordionSummary>
                  <AccordionDetails>
                    <List component="nav" aria-label="main mailbox folders">
                      <AppList
                        data={Object.keys(getEntityDatasets(id)).map(
                          (datasetName, datasetId) => ({
                            id: datasetId,
                            name:
                              getEntityDocument(id).length === 11
                                ? `pf${datasetName}`
                                : `pj${datasetName}`,
                            alias:
                              getEntityDocument(id).length === 11
                                ? "person"
                                : "company",
                          })
                        )}
                        ListEmptyComponent={
                          <ListEmptyResult
                            loading={true}
                            placeholder={
                              <Box>
                                <SidebarPlaceholder />{" "}
                              </Box>
                            }
                          />
                        }
                        renderRow={(item) => (
                          <SidebarItem
                            key={item.id}
                            item={item}
                            changeTopic={(topic) =>
                              handleChangeTopic(id, topic)
                            }
                            currentTopic={currentTopic}
                          />
                        )}
                      />
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </Box>
        </AppScrollbar>
      </Fragment>
    );

  console.log("returning default data", topics);
  return (
    <Fragment>
      <AppScrollbar
        sx={{
          height: "calc(100% - 76px)",
        }}
      >
        <Box>
          <List component="nav" aria-label="main mailbox folders">
            <AppList
              data={topics}
              ListEmptyComponent={
                <ListEmptyResult
                  loading={true}
                  placeholder={
                    <Box>
                      {" "}
                      <SidebarPlaceholder />{" "}
                    </Box>
                  }
                />
              }
              renderRow={(item) => (
                <SidebarItem
                  key={item.id}
                  item={item}
                  changeTopic={changeTopic}
                  currentTopic={currentTopic}
                />
              )}
            />
          </List>
        </Box>
      </AppScrollbar>
    </Fragment>
  );
};

Sidebar.propTypes = {
  report: PropTypes.object,
  changeTopic: PropTypes.func.isRequired,
  currentTopic: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  report: {},
  currentTopic: "",
};
