import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { format, parseISO } from "date-fns";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "navy",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const riskLevels = ["Critico", "Alto", "Medio", "Baixo"];

const SetCondition = () => {
  const [loading, setLoading] = useState(false);
  const [pricing, setPricing] = useState("");
  const [customValue, setCustomValue] = useState("");
  const [maxFee, setMaxFee] = useState("");
  const navigate = useNavigate();

  const { getToken } = useAuthMethod();

  const listPricing = useCallback(async () => {
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/pricingsettings`,
        "get",
        token
      );
      setPricing(result);
      setMaxFee(result?.maxFee);
      setCustomValue(result?.customValue);
    } catch (error) {
      toast.error("Erro ao buscar dados");
    }
  }, []);

  React.useEffect(() => {
    listPricing();
  }, []);

  const createPricing = async () => {
    const token = await getToken();

    if (customValue == "" || maxFee == "") {
      return toast.error("Preencha o campo para criar ou atualizar dados.");
    }
    try {
      await request(
        process.env.REACT_APP_API_URL + "/pricingsettings",
        "post",
        token,
        {
          id: pricing?.id,
          maxFee: maxFee,
          customValue: customValue,
        }
      );
      listPricing();
      toast.success(" Criado ou atualizado com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar ");
      setLoading(false);
    }
  };

  const onChangeCustomValue = (event) => {
    setCustomValue(event.target.value);
  };
  const onChangeMaxFee = (event) => {
    setMaxFee(event.target.value);
  };

  const onClickPricing = () => {
    createPricing();
  };

  // const onClickNext = () => {
  //   navigate(`/pricing/analysis/list`);
  // };
  // const onClickCancel = () => {
  //   navigate(`/pricing/analysis/list`);
  // };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Configurar regras
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={6} sx={{ mb: 8 }}>
              <Typography>
                digite a partir de qual valor será direcionado para precificação
                personalizada
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <FormControl size="small">
                <NumericFormat
                  required
                  label="Valor"
                  customInput={TextField}
                  decimalScale={2}
                  fixedDecimalScale
                  value={customValue}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  prefix="R$ "
                  sx={{
                    width: "100%",
                  }}
                  onValueChange={(values, sourceInfo) => {
                    setCustomValue(values.floatValue);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 8 }}>
              <Typography>
                digite o limite de porcentagem a ser liberado como margem de
                desconto
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <FormControl size="small">
                <TextField
                  size="small"
                  id="max"
                  label="Fee maximo"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                    },
                  }}
                  value={maxFee}
                  onChange={onChangeMaxFee}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickPricing}
              >
                Aplicar
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mb: 8 }}>
              <Typography>Configuracoes aplicadas atualmente.</Typography>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="flex-start"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={5} sx={{ mr: 10 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{ color: "#ffffff" }}
                        >
                          Precificacao personalizada
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell align="left">Valor</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {pricing?.customValueDate != null
                            ? format(
                                parseISO(pricing?.customValueDate),
                                "dd-MM-yyyy "
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {currencyLocale.format(pricing?.customValue)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={5}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{ color: "#ffffff" }}
                        >
                          Precificacao padrao
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell align="left">Fee</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {pricing?.maxFeeDate != null
                            ? format(
                                parseISO(pricing?.maxFeeDate),
                                "dd-MM-yyyy "
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="left">{pricing?.maxFee}%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          {/* <Footer>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={onClickCancel}
                >
                  Cancelar
                  {loading === true && <CircularProgress />}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={onClickNext}
                >
                  Avancar
                  {loading === true && <CircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </Footer> */}
        </Card>
      </Box>
    </Fragment>
  );
};

export default SetCondition;
