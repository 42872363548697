import React, { useCallback, useEffect, useState } from "react";
import { plain } from "shared/utils/plain";

import axios from "axios";
import { utils, writeFileXLSX } from "xlsx";

import { Box } from "@mui/material";

import { DataTable } from "elements/dataTable";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { formatDocument } from "shared/utils/formatDocument";

export const Cessionarios = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = React.useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "email",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tipoConta",
      headerName: "Conta",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "banco",
      headerName: "Banco",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agencia",
      headerName: "Agência",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "conta",
      headerName: "Número",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const csvOptions = {
    fileName: `cessionários`,
    delimiter: ";",
    utf8WithBom: true,
  };

  // token da autenticação
  const { getToken } = useAuthMethod();

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchAllAssignees = async () => {
    try {
      setLoading(true);

      // token do ad
      const token = await getToken();
      const result = await fetchData(token);

      console.log(result);

      const d = result.map((item) => {
        return {
          id: item.assigneeAccountId,
          status: item.status,
          cnpj: formatDocument(item.body.taxId),
          nome: item.body.name,
          email: item.body.email.join(" "),
          tipoConta: item.body.accountType,
          banco: item.body.bank,
          agencia: item.body.branch,
          conta: item.body.account,
        };
      });
      setData(d.sort(compare));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllAssignees();
  }, []);

  // Função de comparação pelo nome
  // usada para ordenar a lista
  function compare(a, b) {
    if (plain(a.nome) < plain(b.nome)) {
      return -1;
    }
    if (plain(a.nome) > plain(b.nome)) {
      return 1;
    }
    return 0;
  }

  const fetchData = async (aToken) => {
    try {
      setLoading(true);
      const params = {
        method: "GET",
        /* url: 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees', */
        url: process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${aToken}`,
        },
      };
      const res = await axios(params);
      return res.data.body;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  ////// XLSX ////////

  const generateXLSX = useCallback(() => {
    /* JSON para worksheet */
    var ws = utils.json_to_sheet(data);

    /* adiciona pro workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "People");

    /* gera o XLSX file */
    writeFileXLSX(wb, "sheetjs.xlsx");
  });

  return (
    <Box>
      <Hover loading={loading} />
      <DataTable rows={data} columns={columns} />
    </Box>
  );
};
