import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useState } from "react";
import Radio from "@mui/material/Radio"; // Add this import
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { styled, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Divider from "@mui/material/Divider";
import { Hover } from "elements/hover";
import InputAdornment from "@mui/material/InputAdornment";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import FormControlLabel from "@mui/material/FormControlLabel"; // Add this import
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const StyledTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: 15,
  flexGrow: 1,
  mt: 1,
});

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const CurrentProposal = () => {
  const [loading, setLoading] = useState(false);
  const [pricingPartner, setPricingPartner] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [analysis, setAnalysis] = useState();
  const [bigData, setBigData] = useState();
  const [mdr, setMdr] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSaveGuarantee = () => {
    acceptedGuarantee();
  };

  const { getToken } = useAuthMethod();

  let { id } = useParams();

  const listForecast = useCallback(async () => {
    setLoading(true);
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${id}`,
        "get",
        token
      );
      const resultMdr = await request(
        process.env.REACT_APP_API_URL + `/feemdr/${id}`,
        "get",
        token
      );
      const resultAnalysis = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );
      setSelectedOption(resultAnalysis?.guarantee?.acceptedGuarantee);
      setPricingPartner(result);
      setMdr(resultMdr);
      setBigData(
        resultAnalysis?.bigData?.bigData?.dadosCadastrais &&
        JSON.parse(resultAnalysis?.bigData?.bigData?.dadosCadastrais)
      );
      setAnalysis(resultAnalysis);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao listar dados");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    listForecast();
  }, []);

  const acceptedGuarantee = async () => {
    const token = await getToken();
    const guaranteeId = analysis?.guarantee?.id;
    setLoading(true);

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/acceptedguarantee/${guaranteeId}`,
        "put",
        token,
        {
          acceptedGuarantee: selectedOption,
        }
      );

      toast.success("Garantia salva com sucesso");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao salvar garantia");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              PROPOSTA COMERCIAL
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3} sx={{ mt: 2 }}>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
                textAlign: "left",
              }}
            >
              {analysis?.dateProposal &&
                format(
                  parseISO(analysis?.dateProposal),
                  "dd 'de' MMMM 'de' yyyy",
                  { locale: ptBR }
                )}
            </Typography>
          </Grid>
          <Grid item xs={9} sx={{ mt: 2 }}>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
                textAlign: "left",
              }}
            >
              {bigData?.Result[0]?.BasicData?.OfficialName}
            </Typography>
          </Grid>
          {analysis?.clientType !== "VAR" && (
            <>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 5,
                  }}
                >
                  GARANTIA
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 5,
                  }}
                >
                  Selecione a opção de garantia escolhida pelo cliente:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-label="garantia-options"
                  name="garantia-options"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value={`Opção 1: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption1}`}
                    control={<Radio />}
                    label={`- Opção 1: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption1}`}
                  />
                  <FormControlLabel
                    value={`Opção 2: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption2}`}
                    control={<Radio />}
                    label={`- Opção 2: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption2}`}
                  />
                  <FormControlLabel
                    value={`Opção 3: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption3}`}
                    control={<Radio />}
                    label={`- Opção 3: ${pricingPartner?.[0]?.analysis?.guarantee?.guaranteeOption3}`}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleSaveGuarantee}
                  sx={{
                    backgroundColor: "#000536",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  size="small"
                  disabled={loading}
                >
                  Salvar garantia escolhida
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 5,
                  }}
                >
                  Aceitamos essas modalidades de garantia:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>- Carta fiança;</Typography>
                <Typography>- Seguro fiança;</Typography>
                <Typography>- Depósito caução/pix na conta Entre</Typography>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 5,
                  }}
                >
                  Condição comercial negociada de risco:
                </Typography>
                <Grid item xs={12}>
                  <Typography>{analysis?.specialDescriptionRisk}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 10,
                    ml: 6,
                    mb: 15,
                  }}
                >
                  PRECIFICAÇÃO
                </Typography>
                <Divider />
              </Grid>

              <Grid Item xs={12} sx={{ mt: 5, mb: 4 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={5}
                          sx={{ color: "#ffffff" }}
                        >
                          Preço
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={4}
                          sx={{ color: "#ffffff" }}
                        >
                          Volume transacionado
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          sx={{ color: "#ffffff" }}
                        >
                          Fee (ITC + Fee)
                        </TableCell>
                      </TableRow>
                      {pricingPartner?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>entre</TableCell>
                          <TableCell align="center">
                            {currencyLocale.format(row?.tpvMin)}
                          </TableCell>
                          <TableCell align="center">e</TableCell>
                          <TableCell align="center">
                            {currencyLocale.format(row?.tpvMax)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.discountFee != 0
                              ? row?.discountFee
                              : row?.fee}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography>Taxa Antecipação*</Typography>
                <FormControl size="small">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={pricingPartner?.[0]?.prepaymentFee}
                    disabled
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Typography>
                  *A taxa de antecipação sofrerá alterações conforme mudanças da
                  taxa SELIC, custo de captação e impostos.
                </Typography>
              </Grid>
            </>
          )}
          {analysis?.clientType === "VAR" && (
            <>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 15,
                    flexGrow: 1,
                    textAlign: "center",
                    mt: 10,
                    ml: 10,
                    mb: 15,
                  }}
                >
                  PRECIFICAÇÃO
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <Typography sx={{ mb: 1 }}>
                  Trabalhamos com MDR e Interchange Plus, composto por
                  Intercâmbio + Fee %
                </Typography>
                <Divider />
              </Grid>

              <Grid Item xs={12} sx={{ mt: 1, mb: 4 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={5}
                          sx={{ color: "#ffffff" }}
                        >
                          Proposta MDR
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ backgroundColor: "#000536" }}>
                        <TableCell
                          align="center"
                          colSpan={1}
                          sx={{ color: "#ffffff" }}
                        >
                          MCC
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          sx={{ color: "#ffffff" }}
                        >
                          Bandeira
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          sx={{ color: "#ffffff" }}
                        >
                          Produto
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          sx={{ color: "#ffffff" }}
                        >
                          Fee
                        </TableCell>
                      </TableRow>
                      {mdr?.analysisMdr?.map((row) => (
                        <TableRow key={row?.id}>
                          <TableCell align="center">{row?.mcc?.mcc}</TableCell>
                          <TableCell align="center">{row?.cardBrand}</TableCell>
                          <TableCell align="center">{row?.product}</TableCell>
                          <TableCell align="center">{row?.mdr}%</TableCell>
                        </TableRow>
                      ))}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography>Essa proposta tem validade de 30 dias.</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3, fontWeight: 400, fontSize: 15 }}>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 15,
                flexGrow: 1,
                textAlign: "center",
                mt: 5,
              }}
            >
              {" "}
              Condição comercial negociada de pricing:{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{analysis?.specialDescriptionPricing}</Typography>
          </Grid>
        </Grid>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default CurrentProposal;
