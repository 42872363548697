import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import { ButtonGroup, Typography, List, ListItemText, ListItemButton, Table, TableHead, TableRow, TableCell, TableBody, Button, TableContainer, Switch } from "@mui/material";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { useAuthMethod } from "../../../../hooks/AuthHooks";
import request from "../../../../shared/utils/request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SetDomainModal from "./setDomainModal";
import FormControlLabel from "@mui/material/FormControlLabel";

const operations = [
  { value: "igual", label: "Igual" },
  { value: "igualMenor", label: "Igual ou menor" },
  { value: "igualMaior", label: "Igual ou maior" },
  { value: "comparar", label: "Comparar valor com outra API" },
  { value: "menor", label: "Menor" },
  { value: "contem", label: "Contem" },
  { value: "null", label: "atributo diferente de nulo" },
];

const CategoryList = ({ categories, selectedCategory, onSelectCategory }) => (
  <List component="nav" aria-label="category selector">
    {categories.map((category) => (
      <ListItemButton
        key={category.id}
        selected={selectedCategory === category}
        onClick={() => onSelectCategory(category)}
      >
        <ListItemText primary={category.description} />
      </ListItemButton>
    ))}
  </List>
);

const CategoryDetails = ({ selectedCategory, rules, onSwitchFlexIgnore, onRedirectNewRule, onDomainUpdated }) => {
  const { getToken } = useAuthMethod();
  async function handleToggleSwitch() {
    const token = await getToken();
    try {
      await request(process.env.REACT_APP_API_URL + `/section/${selectedCategory.id}`, "put", token, {
        ...selectedCategory,
        flexibilizeFaceMatch: !selectedCategory?.flexibilizeFaceMatch,
      });
    } catch (error) {
      toast.error("Erro ao atualizar categoria");
    }
    if (onDomainUpdated && typeof onDomainUpdated === 'function') {
      onDomainUpdated();
    }
  }

  return (
    <Box padding='16px' width='100%' border='1px solid #e0e0e0' borderRadius='0 0 8px 0'>
      <Box borderRadius='8px 8px 0 0' padding='8px' display='flex' gap='16px' justifyContent='space-between'
           alignItems='center' bgcolor='#fefefe'>
        {selectedCategory && (
          <>
            <Typography>{selectedCategory.description}</Typography>
            <Typography>Dominio Esteira Flex: {selectedCategory.domain ?? 'NÃO CONFIGURADO'}</Typography>
            <Typography>{rules.length > 0 ? `${rules.length} REGRAS CADASTRADAS` : 'SEM REGRAS CADASTRADAS PARA A FILA'}</Typography>

            <FormControlLabel control={<Switch
              name="flexibilize facematch"
              checked={selectedCategory.flexibilizeFaceMatch}
              inputProps={{"aria-label": "flexibilize facematch"}}
              onChange={handleToggleSwitch}
            />} label="Flexibilizar FaceMatch" />

            <ButtonGroup>
              <SetDomainModal id={selectedCategory.id} onUpdate={onDomainUpdated}/>
              <Button variant='outlined' onClick={onRedirectNewRule}>Nova Regra</Button>
            </ButtonGroup>
          </>
        )}
      </Box>

      {!selectedCategory && (
        <Box sx={{
          minWidth: 650,
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <Box
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '8px'}}>
            <Typography>
              Selecione uma categoria para continuar.
            </Typography>
          </Box>
        </Box>
      )}

      {(selectedCategory && !selectedCategory.domain) && (
        <Box sx={{
          minWidth: 650,
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <Box
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '8px'}}>
            <Typography>
              A categoria selecionada não possui um domínio de esteira flex configurado, portanto, não é possivel
              flexibilizar as regras.
            </Typography>

            <SetDomainModal id={selectedCategory.id} onUpdate={onDomainUpdated}/>
          </Box>
        </Box>
      )}

      {(selectedCategory && selectedCategory.domain) && (
        <RulesTable rules={rules} onSwitchFlexIgnore={onSwitchFlexIgnore}/>
      )}
    </Box>
  )
}

const RulesTable = ({ rules, onSwitchFlexIgnore }) => (
  <TableContainer sx={{ maxHeight: '70vh', bgcolor: '#fefefe' }}>
    <Table bgcolor='#fefefe' sx={{ minWidth: 650 }} aria-label="rules table" stickyHeader={true}>
      <TableHead bgcolor='#fefefe'>
        <TableRow bgcolor='#fefefe'>
          <TableCell align="left">Descricao</TableCell>
          <TableCell align="left">Api</TableCell>
          <TableCell align="left">Parametro</TableCell>
          <TableCell align="left">Operacao</TableCell>
          <TableCell align="left">Valor</TableCell>
          <TableCell align="left">Tipo</TableCell>
          <TableCell align="left">Pontuação</TableCell>
          <TableCell align="left">Critico</TableCell>
          <TableCell align="left">Ignorar na esteira flex</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rules.map((rule) => (
          <TableRow key={rule.id} bgcolor='#fefefe' sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">{rule.description}</TableCell>
            <TableCell component="th" scope="row">{rule.apiBureaus?.apiName}</TableCell>
            <TableCell sx={{ wordBreak: "break-all", width: 200 }} component="th" scope="row">{rule.parameterPath}</TableCell>
            <TableCell align="left">{operations.find((op) => op.value === rule.operation)?.label ?? ""}</TableCell>
            <TableCell align="left">{rule.value}</TableCell>
            <TableCell align="left">{rule.clientType}</TableCell>
            <TableCell align="left">{rule.score}</TableCell>
            <TableCell align="left">{rule.critical ? "Sim" : "Não"}</TableCell>
            <TableCell align="center">
              <Switch
                name="checkedA"
                checked={rule.flexIgnore}
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={() => onSwitchFlexIgnore(rule.id)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

function CategoryManagement() {
  const navigate = useNavigate();
  const { getToken } = useAuthMethod();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [rules, setRules] = useState([]);

  const getCategories = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(process.env.REACT_APP_API_URL + `/section`, "get", token);
      setCategories(result.filter(s => s.accreditation && s.display));
      if(selectedCategory) {
        const category = result.find(c => c.id === selectedCategory.id);
        handleSelectCategory(category);
      }
    } catch (error) {
      toast.error("Erro ao buscar categoria");
    }
  }, [getToken]);

  const getCategoryRules = async (category) => {
    const token = await getToken();
    try {
      const result = await request(process.env.REACT_APP_API_URL + `/rules/${category.id}`, "get", token);
      setRules(result);
    } catch (error) {
      toast.error("Erro ao buscar regras");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    getCategoryRules(category);
  };

  const handleSwitchFlexIgnore = async (id) => {
    const rule = rules.find(r => r.id === id);
    if (!rule) return;

    const token = await getToken();
    try {
      await request(process.env.REACT_APP_API_URL + `/rules/${id}`, "put", token, {
        ...rule,
        flexIgnore: !rule.flexIgnore,
        apiBureaus: rule.apiBureaus.id,
        rulesSectionId: selectedCategory.id,
      });
      getCategoryRules(selectedCategory);
    } catch (error) {
      toast.error("Erro ao atualizar o parâmetro");
    }
  };

  const handleRedirectNewRule = () => {
    navigate(`/accreditation/risk/rules/create/${selectedCategory.id}`);
  };

  return (
    <Box padding='16px'>
      <Box sx={{ display: 'flex', height: '64px', justifyContent: 'center', alignItems: 'center', backgroundColor: "#000536", width: "100%", color: '#fff', borderRadius: '8px 8px 0 0' }}>
        <Typography sx={{ mb: 1.5, fontWeight: Fonts.BOLD, fontSize: 20 }}>
          Gestão de Filas
        </Typography>
      </Box>
      <Box bgcolor='#fefefe' height='70vh'>
        <Box display='flex'>
          <Box height='70vh'>
            <CategoryList categories={categories} selectedCategory={selectedCategory} onSelectCategory={handleSelectCategory} />
          </Box>
          <CategoryDetails
            selectedCategory={selectedCategory}
            rules={rules}
            onSwitchFlexIgnore={handleSwitchFlexIgnore}
            onRedirectNewRule={handleRedirectNewRule}
            onDomainUpdated={() => getCategories()}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CategoryManagement;
