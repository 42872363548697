import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Fonts } from "shared/constants/AppEnums";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const CreateQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [questionType, setQuestionType] = useState("");
  const [questionOrder, setQuestionOrder] = useState();
  const [required, setRequired] = useState(false);
  const [question, setQuestion] = useState("");
  const [active, setActive] = useState(true);
  const [clientType, setClientType] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  const { getToken } = useAuthMethod();

  const createQuestions = async () => {
    const token = await getToken();

    if (
      clientType == "" ||
      question == "" ||
      questionOrder == "" ||
      questionType == ""
    ) {
      return toast.error("Preencha os campos para criar uma pergunta");
    }
    try {
      await request(
        process.env.REACT_APP_API_URL + "/questions",
        "post",
        token,
        {
          active,
          clientType,
          question,
          questionOrder,
          questionType,
          required,
        }
      );

      toast.success("Pergunta criada com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar pergunta");
      setLoading(false);
    }
  };

  const onClickAddQuestion = () => {
    createQuestions();
  };

  const onChangeClientType = (event) => {
    setClientType(event.target.value);
  };

  const onChangeActive = (event) => {
    setActive(event.target.value);
  };

  const onChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const onChangeQuestionOrder = (event) => {
    setQuestionOrder(event.target.value);
  };

  const onChangeQuestionType = (event) => {
    setQuestionType(event.target.value);
  };

  const onChangeRequired = (event) => {
    setRequired(event.target.value);
  };

  const onClickCancel = () => {
    navigate(`/onboarding/risk/questions/listquestions`);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Criar Perguntas
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ justifyContent: "end", display: "flex" }}></Box>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={2}>
              Pergunta
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={question}
                  onChange={(event) => setQuestion(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Area
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" required>
                <Select
                  labelId="clientType"
                  id="clientType"
                  value={clientType}
                  onChange={onChangeClientType}
                >
                  <MenuItem value="" disabled>
                    Selecione uma opção
                  </MenuItem>
                  <MenuItem value="VAR" key="psp">
                    VAR
                  </MenuItem>
                  <MenuItem value="PSP" key="var">
                    PSP
                  </MenuItem>
                  <MenuItem value="COMERCIAL" key="var">
                    COMERCIAL
                  </MenuItem>
                  <MenuItem value="COMERCIAL_VAR" key="var">
                    COMERCIAL_VAR
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Tipo
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" required>
                <Select
                  labelId="questionType"
                  id="questionType"
                  value={questionType}
                  onChange={onChangeQuestionType}
                >
                  <MenuItem value="" disabled>
                    Selecione uma opção
                  </MenuItem>
                  <MenuItem value="text" key="text">
                    Text
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              Ordem
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={questionOrder}
                  onChange={onChangeQuestionOrder}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Ativo
            </Grid>
            <Grid item xs={4}>
              <Switch onChange={(e) => setActive(e.target.checked)} />
            </Grid>

            <Grid item xs={2}>
              Obrigatorio
            </Grid>
            <Grid item xs={4}>
              <Switch onChange={(e) => setRequired(e.target.checked)} />
            </Grid>

            <Grid item xs={12}>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickAddQuestion}
              >
                Adicionar
                {loading === true && <CircularProgress />}
              </Button>
            </Grid>
          </Grid>

          <Footer>
            <Button
              sx={{ backgroundColor: "navy", borderRadius: "10px" }}
              variant="contained"
              disabled={loading}
              onClick={onClickCancel}
            >
              Voltar
              {loading === true && <CircularProgress />}
            </Button>
          </Footer>
        </Card>
      </Box>
    </Fragment>
  );
};

export default CreateQuestions;
