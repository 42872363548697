import React from "react";
import Box from "@mui/material/Box";
import { TextareaAutosize, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const Desicion = () => {
  const { getToken } = useAuthMethod();
  const { user } = useAuthUser();
  const [open, setOpen] = React.useState(false);
  const [proposal, setProposal] = useState({ title: null, description: null });

  const handleClickRejected = () => {
    setStatus("Rejeitado");
    setOpen(true);
  };

  const handleClickApproved = () => {
    setStatus("Aprovado");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSave = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          user: user?.displayName,
          comment: comment,
          statusRisk: status,
        }
      );

      try {
        const result = await request(
          process.env.REACT_APP_API_URL +
            `/accreditation/api/retAnalysis/${id}`,
          "post",
          token
        );
      } catch (error) {
        toast.error("Erro ao retornar proposta ao Credenciamento");
      }

      toast.success(" Salvo com sucesso!");
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao salvar");
    } finally {
      setLoading(false);
    }
  };

  let { id } = useParams();

  return (
    <Box>
      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        {(proposal?.statusRisk?.toUpperCase() === "EM ANALISE" ||
          proposal?.statusRisk?.toUpperCase() === "AJUSTAR GARANTIA") && (
          <>
            <Button
              onClick={handleClickApproved}
              variant="contained"
              component="label"
              sx={{
                marginRight: 5,
                marginTop: 2,
                padding: "9px 12px",
                lineHeight: 1,
                width: 96,
                fontWeight: Fonts.MEDIUM,
                backgroundColor: "#000536",
              }}
            >
              Aprovar
            </Button>
            <Button
              onClick={handleClickRejected}
              variant="contained"
              component="label"
              sx={{
                marginRight: 5,
                marginTop: 2,
                padding: "9px 12px",
                lineHeight: 1,
                width: 96,
                fontWeight: Fonts.MEDIUM,
                backgroundColor: "#000536",
              }}
            >
              Rejeitar
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Confirmacao</DialogTitle>
              <DialogContent>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="min"
                    label="Responsavel"
                    variant="outlined"
                    sx={{ mb: 4, mt: 3 }}
                    value={user?.displayName}
                    disabled={true}
                  />
                </FormControl>

                <TextareaAutosize
                  onChange={handleOnChangeComment}
                  aria-label="minimum height"
                  minRows={3}
                  placeholder=" Descreva o motivo para tal acao."
                  style={{ width: "100%" }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleClickSave}>Salvar</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Desicion;
