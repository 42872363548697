import * as React from "react";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Hover } from "elements/hover";
import PropTypes from "prop-types";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

function DocumentsUpload(props) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState();

  const { id } = useParams();
  const { getToken } = useAuthMethod();

  const handleClickUpload = async (uploadId) => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + `/upload/url/${uploadId}`
      );
      window.open(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  const listFiles = async function () {
    setLoading(true);
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/documentsupload/${id}`,
        "get",
        token
      );
      const analysisResult = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );
      let docs = result;

      docs = docs.filter(
        (v) => v.documentType == "Pricing" || v.documentType == "Risk"
      );
      setAnalysis(analysisResult);
      setDocuments(docs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listFiles();
  }, [id]);

  return (
    <div>
      <Typography sx={{ padding: 5, fontSize: 16 }}>
        Evidências das Condições Especiais
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Área envolvida</TableCell>
              <TableCell>Descrição da Condição</TableCell>
              <TableCell>Evidência de Aprovação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Pricing
              </TableCell>
              <TableCell>{analysis?.specialDescriptionPricing}</TableCell>
              <TableCell sx={{ wordBreak: "break-all", width: 300 }}>
                {documents
                  ?.filter((doc) => doc.documentType === "Pricing")
                  ?.map((row) => {
                    return (
                      <React.Fragment key={row.id}>
                        <a
                          href={"#void"}
                          onClick={() => handleClickUpload(row.id)}
                        >
                          {row.fileName}
                        </a>{" "}
                      </React.Fragment>
                    );
                  })}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Risco
              </TableCell>
              <TableCell>{analysis?.specialDescriptionRisk}</TableCell>
              <TableCell sx={{ wordBreak: "break-all", width: 300 }}>
                {documents
                  ?.filter((doc) => doc.documentType === "Risk")
                  ?.map((row) => {
                    return (
                      <React.Fragment key={row.id}>
                        <a
                          href={"#void"}
                          onClick={() => handleClickUpload(row.id)}
                        >
                          {row.fileName}
                        </a>{" "}
                      </React.Fragment>
                    );
                  })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Hover loading={loading} />
    </div>
  );
}

export default DocumentsUpload;
DocumentsUpload.defaultProps = {};
DocumentsUpload.propTypes = {
  complianceOnly: PropTypes.boolean,
};
