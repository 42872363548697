import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { PatternFormat } from "react-number-format";

const FormikPatternFormat = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (values, sourceInfo) => {
    helpers.setValue(values.floatValue);
  };

  return (
    <>
      <PatternFormat
        {...field}
        {...props}
        customInput={TextField}
        onValueChange={handleChange}
        style={{ width: "100%" }}
      />
      {meta.touched && meta.error && (
        <div style={{ color: "red", textAlign: "left", fontSize: "0.64rem" }}>
          {meta.error}
        </div>
      )}
    </>
  );
};

export default FormikPatternFormat;
