import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState, useCallback, Fragment } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "navy",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const riskLevels = ["Critico", "Alto", "Medio", "Baixo"];

const CreateRules = () => {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [totalScore, setTotalScore] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [description, setDescription] = useState("");
  const [action, setAction] = useState("");
  const [minScore, setMinScore] = useState("");
  const [maxScore, setMaxScore] = useState("");
  const [riskLevel, setRiskLevel] = useState("");
  const navigate = useNavigate();

  const { getToken } = useAuthMethod();

  const listCategory = useCallback(async () => {
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/section`,
        "get",
        token
      );
      setSections(
        result.filter(section => section.accreditation && section.display)
      );
    } catch (error) {
      toast.error("Erro ao buscar categoria");
    }
  }, []);

  const listTotalScore = useCallback(async () => {
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/totalscore`,
        "get",
        token
      );
      setTotalScore(result);
    } catch (error) {
      toast.error("Erro ao buscar score");
    }
  }, []);

  React.useEffect(() => {
    listTotalScore();
    listCategory();
  }, [listTotalScore, listCategory]);

  const createCategory = async () => {
    const token = await getToken();

    if (description == "") {
      return toast.error("Preencha o campo para criar nova categoria");
    }
    try {
      await request(process.env.REACT_APP_API_URL + "/section", "post", token, {
        description: description,
      });
      listCategory();
      toast.success("Categoria criada com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar categoria");
      setLoading(false);
    }
  };

  const createTotalScore = async () => {
    const token = await getToken();

    try {
      if (riskLevel == "" || minScore == "" || maxScore == "" || action == "") {
        return toast.error("Preencha todos os campos");
      }
      await request(
        process.env.REACT_APP_API_URL + "/totalscore",
        "post",
        token,
        {
          action: action,
          minScore: minScore,
          maxScore: maxScore,
          riskLevel: riskLevel,
        }
      );
      listTotalScore();
      toast.success("Categoria criada com sucesso.");
      setRiskLevel("");
      setMinScore("");
      setMaxScore("");
      setAction("");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar categoria");
      setLoading(false);
    }
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };
  const onChangeRiskLevel = (event) => {
    setRiskLevel(event.target.value);
  };
  const onChangeMinScore = (event) => {
    setMinScore(event.target.value);
  };
  const onChangeMaxScore = (event) => {
    setMaxScore(event.target.value);
  };
  const onChangeAction = (event) => {
    setAction(event.target.value);
  };

  const onClickDescription = () => {
    createCategory();
  };

  const onClickScore = () => {
    createTotalScore();
  };

  const onClickNext = () => {
    if (!selectedSection) {
      return toast.error("Por favor selectione uma categoria.");
    }
    navigate(`/accreditation/risk/rules/create/${selectedSection}`);
  };
  const onClickCancel = () => {
    navigate(`/accreditation/risk/analysis/analysislist`);
  };
  const onClickDeleteSection = async () => {
    const token = await getToken();

    try {
      await request(
        process.env.REACT_APP_API_URL + `/section/${selectedSection}`,
        "delete",
        token,
        {}
      );
      listCategory();
      toast.success("Categoria deletada com sucesso.");
      setSelectedSection("");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao deletar categoria");
      setLoading(false);
    }
  };

  const onClickDeleteScore = async (scoreId) => {
    const token = await getToken();

    try {
      await request(
        process.env.REACT_APP_API_URL + `/totalscore/${scoreId}`,
        "delete",
        token,
        {}
      );
      listTotalScore();
      toast.success("Score deletado com sucesso.");
      setSelectedSection("");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao deletar score");
      setLoading(false);
    }
  };

  const onChangeSelectedSection = (event) => {
    setSelectedSection(event.target.value);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Configurar regras
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12} sx={{ mb: 8 }}>
              <Typography>
                1) Primeiro selecione ou crie a categoria para qual a regra será
                criada
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  value={selectedSection}
                  onChange={onChangeSelectedSection}
                >
                  {sections?.map((section) => {
                    return (
                      <MenuItem key={section.id} value={section?.id}>
                        {section?.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={2}>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading || selectedSection == ""}
                onClick={onClickDeleteSection}
              >
                Deletar
              </Button>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Nova categoria"
                  variant="outlined"
                  value={description}
                  onChange={onChangeDescription}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                value="description"
                onClick={onClickDescription}
                disabled={loading}
              >
                Adicionar
              </Button>
            </Grid> */}
            <Grid item xs={12} sx={{ mb: 8, mt: 8 }}>
              <Typography>
                2) Configure as pontuações para cada grau de risco e ação a ser
                efetuada.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Nivel Risco
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Nivel de Risco"
                  value={riskLevel}
                  onChange={onChangeRiskLevel}
                >
                  {riskLevels?.map((level, index) => {
                    return (
                      <MenuItem key={index} value={level}>
                        {level}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="min"
                  label="Pontuacao minima"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  value={minScore}
                  onChange={onChangeMinScore}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="max"
                  label="Pontuacao maxima"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  value={maxScore}
                  onChange={onChangeMaxScore}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="categoryAction">Acao</InputLabel>
                <Select
                  labelId="categoryAction"
                  id="demo-simple-select"
                  label="Age"
                  value={action}
                  onChange={onChangeAction}
                >
                  <MenuItem value={"APROVADO"}>Aprovado</MenuItem>
                  <MenuItem value={"REPROVADO"}>Reprovado</MenuItem>
                  <MenuItem value={"ANALISE MANUAL"}>Analise Manual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickScore}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mb: 8 }}>
              <Typography>3) Configuracoes aplicadas atualmente.</Typography>
            </Grid>
            <Grid Item xs={9}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#000536" }}>
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{ color: "#ffffff" }}
                      >
                        Classificacao de Cliente em Categoria
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        sx={{ color: "#ffffff" }}
                      >
                        Criterio de Decisao Acoes Aplicaveis
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Grau de Risco</TableCell>
                      <TableCell align="left">Pontuacao</TableCell>
                      <TableCell align="left" colSpan="2">
                        Acao
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {totalScore?.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ backgroundColor: row?.color }}
                          component="th"
                          scope="row"
                        >
                          {row?.riskLevel}
                        </TableCell>
                        <TableCell align="left">
                          {row?.minScore} a {row.maxScore}{" "}
                        </TableCell>
                        <TableCell align="left">{row?.action}</TableCell>
                        <TableCell align="left">
                          <Button
                            sx={{
                              backgroundColor: "navy",
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            disabled={loading}
                            onClick={() => onClickDeleteScore(row.id)}
                          >
                            Deletar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Footer>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={onClickCancel}
                >
                  Cancelar
                  {loading === true && <CircularProgress />}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={onClickNext}
                >
                  Avancar
                  {loading === true && <CircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </Footer>
        </Card>
      </Box>
    </Fragment>
  );
};

export default CreateRules;
