import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Row from "../PortfolioManagementColapsibleRow";

export default function CollapsibleTable(props) {
  const { data } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nome do Arquivo</TableCell>
            <TableCell align="right">Registros</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={data.fileName} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
