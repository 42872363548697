import React, { useEffect, useState } from "react";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import uuid4 from "uuid4";
import XLSX from "xlsx";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import Papa from "papaparse";

export const RegistroContratoCessao = () => {
  const [loading, setLoading] = useState(false);

  const [dataCSV, setDataCSV] = useState();

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [amount, setAmount] = useState(0);

  const [reservationDate, setReservationDate] = useState(dayjs());
  const handleChangeDate = (newValue) => {
    setReservationDate(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [selectedPaymentNetwork, setSelectedPaymentNetwork] = useState("");
  const handleChangePaymentNetwork = (event) => {
    setSelectedPaymentNetwork(event.target.value);
  };

  const [cardIssuers, setCardIssuers] = useState([]);
  const [selectedCardIssuer, setSelectedCardIssuer] = useState("");
  const handleChangeCardIssuer = (event) => {
    setSelectedCardIssuer(event.target.value);
  };

  const [allCardIssuers, setAllCardIssuers] = useState([]);

  const [filterCardIssuer, setFilterCardIssuer] = useState("");
  const handleChangeFilterCardIssuer = (e) => {
    setFilterCardIssuer(e.target.value.toLowerCase());
  };

  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const handleChangeAssignee = (event) => {
    setSelectedAssignee(event.target.value);
  };

  /* const handleUpload = (event) => {
      const file = event.target.files[0];

      Papa.parse(file, {
        complete: (result) => {
          // Create an array to store all arrays (rows)
          const arrayOfArrays = [];

          // Iterate through each row of the CSV data
          result.data.forEach((row) => {
            // Convert the row object to an array
            const rowArray = Object.values(row);

           // Verifique se a array não contém uma linha vazia
            if (!rowArray.some((item) => item === "")) {
              const cleanedRowArray = rowArray.map((item) =>
                typeof item === 'string' ? item.replace(/[.,]/g, '') : item
              );
              arrayOfArrays.push(cleanedRowArray);
            }
          });

          console.log(arrayOfArrays)

          // Loop através da array para substituir o cardIssuer
          arrayOfArrays.forEach((array) => {
            allCardIssuers.forEach((issuer) => {

              //Retira espaçamentos do emissor no final e no começo caso tenha
              array[3] = array[3].trim();
              // Verifica as condições
              if (issuer.paymentNetwork === array[0] && issuer.label === array[3]) {
                console.log(issuer.name);
                console.log(issuer.label);

                // Substitui o valor no array atual
                array[3] = issuer.value;
              }
            });
          });

          // Agora arrayOfArrays contém todos os arrays com as substituições
          setDataCSV(arrayOfArrays);
        },
        header: true, // Indica que a primeira linha é o cabeçalho
      });
    };
   */

  const handleUpload = (event) => {
    const file = event.target.files[0];

    if (!file || file.type !== "text/csv") {
      toast.error("Apenas arquivos CSVs são permitidos");
      return;
    }

    Papa.parse(file, {
      complete: (result) => {
        // Create an array to store all arrays (rows)
        const arrayOfArrays = [];

        let verification = true;
        console.log(result);

        // Iterate through each row of the CSV data
        result.data.forEach((row) => {
          console.log(row);
          // Convert the row object to an array
          const rowArray = Object.values(row);

          // Verifique se a array não contém uma linha vazia
          if (!rowArray.some((item) => item === "")) {
            if (rowArray[5]) {
              rowArray[4] = rowArray[4] + "," + rowArray[5];
              console.log(rowArray[4]);
            }

            rowArray[4] = rowArray[4].replace(/\s*R\s*\$\s*/, "");
            console.log(rowArray[4]);

            rowArray[0] = rowArray[0].toUpperCase();

            /* console.log("VALOR ANTES:", rowArray[4]);
                        rowArray[4] = parseFloat(rowArray[4].replace(/\./g, '').replace(',', '.'))
                        console.log("VALOR DEPOIS:", rowArray[4])
                        console.log("VALOR DEPOIS DA VIRGULA:", rowArray[5])

                        let valorNumerico = rowArray[4]
                        rowArray[4] = valorNumerico

                        console.log("VALOR NUMERICO:", valorNumerico)
                        console.log("VALOR ARRAY:", rowArray[4])

             */
            function formatarValor(valor) {
              const valorNumerico = parseFloat(
                valor.toString().replace(/\./g, "").replace(",", ".")
              );
              const valorArredondado = valorNumerico
                .toFixed(2)
                .replace(".", ",");
              return valorArredondado.replace(",", "");
            }

            /*  const valores = [
                           "6.900.000",
                           6900000,
                           "6900000,00",
                           "6.900.000,00",
                           "6.900.000,21",
                           "6900000,212",
                           "6900000,29",
                           "6900000,296",
                           "6900000,296451"
                         ]; */

            const valoresFormatados = formatarValor(rowArray[4]);
            rowArray[4] = valoresFormatados;
            console.log(valoresFormatados);

            if (isNaN(valoresFormatados)) {
              toast.error("A planilha possui valores invalidos");
              verification = false;
              return;
            }

            /* rowArray[4] = rowArray[4].replace(/[.,]/g, '');
                        let valorNumerico = rowArray[4]

                        console.log("VALOR NUMERICO:", valorNumerico)
                        console.log("VALOR ARRAY:", rowArray[4])

                          // Verifique se é um número válido
                        if (!isNaN(valorNumerico)) {
                          const formatoMoeda = valorNumerico.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          });

                          console.log(formatoMoeda);
                        } else {
                          console.log(`Valor inválido: ${valorNumerico}`);
                        } */
            // Agora você tem a string vlTituloComVirgula com os dois valores separados por vírgula
            arrayOfArrays.push(rowArray);
          }
        });

        if (verification === true) {
          console.log("VALOR:", arrayOfArrays);
          setDataCSV(arrayOfArrays);
        }
        // Now arrayOfArrays contains an array for each row of the CSV data
      },
      header: true, // Indica que a primeira linha é o cabeçalho
    });
  };

  const generateFile = () => {
    const rows = [
      {
        Bandeira: "Value1",
        Cessionario: "Value2",
        Data: "Value3",
        Ica: "Value4",
        Valor: "Value5",
      },
    ];

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["Bandeira", "Cessionario", "Data", "Emissor", "Valor"]],
      { origin: "A1" }
    );

    /* calculate column width */
    const max_width = Object.keys(rows[0]).reduce(
      (w, key) => Math.max(w, key.length),
      10
    );
    worksheet["!cols"] = [{ wch: max_width }];

    /* create a CSV file and try to save it as Presidents.csv */
    XLSX.writeFile(workbook, "Example.csv", {
      bookType: "csv",
      compression: true,
    });
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks' */
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
      console.log(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees' */
        process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees"
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      setAssignees(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores da bandeira selecionada
  const fetchCardcardIssuers = async () => {
    try {
      if (selectedPaymentNetwork.length === 0) {
        setCardIssuers([]);
        return;
      }

      setLoading(true);
      /* const result = await fetchData(
              'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
              {
                paymentNetwork: selectedPaymentNetwork,
              }
            ); */

      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/card-issuer/search",
        {
          paymentNetwork: selectedPaymentNetwork,
        }
      );

      console.log(result);

      const d = result.map((item) => {
        return {
          value: item?.cardIssuerId ?? "",
          label: item?.name ?? "",
        };
      });

      // Ordenar o array d em ordem alfabetica
      d.sort((a, b) => a.label.localeCompare(b.label));
      setCardIssuers((prevCardIssuers) => [...prevCardIssuers, ...d]);
    } catch (error) {
      console.log(error);
      if (error.message) {
        toast.error(error.message);
      }
      setCardIssuers([]);
    } finally {
      setLoading(false);
    }
  };

  const getAllIssuers = async () => {
    try {
      setLoading(true);

      for (const paymentNetwork of paymentNetworks) {
        /* const result = await fetchData(
                  'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
                  {
                    paymentNetwork: paymentNetwork.value,
                  }
                ); */

        const result = await fetchData(
          process.env.REACT_APP_CLIENT_BTG +
            "/tr/depositary-bank/card-issuer/search",
          {
            paymentNetwork: paymentNetwork.value,
          }
        );

        console.log(result);

        const d = result.map((item) => {
          return {
            paymentNetwork: item?.paymentNetwork ?? "",
            value: item?.cardIssuerId ?? "",
            label: item?.name ?? "",
          };
        });
        // Ordenar o array d em ordem alfabetica
        setAllCardIssuers((prevCardIssuers) => [...prevCardIssuers, ...d]);
      }
    } catch (error) {
      console.log(error);
      if (error.message) {
        toast.error(error.message);
      }
      setAllCardIssuers([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const handleRegister = async (event) => {
    try {
      setLoading(true);

      // token do ad
      const token = await getToken();

      const params = {
        method: "POST",
        /* url: 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservation', */
        url:
          process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/credit-reservation",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          clientRequestId: uuid4(),
          amount: amount * 100,
          reservationDate: reservationDate.format("YYYY-MM-DD"),
          paymentNetwork: selectedPaymentNetwork,
          assigneeAccountId: selectedAssignee,
          cardIssuerId: selectedCardIssuer,
        },
      };
      const res = await axios(params);
      console.log(res.data);
      if (res.status === 200) {
        const id = res.data.body.pactualId;
        toast.success(`Contrato registrado com id ${id}`);
      } else {
        let msg = "";
        for (const e of res.errors) {
          console.log(e.message);
          msg += e.message + " ";
        }
        throw msg;
      }
    } catch (error) {
      console.log(error.message);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchArray = async (aToken, aPage = 0) => {
    console.log("Entrou aqui");
    const token = await getToken();
    setLoading(true);

    const params = {
      method: "POST",
      url: process.env.REACT_APP_CLIENT_BTG_SERVER + "/planilha/upload",
      data: JSON.stringify(dataCSV),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    };

    return axios(params)
      .then((res) => {
        console.log(res);
        toast.success("Planilha enviada!");
      })
      .catch((error) => {
        console.log(error.response);
        toast.error("Erro ao realizar a chamada ao backend");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTaxId = async (assigneeAccountId) => {
    const makeApiRequest = async () => {
      /* const url = `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignee/${assigneeAccountId}`; */
      const url =
        process.env.REACT_APP_CLIENT_BTG +
        `/tr/depositary-bank/assignee/${assigneeAccountId}`;
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer 6NhpTn46L4Yi03Ou5J8qOH3VHGLc`,
            "Content-Type": "application/json",
          },
        });
        const taxId = response.data.body.body.taxId;
        const issuerName = response.data.body.body.name;
        return { taxId, issuerName };
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    };
    return makeApiRequest();
  };

  const filteredIssuers = cardIssuers.filter((item) =>
    item.label.toLowerCase().includes(filterCardIssuer)
  );

  /**
   * useEffect
   */
  // Inicial
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  useEffect(() => {
    getAllIssuers();
  }, [paymentNetworks]);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardcardIssuers();
  }, [selectedPaymentNetwork]);

  useEffect(() => {
    console.log(amount);
  }, [amount]);

  useEffect(() => {
    if (dataCSV) {
      console.log(dataCSV);
      fetchArray();
    }
  }, [dataCSV]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Bandeira</InputLabel>
          <Select
            required
            value={selectedPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: "100%",
            }}
          >
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Emissor</InputLabel>
          <Select
            required
            value={selectedCardIssuer}
            label="Emissor"
            onChange={(e) => handleChangeCardIssuer(e)}
            sx={{
              width: "100%",
            }}
          >
            {filteredIssuers.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "100%",
            }}
            label="Filtro Emissor"
            variant="outlined"
            value={filterCardIssuer}
            onChange={handleChangeFilterCardIssuer}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Cessionário</InputLabel>
          <Select
            required
            value={selectedAssignee}
            label="Emissor"
            onChange={(e) => handleChangeAssignee(e)}
            sx={{
              width: "100%",
            }}
          >
            {assignees.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            width: 0,
            flexBasis: "100%",
          }}
        />

        <FormControl sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              required
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"Data"}
              value={reservationDate}
              onChange={handleChangeDate}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="Valor"
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            value={amount}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix="R$ "
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setAmount(values.floatValue);
            }}
          />
        </FormControl>

        {/* <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            label="Observação"
            variant="outlined"
            sx={{
              width: '100%',
            }}
          />
        </FormControl> */}

        <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleRegister}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Registrar
          </Button>
        </FormControl>
      </Box>

      <Box
        sx={{
          borderTop: "solid",
          width: "100%",
        }}
      />

      <FormControl fullWidth>
        <input
          accept=".csv"
          onChange={handleUpload}
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="raised"
            component="span"
            sx={{
              m: 1,
              width: "300px",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Upload de arquivo para registro em lote
          </Button>
        </label>
      </FormControl>

      <FormControl fullWidth>
        <Button
          onClick={generateFile}
          sx={{
            m: 1,
            width: "300px",
            color: "secondary.contrastText",
            bgcolor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
        >
          Download Exemplo
        </Button>
      </FormControl>
    </Box>
  );
};
