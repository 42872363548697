import * as React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export default function FallbackSpinner() {
  return (
    <div>
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
