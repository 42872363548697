import React, { useState } from "react";
import axios from "axios";

import { Box, Button, FormControl, TextField } from "@mui/material";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { DataTable } from "elements/dataTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const ConsultaExtrato = () => {
  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [valuePactualId, setValuePactualId] = useState("");
  const handleChangeValuePactualId = (event) => {
    console.log(event.target.value);
    setValuePactualId(event.target.value);
  };

  const [valueErrorColumns, setErrorColumns] = useState("false");
  const handleErrorColumns = (event) => {
    setErrorColumns(event.target.value);
  };

  /* const [valueTargetDate, setValueTargetDate] = useState(dayjs());
    const handleChangeTargetDate = (newValue) => {
      setValueTargetDate(dayjs(newValue));
    }; */

  const [dataSaldo, setDataSaldo] = useState([]);

  const [dataTratada, setDataTratada] = useState([]);

  // Data Início
  const [valueDataInicio, setDataInicio] = React.useState(dayjs()); // Data Inicio
  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue);
  };

  // Data Fim
  const [valueDataFim, setDataFim] = React.useState(dayjs()); // Data Fim
  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue);
  };

  const [valueAccountId, setValueAccountId] = useState("");
  const handleChangeValueAccountId = (event) => {
    console.log(event.target.value);
    setValueAccountId(event.target.value);
  };

  const columns = [
    {
      field: "balance",
      headerName: "balance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "availableBalance",
      headerName: "availableBalance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "blockedAmount",
      headerName: "blockedAmount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "account",
      headerName: "account",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "searchDate",
      headerName: "searchDate",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "saldoFinal",
      headerName: "saldoFinal",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "type",
      headerName: "type",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Data",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "movementDescription",
      headerName: "movementDescription",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "amount",
      headerName: "Valor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;

        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "movementId",
      headerName: "movementId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountId",
      headerName: "accountId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cashDirection",
      headerName: "Direção do Credito",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "creditName",
      headerName: "creditName",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "debitAccountType",
      headerName: "debitAccountType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const columnsSaldo = [
    {
      field: "balance",
      headerName: "balance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "availableBalance",
      headerName: "availableBalance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "blockedAmount",
      headerName: "blockedAmount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "account",
      headerName: "account",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "searchDate",
      headerName: "searchDate",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "accountId",
      headerName: "accountId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "saldoFinal",
      headerName: "saldoFinal",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;
        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  const fetchData = async (aUrl = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const getExtratos = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];

      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity: "listMovement" + process.env.REACT_APP_ENVIROMENT,
            dataInicial: valueDataInicio.format("'YYYY-MM-DD'"),
            dataFinal: valueDataFim.format("'YYYY-MM-DDT23:59:59[Z]'"),
            accountId: `"${valueAccountId}"`,
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);
      return newArray;
    } catch (error) {
      toast.error(`Erro na chamada do extrato: ${error.message}`);
      console.error(error);
    }
  };

  const getFinancialTransactions = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];

      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity:
              "listFinancialTransaction" + process.env.REACT_APP_ENVIROMENT,
            dataInicial: valueDataInicio.format("'YYYY-MM-DD'"),
            dataFinal: valueDataFim.format("'YYYY-MM-DDT23:59:59[Z]'"),
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      return newArray;
    } catch (error) {
      toast.error(`Erro na chamada das Transaçoes: ${error.message}`);
      console.error(error);
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const getContratos = async () => {
    try {
      setLoading(true);

      // Pega o token do login para passar na chamada
      const token = await getToken();
      const extratosResult = await getExtratos(token);
      const financialTransactions = await getFinancialTransactions(token);

      console.log("EXTRATOS:", extratosResult);
      console.log("FINANCIAL TRANSACTIONS:", financialTransactions);

      let arrayResultados = [];

      const enrichedResult = extratosResult.map((extratos) => {
        const key = `${extratos.movementId}`;
        const equalValues = financialTransactions.find(
          (transacao) => transacao.movementId === extratos.movementId
        );
        /* const cessionarioDataTodos = resultFetchLimiteCessionario.find(dataLimiteCessionario =>
                  dataLimiteCessionario.cessionario === dataContratos.cessionario &&
                  dataLimiteCessionario.emissor === "Todos"
                ); */

        if (equalValues) {
          let novoObjetoData = {};
          novoObjetoData.type = extratos.type;
          novoObjetoData.date = extratos.date;
          novoObjetoData.amount = extratos.amount;
          novoObjetoData.movementId = extratos.movementId;
          novoObjetoData.movementDescription = extratos.movementDescription;
          novoObjetoData.accountId = extratos.accountId;
          novoObjetoData.errorCodes = extratos.errorCodes;
          novoObjetoData.errorMessages = extratos.errorMessages;
          novoObjetoData.insertTimestamp = extratos.insertTimestamp;
          novoObjetoData.pactualIdFailed = extratos.pactualIdFailed;
          novoObjetoData.cashDirection =
            equalValues?.cashDirection ?? "Não possui Webhook";
          novoObjetoData.creditName =
            equalValues?.creditName ?? "Não possui Webhook";
          novoObjetoData.debitAccountType =
            equalValues?.debitAccountType ?? "Não possui Webhook";
          console.log("As propriedades batem:", extratos);
          arrayResultados.push(novoObjetoData);
        } else {
          let novoObjetoData = {};
          novoObjetoData.type = extratos?.type ?? "Sem informação no database";
          novoObjetoData.date = extratos?.date ?? "Sem informação no database";
          novoObjetoData.amount =
            extratos?.amount ?? "Sem informação no database";
          novoObjetoData.movementId =
            extratos?.movementId ?? "Sem informação no database";
          novoObjetoData.movementDescription =
            extratos?.movementDescription ?? "Sem informação no database";
          novoObjetoData.accountId =
            extratos?.accountId ?? "Sem informação no database";
          novoObjetoData.errorCodes =
            extratos?.errorCodes ?? "Sem informação no database";
          novoObjetoData.errorMessages =
            extratos?.errorMessages ?? "Sem informação no database";
          novoObjetoData.insertTimestamp =
            extratos?.insertTimestamp ?? "Sem informação no database";
          novoObjetoData.pactualIdFailed =
            extratos?.pactualIdFailed ?? "Sem informação no database";
          novoObjetoData.cashDirection =
            equalValues?.cashDirection ?? "Não possui Webhook";
          novoObjetoData.creditName =
            equalValues?.creditName ?? "Não possui Webhook";
          novoObjetoData.debitAccountType =
            equalValues?.debitAccountType ?? "Não possui Webhook";
          console.log("Propriedades não batem para:", extratos);
          arrayResultados.push(novoObjetoData);
          /* arrayResultados.push(extratos) */
        }

        /* if (cessionarioDataTodos) {
                  let novoObjetoDataTodos = {}
                  novoObjetoDataTodos.cessionario = dataContratos.cessionario
                  novoObjetoDataTodos.emissor = cessionarioDataTodos.emissor
                  novoObjetoDataTodos.limite = cessionarioDataTodos.limite;
                  novoObjetoDataTodos.disponivel = cessionarioDataTodos.limite - dataContratos.reservado
                  novoObjetoDataTodos.reservado = dataContratos.reservado
                  novoObjetoDataTodos.observacao = cessionarioDataTodos.observacao
                  console.log("As propriedades batem(EMISSARIO TODOS):", dataContratos)
                  arrayResultados.push(novoObjetoDataTodos)
                } else {
                  console.log('Propriedades não batem para(EMISSARIO TODOS):', dataContratos);
                } */
      });
      console.log("Array tratada:", arrayResultados);
      setDataTratada(arrayResultados);
      return arrayResultados;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSaldoFinalDoDia = async (contratos) => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* `https://backend.entrepay.com.br/btg-homolog/cm/account/balance/${valueAccountId}/${dayjs(valueDataInicio).subtract(1, 'day').format('YYYY-MM-DD')}`); */
        process.env.REACT_APP_CLIENT_BTG +
          `/cm/account/balance/${valueAccountId}/${dayjs(valueDataInicio)
            .subtract(1, "day")
            .format("YYYY-MM-DD")}`
      );
      console.log(result);

      // Filtrar objetos com cashDirection "CashIn" e "CashOut"
      const cashInObjects = contratos.filter(
        (item) => item.cashDirection === "CashIn"
      );
      const cashOutObjects = contratos.filter(
        (item) => item.cashDirection === "CashOut"
      );

      // Somar os valores de "amount" dos objetos CashIn
      const totalCashIn = cashInObjects.reduce(
        (acc, item) => acc + item.amount,
        0
      );

      // Subtrair os valores de "amount" dos objetos CashOut
      const totalCashOut = cashOutObjects.reduce(
        (acc, item) => acc - item.amount,
        0
      );

      // Somar o resultado com "availableBalance"
      const resultadoFinal =
        result.availableBalance + totalCashIn + totalCashOut;

      result.saldoFinal = resultadoFinal;

      setDataTratada((prevState) => [...prevState, result]);
      return result;
    } catch (error) {
      console.log(error.message);
      toast.error(`Erro na chamada do Saldo: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (event) => {
    // Pega o token do login para passar na chamada
    if (valueAccountId.length <= 0) {
      toast.error("Campo accountId vazio!");
    } else {
      const contratos = await getContratos();
      console.log(contratos);
      const saldo = await getSaldoFinalDoDia(contratos);
      console.log(saldo);
      console.log("ESTADO FINAL:", dataTratada);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <FormControl fullWidth>
          <TextField
            sx={{
              width: "100%",
            }}
            label="Account Id"
            variant="outlined"
            value={valueAccountId}
            onChange={handleChangeValueAccountId}
          />
        </FormControl>

        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 300 } }}
              label="Data de início"
              value={valueDataInicio}
              onChange={(value) => handleChangeDataInicio(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 300 } }}
              value={valueDataFim}
              label="Data final"
              onChange={(value) => handleChangeDataFim(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
              maxDate={dayjs().add(27, "day")}
            />
          </LocalizationProvider>
        </FormControl>

        {/* <FormControl fullWidth>
        <InputLabel>Erros</InputLabel>
        <Select
          value={valueErrorColumns}
          label="Erros"
          onChange={(e) => handleErrorColumns(e)}
          sx={{
            width: 200,
          }}
        >
            <MenuItem value={"false"}>
              {"Não"}
            </MenuItem>
            <MenuItem value={"true"}>
              {"Sim"}
            </MenuItem>
        </Select>
      </FormControl> */}

        {/* <FormControl sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: '0.1em', width: 220 } }}
              label={'Data*'}
              value={valueTargetDate}
              onChange={handleChangeTargetDate}
              slotProps={{ textField: { variant: 'outlined' } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl> */}

        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Consultar
          </Button>
        </FormControl>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <Box
        sx={{
          height: "150px",
          width: "100%",
        }}
      >
        <DataTable
          rows={dataTratada.filter((item) => {
            return (
              item.balance !== null &&
              item.balance !== undefined &&
              item.balance !== ""
            );
          })}
          sx={{
            minHeight: 450,
            width: "100%",
            bgcolor: "white",
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "secondary.light",
              color: "secondary.contrastText",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-overlay": {
              height: "auto",
              color: "white",
            },
            "& .MuiDataGrid-toolbarContainer": {
              display: "none",
            },
            "& .MuiDataGrid-overlayWrapperInner": {
              display: "none",
            },
          }}
          getRowId={(row) => generateRandomId()}
          columns={columnsSaldo}
          columnVisibilityModel={{
            balance: true,
            availableBalance: true,
            blockedAmount: true,
            account: true,
            searchDate: true,
            accountId: true,
            saldoFinal: true,
            type: false,
            accountId: false,
            amount: false,
            movementDescription: false,
            movementId: false,
            cashDirection: false,
            creditName: false,
            debitAccountType: false,
            date: false,
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <DataTable
        rows={
          dataTratada.map((item, index) => ({
            ...item,
            balance: item.balance,
            availableBalance: item.availableBalance,
            blockedAmount: item.blockedAmount,
            account: item.account,
            searchDate: item.searchDate,
            accountId: item.accountId,
            type: item.type,
            accountId: item.accountId,
            amount: item.amount,
            movementDescription: item.movementDescription,
            movementId: item.movementId,
            cashDirection:
              item.cashDirection === "CashIn"
                ? "+"
                : item.cashDirection === "CashOut"
                ? "-"
                : "Não Possui Webhook",
            creditName: item.creditName,
            debitAccountType: item.debitAccountType,
            date: item.date,
            saldoFinal: item.saldoFinal,
          })) ?? []
        }
        columnVisibilityModel={{
          balance: false,
          availableBalance: false,
          blockedAmount: false,
          account: false,
          searchDate: false,
          accountId: false,
          saldoFinal: false,
          type: false,
          movementId: false,
          debitAccountType: false,
          creditName: false,
        }}
        getRowId={(row) => generateRandomId()}
        columns={columns}
      />
    </Box>
  );
};
