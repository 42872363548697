import * as React from "react";
import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Textarea from "@mui/joy/Textarea";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import axios from "axios";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import CenteredCircularProgress from "pages/apps/onboarding/Proposals/Question/CenteredCircularProgress";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

const TextFieldForm = styled(TextField)({
  borderRadius: "9px",
  backgroundColor: "#FFFFFF",
});
const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

function getQuestionAnswer(answers, questionId) {
  return typeof answers.find((v) => v?.id === questionId)?.questionAnswer ===
    "object"
    ? answers.find((v) => v?.id === questionId)?.questionAnswer
    : answers.find((v) => v?.id === questionId)?.questionAnswer ?? null;
}

function ProposalQuestion(props) {
  const [questions, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [numberPartners, setNumberPartners] = useState([1]);
  const [objBiro, setObjBiro] = useState(false);

  const analysis = JSON.parse(localStorage.getItem("analysis"));

  const navigate = useNavigate();

  const getQuestions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/questions/" + analysis.id,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );

      setQuestion(response.data);

      await response.data.map((question, index) => {
        let arrAnswers = answers;
        if (question.questionIdentifier === "CNPJ") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.cnpj,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "RAZAO_SOCIAL") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.dadosCadastrais
            ).Result[0].BasicData.OfficialName,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "NOME_FANTASIA") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: analysis.dataCnpj.name,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "DT_CONSTITUICAO") {
          const parts = JSON.parse(
            analysis.bigData.bigData.bigData.dadosCadastrais
          ).Result[0].BasicData.FoundedDate.split("T");
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: parts[0],
            index: question.id,
          };
        }

        if (question.questionIdentifier === "RUA") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.addresses
            ).Result[0].ExtendedAddresses?.Addresses[0].AddressMain,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "BAIRRO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.addresses
            ).Result[0].ExtendedAddresses?.Addresses[0].Neighborhood,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "CIDADE") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.addresses
            ).Result[0].ExtendedAddresses?.Addresses[0].City,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "ESTADO") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.addresses
            ).Result[0].ExtendedAddresses?.Addresses[0].State,
            index: question.id,
          };
        }

        if (question.questionIdentifier === "CEP") {
          arrAnswers[question.id] = {
            id: question.id,
            questionAnswer: JSON.parse(
              analysis.bigData.bigData.bigData.addresses
            ).Result[0].ExtendedAddresses?.Addresses[0].ZipCode,
            index: question.id,
          };
        }

        setAnswers(arrAnswers);
      });
      let newPartners = JSON.parse(
        analysis?.neowayData?.neowayData?.neowayData
      ).beneficiarios;
      let newObj = [];
      newPartners?.map((partner, index) => {
        newObj[index] = {
          fullName: partner.nome,
          rg: "",
          cpf: partner.documento,
        };
        setPartners(newObj);
      });
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar perguntas");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const onClickPartners = (event) => {
    setPartners([...partners, {}]);
  };

  const onChangeCheckboxAnswers = (questionId, event) => {
    const selectedValue = event.target.value;
    const temp = answers.map((v) => v);
    // Update the selected values array for the question
    const updatedSelectedValues = [
      ...(temp[questionId]?.questionAnswer?.split(",").filter((n) => n) || []),
    ];
    if (updatedSelectedValues.includes(selectedValue)) {
      // Deselect the value if it's already selected
      updatedSelectedValues.splice(
        updatedSelectedValues.indexOf(selectedValue),
        1
      );
    } else {
      // Select the value if it's not selected
      updatedSelectedValues.push(selectedValue);
    }

    // When saving to the database, join the selected values into a single string
    const selectedValuesString = updatedSelectedValues.join(",");
    temp[questionId] = {
      ...temp[questionId],
      id: questionId,
      questionAnswer: selectedValuesString,
    };
    setAnswers(temp);

    // Save selectedValuesString to your database
    // ...
  };

  const onChangeAnswers = (id, answer) => {
    const temp = answers.map((v) => v);
    temp[id] = {
      ...temp[id],
      id,
      questionAnswer: answer,
    };
    setAnswers(temp);
  };

  const onClickNext = async () => {
    if (answers.length === 0) {
      return toast.error("Por favor preencha o formulario.");
    }

    setSaving(true);
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/form/" + analysis.id,
        answers,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        process.env.REACT_APP_API_URL + "/formpartner/" + analysis.id,
        partners,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Formulario preenchido com sucesso.");
      navigate("/homeregistration/");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar perguntas");
      setSaving(false);
    }
  };
  const getAnswers = (id) => {
    return !!answers && !!answers[id] && !!answers[id].questionAnswer
      ? answers[id].questionAnswer
      : "";
  };

  const onChangePartner = (index, parameter, value) => {
    const temp = partners.map((v) => v);
    temp[index] = {
      ...temp[index],
      [parameter]: value,
    };
    setPartners(temp);
  };

  const renderOptions = (
    options,
    id,
    onChange,
    value,
    showMessage,
    questionType,
    label = null
  ) => {
    return (
      <FormControl fullWidth>
        {label && (
          <FormLabel id={`remuneration-label-${id}`}>{label}</FormLabel>
        )}
        {questionType === "radioGroup" ? (
          <RadioGroup
            aria-label="question"
            id=""
            name={"question" + id}
            aria-labelledby={`remuneration-label-${id}`}
            row
            onChange={onChange}
            value={value}
            required={true}
          >
            {options?.map((option, index) => {
              return (
                <FormControlLabel
                  key={`option-${id}-${index}`}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              );
            })}
          </RadioGroup>
        ) : questionType === "checkbox" ? (
          <FormGroup row={true}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    value={option.value} // Note: This should be updated with the actual value for each checkbox
                    onChange={onChange} // You might need to pass the option.value to onChange
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        ) : null}
        {showMessage === true && (
          <Typography sx={{ color: "#2C90D8" }}>
            O upload desse documento será necessário para assinatura do
            contrato.
          </Typography>
        )}
      </FormControl>
    );
  };

  return (
    <>
      <main>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Divider />
          <Container>
            <h2>
              Gostaríamos de conhecer sua estrutura societária e operacional
            </h2>
            <h4>
              Algumas perguntas precisam ser complementadas com documentos. O
              anexo dos arquivos poderá ser feito posteriormente na área{" "}
              <strong>Uploads</strong>.
            </h4>
            <br />
            {loading && <CenteredCircularProgress />}
            {questions?.length > 0 && (
              <>
                <h3>1. Dados da Empresa</h3>
                {questions?.map((question, index) => {
                  const dependencyQuestionId = question?.dependencyQuestionId;
                  const answer = getQuestionAnswer(
                    answers,
                    dependencyQuestionId
                  );
                  const questionAnswer = getQuestionAnswer(
                    answers,
                    question.id
                  );
                  const shouldRender =
                    !question?.dependencyAnswer ||
                    (question?.dependencyAnswer !== null &&
                      answer !== "" &&
                      answer
                        ?.toLowerCase()
                        .includes(question?.dependencyAnswer?.toLowerCase()));
                  const showMessage =
                    question?.uploadMessageAnswer?.toLowerCase() ===
                      questionAnswer ||
                    question?.uploadMessageAnswerSecondary?.toLowerCase() ===
                      questionAnswer;
                  const options =
                    (question?.questionType === "checkbox" ||
                      question?.questionType === "radioGroup") &&
                    question.options
                      ? JSON.parse(question.options).options
                      : [];
                  return (
                    <div key={question.id}>
                      {shouldRender && <h4>{question?.question}</h4>}
                      {question?.questionType === "text" && shouldRender && (
                        <>
                          <TextFieldForm
                            key={question.id}
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            value={getAnswers(question.id)}
                            onChange={(event) =>
                              onChangeAnswers(
                                question.id,
                                event.target.value,
                                index
                              )
                            }
                            style={{
                              width: "80%",
                            }}
                          />
                          <Divider />
                        </>
                      )}
                      {question?.questionType === "radioGroup" &&
                        shouldRender && (
                          <>
                            {renderOptions(
                              options,
                              question.id,
                              (event) =>
                                onChangeAnswers(
                                  question.id,
                                  event.target.value,
                                  question.questionIdentifier
                                ),
                              questionAnswer,
                              showMessage,
                              "radioGroup"
                            )}
                            <Divider />
                          </>
                        )}
                      {question?.questionType === "checkbox" &&
                        shouldRender && (
                          <>
                            {renderOptions(
                              options,
                              question.id,
                              (event) =>
                                onChangeCheckboxAnswers(question.id, event),
                              questionAnswer,
                              showMessage,
                              "checkbox"
                            )}
                            <Divider />
                          </>
                        )}
                      {question?.questionType === "textArea" &&
                        shouldRender && (
                          <>
                            <Textarea
                              placeholder="Type in here…"
                              defaultValue=""
                              minRows={2}
                              maxRows={4}
                              onChange={(event) =>
                                onChangeAnswers(
                                  question.id,
                                  event.target.value,
                                  index
                                )
                              }
                              style={{
                                width: "80%",
                              }}
                            />
                            <Divider />
                          </>
                        )}

                      {question?.question === "CEP:" && (
                        <>
                          {partners?.map((value, index) => {
                            return (
                              <React.Fragment key={index}>
                                {index === 0 && <h3>2. Dados Societários </h3>}
                                {index > 0 && <Divider />}

                                {value?.cpf?.length === 14 && (
                                  <>
                                    <h4>2.1 Razão Social:</h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value.companyName}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "companyName",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />
                                    <h4>2.2 Nome Fantasia:</h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.tradingName}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "tradingName",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />

                                    <h4>2.3 Data de Constituição: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.date}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "date",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />

                                    <h4>2.4 Endereço: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.address}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "address",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />
                                    <Typography sx={{ color: "#2C90D8" }}>
                                      O upload desse documento será necessário
                                      para assinatura do contrato.
                                    </Typography>
                                    <br />
                                  </>
                                )}
                                {value?.cpf?.length === 11 && (
                                  <>
                                    <h4>2.1 Nome Completo:</h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.fullName}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "fullName",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />

                                    <h4>2.2 RG: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.rg}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "rg",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />

                                    <h4>2.3 CPF: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.cpf}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "cpf",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />
                                    <Typography sx={{ color: "#2C90D8" }}>
                                      O upload desse documento será necessário
                                      para assinatura do contrato.
                                    </Typography>
                                    <br />
                                    <h4>2.4 Endereço: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      value={value?.address}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "address",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />
                                    <h4>2.5 Informar % acionária: </h4>
                                    <TextFieldForm
                                      id="outlined-basic"
                                      label=""
                                      variant="outlined"
                                      size="small"
                                      type="number"
                                      value={value?.percent}
                                      onChange={(event) =>
                                        onChangePartner(
                                          index,
                                          "percent",
                                          event.target.value
                                        )
                                      }
                                      style={{
                                        width: "80%",
                                      }}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                          <br />

                          {/* <Button
                            sx={{
                              backgroundColor: "navy",
                              borderRadius: "10px",
                              gap: "3px",
                              fontWeight: "bold",
                            }}
                            onClick={onClickPartners}
                            variant="contained"
                          >
                            <AddIcon />
                            Sócio
                          </Button> */}

                          <Divider />
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </Container>
        </Box>
      </main>
      <Divider />
      <br />

      <Footer>
        <Button
          sx={{ backgroundColor: "navy", borderRadius: "10px" }}
          variant="contained"
          onClick={onClickNext}
          disabled={saving}
        >
          Continuar
          {loading === true && <CircularProgress />}
        </Button>
      </Footer>
    </>
  );
}

export default ProposalQuestion;
