import React, { Fragment } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import NovaLogo from "../../../assets/user/novaLogo.png";
import { useAuthMethod } from "@crema/utility/AuthHooks";

export const Signin = () => {
  const { signIn } = useAuthMethod();

  const handleSignIn = async function () {
    await signIn();
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          sx={{
            maxWidth: 1024,
            width: "100%",
            overflow: "hidden",
            boxShadow:
              "10px 10px 10px 10px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%)",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                backgroundColor: "#E5007E",
                "& svg": {
                  width: "100%",
                  height: "100%",
                  display: "inline-block",
                },
              }}
            >
              <img src={NovaLogo}/>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: "center",
                placeContent: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "20px"
              }}
            >
              <Button
                variant="contained"
                type="submit"
                onClick={handleSignIn}
                sx={{
                  height: 44,
                  bgcolor: "secondary.main",
                  "&:hover": { backgroundColor: "secondary.light" },
                }}
              >
                LOGIN
              </Button>
              <Box>
                {process.env.REACT_APP_ENVIROMENT} - {new Date().toLocaleString()}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
};
