import React, { useEffect, useState } from "react";
import { plain } from "shared/utils/plain";
import axios from "axios";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { DataTable } from "elements/dataTable";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { formatDocument } from "shared/utils/formatDocument";

export const Emissor = () => {
  const [loading, setLoading] = useState(false);

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState("");
  const handleChangePaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
  };

  const [data, setData] = React.useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paymentNetwork",
      headerName: "Bandeira",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "taxId",
      headerName: "taxId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createTimestamp",
      headerName: "Data",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  // token da autenticação
  const { getToken } = useAuthMethod();

  // Função de comparação pelo nome
  // usada para ordenar a lista
  function compare(a, b) {
    if (plain(a.nome) < plain(b.nome)) {
      return -1;
    }
    if (plain(a.nome) > plain(b.nome)) {
      return 1;
    }
    return 0;
  }

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchCardIssuers = async () => {
    try {
      setLoading(true);

      /* const result = await fetchData(
              'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
              {
                paymentNetwork: currentPaymentNetwork,
              }
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/card-issuer/search",
        {
          paymentNetwork: currentPaymentNetwork,
        }
      );

      console.log(result);

      const d = result.map((item) => {
        return {
          id: item.cardIssuerId,
          nome: item.name,
          cnpj: formatDocument(item.taxId),
          status: item.status,
          paymentNetwork: item.paymentNetwork,
          createTimestamp: item.createTimestamp,
          lastUpdateTimestamp: item.lastUpdateTimestamp,
          taxId: item.taxId,
        };
      });
      setData(d.sort(compare));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks' */
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      console.log(result);

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // lista emissores daquela bandeira
  useEffect(() => {
    if (currentPaymentNetwork.length > 0) {
      console.log(currentPaymentNetwork);
      fetchCardIssuers();
    }
  }, [currentPaymentNetwork]);

  // pega lista de bandeiras
  useEffect(() => {
    fetchPaymentNetworks();
  }, []);

  const fetchData = async (aUrl, aParams = {}) => {
    try {
      setLoading(true);
      // token do ad
      const token = await getToken();

      const params = {
        method: "GET",
        url: aUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: aParams,
      };
      console.log(params);
      const res = await axios(params);
      return res.data.body;
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        borderBottom: `dashed 1px `,
        width: "100%",
        gap: 2,
      }}
    >
      <Hover loading={loading} />

      <FormControl fullWidth>
        <InputLabel>Bandeira</InputLabel>
        <Select
          value={currentPaymentNetwork}
          label="Bandeira"
          onChange={(e) => handleChangePaymentNetwork(e)}
          sx={{
            width: 200,
          }}
        >
          {paymentNetworks.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DataTable
        rows={data}
        columns={columns}
        columnVisibilityModel={{
          lastUpdateTimestamp: false,
          createTimestamp: false,
          taxId: false,
        }}
      />
    </Box>
  );
};
