import React, { createRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { ObjectView } from "./ObjectView";

export const Detail = ({ report, currentTopic, currentEntity }) => {
  const contentRef = createRef();
  const [contents, setContents] = useState("");

  function getEntityDatasets(entityIndex) {
    console.log("TESTE1");
    const entity = report.responses[entityIndex];
    const datasets = {};
    entity.Result.forEach((result) => {
      const resultDatasets = Object.keys(result);
      resultDatasets.forEach((dataset) => {
        if (dataset !== "MatchKeys") {
          datasets[dataset] = result[dataset];
        }
      });
    });
    return datasets;
  }

  function getDataset(topic) {
    if (!!report.responses) {
      const normalizedTopic = topic.substring(2);
      return getEntityDatasets(currentEntity)[normalizedTopic];
    }
    return report[topic] ?? report.bigData[topic];
  }

  function getAvailableTopics() {
    if (report.responses) {
      return Object.keys(getEntityDatasets(currentEntity));
    }
    console.log(report);
    let bigdata = report.bigData;
    if (!bigdata) bigdata = report;
    console.log("no 'responses' array", bigdata);
    console.log("keys", Object.keys(bigdata));
    return Object.keys(bigdata);
  }

  const updateContents = function () {
    const availableTopics = getAvailableTopics();
    console.log("availableTopics", availableTopics);
    let t = {};
    for (const topic of availableTopics) {
      if (currentTopic.endsWith(topic)) {
        if (getDataset(currentTopic) === null) {
          t = {};
        } else {
          switch (typeof getDataset(currentTopic)) {
            case "null":
            case "undefined":
              {
                t = {};
              }
              break;

            case "object":
              {
                t = Object.assign(getDataset(currentTopic), {});
              }
              break;

            default: {
              t = getDataset(currentTopic);
            }
          }
        }
      }
    }
    setContents(t);
  };

  // chamado quando o report é alterado
  useEffect(() => {
    updateContents();
  }, [report, currentTopic]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 6,
      }}
      ref={contentRef}
    >
      <>
        <Box isDetailView>
          <ObjectView detail={contents} />
        </Box>
      </>
    </Box>
  );
};
Detail.propTypes = {
  report: PropTypes.object,
  currentTopic: PropTypes.string.isRequired,
};
Detail.defaultProps = {
  report: {},
  currentTopic: "",
};
