import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "lodash";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import "../style.css";
import { useNavigate } from "react-router-dom";

const months = [
  {
    label: "MÊS 1",
    value: "billingMonth1",
  },
  {
    label: "MÊS 2",
    value: "billingMonth2",
  },
  {
    label: "MÊS 3",
    value: "billingMonth3",
  },
  {
    label: "MÊS 4",
    value: "billingMonth4",
  },
  {
    label: "MÊS 5",
    value: "billingMonth5",
  },
  {
    label: "MÊS 6",
    value: "billingMonth6",
  },
  {
    label: "MÊS 7",
    value: "billingMonth7",
  },
  {
    label: "MÊS 8",
    value: "billingMonth8",
  },
  {
    label: "MÊS 9",
    value: "billingMonth9",
  },
  {
    label: "MÊS 10",
    value: "billingMonth10",
  },
  {
    label: "MÊS 11",
    value: "billingMonth11",
  },
  {
    label: "MÊS 12",
    value: "billingMonth12",
  },
];

const produto = [
  {
    label: "Débito",
    value: "performanceByDebit",
    indicador: "",
  },
  {
    label: "Crédito",
    value: "performanceByCredit",
    indicador: "",
  },
  {
    label: "Parcelado",
    value: "performanceByInstallment",
    indicador: "",
  },
];
const tecnologia = [
  {
    label: "Mundo Físico (Cartão Presente)",
    value: "performanceWithCard",
    indicador: "[6].__EMPTY_5",
  },
  {
    label: "Digital (Cartão Não Presente)",
    value: "performanceWithoutCard",
    indicador: "",
  },
];

const antecipacao = [
  {
    label: "% Antecipação",
    value: "prepayment",
  },
  {
    label: "Prazo Médio Liquidação (dias)",
    value: "averageSettlementTerm",
  },
];

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const ForecastPreview = () => {
  const [forecast, setForecast] = useState([]);
  const [forecastGeneral, setForecastGeneral] = useState({});
  const [loading, setLoading] = useState(false);
  const data = {};
  const mccs = [];

  const { getToken } = useAuthMethod();

  let { id } = useParams();

  const handleForecast = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/forecast/${id}`,
        "get",
        token
      );
      const resultGeneral = await request(
        process.env.REACT_APP_API_URL + `/forecastgeneraloperation/${id}`,
        "get",
        token
      );
      setForecast(result);
      setForecastGeneral(resultGeneral);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    handleForecast();
  }, [handleForecast]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);  };

  return (
    <>
      <div className="details">
      <h2 className='title-details' >Forecast</h2>
        <Box>
          <Box>
            {loading && (
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            )}
            {!loading && (
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            FATURAMENTO TOTAL POR MÊS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {months.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.label}
                            </TableCell>
                            <TableCell>
                              {currencyLocale.format(
                                get(forecastGeneral, row.value, "")
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <br />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            ANO
                          </TableCell>
                          <TableCell>
                            {currencyLocale.format(
                              forecastGeneral.billingMonth1 +
                                forecastGeneral.billingMonth2 +
                                forecastGeneral.billingMonth3 +
                                forecastGeneral.billingMonth4 +
                                forecastGeneral.billingMonth5 +
                                forecastGeneral.billingMonth6 +
                                forecastGeneral.billingMonth7 +
                                forecastGeneral.billingMonth8 +
                                forecastGeneral.billingMonth9 +
                                forecastGeneral.billingMonth10 +
                                forecastGeneral.billingMonth11 +
                                forecastGeneral.billingMonth12
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={7}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>% atuação por produto</TableCell>
                          <TableCell>Proporção</TableCell>
                          <TableCell>Indicador % produto</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {produto.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.label}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {get(forecastGeneral, row.value, "")}%
                            </TableCell>
                            <TableCell>
                              {index === 0 &&
                                `${
                                  forecastGeneral.performanceByCredit +
                                  forecastGeneral.performanceByDebit +
                                  forecastGeneral.performanceByInstallment
                                }%`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <br />
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>% atuação por tecnologia</TableCell>
                          <TableCell>Proporção</TableCell>
                          <TableCell>Indicador % tecnologia</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tecnologia.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.label}
                            </TableCell>
                            <TableCell>
                              {get(forecastGeneral, row.value, "")}%
                            </TableCell>
                            <TableCell>
                              {index === 0 &&
                                `${
                                  forecastGeneral.performanceWithCard +
                                  forecastGeneral.performanceWithoutCard
                                }%`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <br />
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        {antecipacao.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.label}
                            </TableCell>
                            <TableCell>
                              {get(forecastGeneral, row.value, "")}
                              {index === 0 && "%"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <h3>Carteira de MCCs</h3>
                </Grid>
                <Grid item xs={3}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Indicador % Carteira
                          </TableCell>
                          <TableCell>
                            {forecast.reduce(
                              (acc, curr) =>
                                acc + curr.mccBillingProjectionByProduct,
                              0
                            )}
                            %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>MCC</TableCell>
                          <TableCell>% que representa na carteira</TableCell>
                          <TableCell>Produto</TableCell>
                          <TableCell>Ticket Médio por Produto e MCC</TableCell>
                          <TableCell>TPV total do MCC</TableCell>
                          <TableCell>
                            Projeção do faturamento anual do MCC por produto
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {forecast.map((mcc) => {
                          return (
                            <TableRow
                              key={mcc.mcc}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {mcc?.mcc}
                              </TableCell>
                              <TableCell>
                                {mcc?.mccBillingProjectionByProduct}%
                              </TableCell>
                              <TableCell>
                                DEBITO
                                <br />
                                CREDITO
                                <br />
                                PARCELADO
                                <br />
                              </TableCell>
                              <TableCell>
                                {currencyLocale.format(mcc.debit)}
                                <br />
                                {currencyLocale.format(mcc.credit)}
                                <br />
                                {currencyLocale.format(mcc.installment)}
                              </TableCell>
                              <TableCell>
                                {currencyLocale.format(mcc.mccTotalTpv)}
                              </TableCell>
                              <TableCell>
                                {currencyLocale.format(mcc.averageTicketDebit)}
                                <br />
                                {currencyLocale.format(mcc.averageTicketCredit)}
                                <br />
                                {currencyLocale.format(
                                  mcc.averageTicketInstallments
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#000536",
              borderRadius: "10px",
            }}
            variant="contained"
            size="medium"
            onClick={handleBack}
          >
            Voltar
          </Button>
        </div>
      </div>
    </>
  );
};

ForecastPreview.defaultProps = {
  data: [],
};
ForecastPreview.propTypes = {
  data: PropTypes.array,
};

export default ForecastPreview;
