import React from "react";
import { CircularProgress, Container } from "@mui/material";

const CenteredCircularProgress = () => {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Adjust this to the desired height
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default CenteredCircularProgress;
