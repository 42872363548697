import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Fonts } from "shared/constants/AppEnums";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextareaAutosize, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FileUploader } from "react-drag-drop-files";

// const CardStyled = styled(Card)({
//   width: "70%",
//   // height: "50%",
//   padding: 0,
//   borderStyle: "dotted",
//   borderColor: "#2C90D8",
//   marginTop: 3,
// });

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const StandardPricing = () => {
  const [loading, setLoading] = useState(false);
  const [pricingPartner, setPricingPartner] = useState();
  const [retentionFee, setRetentionFee] = useState();
  const [customFee, setCustomFee] = useState();
  const [description, setDescription] = useState();
  const [special, setSpecial] = useState("yes");
  const [files, setFiles] = useState({});

  let { id } = useParams();

  const { getToken } = useAuthMethod();

  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    console.log(file);
    temp[name] = file;
    setFiles(temp);
  };

  const listProposal = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${id}`,
        "get",
        token
      );

      setSpecial(result[0].analysis.specialConditionPricing ?? "yes");
      setDescription(result[0].analysis.specialDescriptionPricing);
      setRetentionFee(result.retentionFee);
      setPricingPartner(result);
    } catch (error) {
      toast.error("Erro ao listar dados");
    }
  }, []);

  React.useEffect(() => {
    listProposal();
  }, []);

  const onChangeRetentionFee = (event) => {
    setRetentionFee(event.target.value);
  };

  const onChangeCustomFee = (event) => {
    setCustomFee(event.target.value);
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeSpecial = (event) => {
    setSpecial(event.target.checked === true ? "no" : "yes");
  };

  const onClickSendProposal = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      if ((!files.Pricing || description.trim() === "") && special != "no") {
        return toast.error(
          "Os campos referentes a condicoes especiais sao necessários para irmos para próxima fase."
        );
      }
      for (const key of Object.keys(files)) {
        const uploadFiles = files[key];
        const data = new FormData();
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }

        const resultUpload = await request(
          process.env.REACT_APP_API_URL + "/documentupload/" + id + "/" + key,
          "post",
          token,
          data
        );
      }

      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          specialDescriptionPricing: description,
          specialConditionPricing: special,
        }
      );

      const result = await request(
        process.env.REACT_APP_API_URL + `/sendproposal/${id}`,
        "post",
        token
      );

      toast.success("Enviado com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar proposta");
    } finally {
      setLoading(false);
    }
  };

  const onClickDiscountFee = async () => {
    try {
      const token = await getToken();
      const result = await request(
        process.env.REACT_APP_API_URL +
          `/pricingdiscountfee/${pricingPartner?.[0]?.id}`,
        "put",
        token,
        {
          retentionFee,
          fee: customFee,
        }
      );

      listProposal();
      toast.success("Atualizado com sucesso");
    } catch (error) {
      if (error.response.status == 400) {
        toast.error("retention fee nao permitido");
      } else {
        toast.error("Erro ao atualizar");
      }
    }
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 12, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Precificação Automática de Parcerias
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography>TPV médio mensal</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="TPV médio mensal"
                variant="outlined"
                value={currencyLocale.format(pricingPartner?.[0]?.tpvAverage)}
                //   onChange={onChangeTpv}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>Ticket Médio</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="Ticket Médio"
                variant="outlined"
                value={currencyLocale.format(pricingPartner?.[0]?.tmAverage)}
                //   onChange={onChangeTicketMedio}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>% de antecipação</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder=" % de antecipação"
                variant="outlined"
                value={pricingPartner?.[0]?.prepayment}
                //   onChange={onChangeAntecipacao}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>Prazo Médio</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="Prazo Médio"
                variant="outlined"
                value={pricingPartner?.[0]?.weightedDays}
                //   onChange={onChangeAntecipacao}
                disabled={true}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ mt: 8, mb: 5 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#000536" }}>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{ color: "#ffffff" }}
                    >
                      TPV - Volume transacionado por mês
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      sx={{ color: "#ffffff" }}
                    >
                      Fee (ITC + Fee)
                    </TableCell>
                  </TableRow>
                  {pricingPartner?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>entre</TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(row?.tpvMin)}
                      </TableCell>
                      <TableCell align="center">e</TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(row?.tpvMax)}
                      </TableCell>

                      <TableCell align="center">
                        {row?.discountFee != 0 ? row?.discountFee : row?.fee}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableHead>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#000536" }}>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{ color: "#ffffff" }}
                    >
                      Faixa de valor a ser antecipada
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      sx={{ color: "#ffffff" }}
                    >
                      Taxa Antecip.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>entre</TableCell>
                    <TableCell align="center">
                      {currencyLocale.format(
                        pricingPartner?.[0]?.prepaymentMinValue
                      )}
                    </TableCell>
                    <TableCell align="center">e</TableCell>
                    <TableCell align="center">
                      {currencyLocale.format(
                        pricingPartner?.[0]?.prepaymentMaxValue
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {pricingPartner?.[0]?.prepaymentFee}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              Digite abaixo a condição especial já aprovada e anexe a evidência
              de aprovação dela*
            </Typography>
            <TextareaAutosize
              onChange={onChangeDescription}
              value={description}
              aria-label="minimum height"
              minRows={4}
              placeholder=" Preenchimento obrigatório."
              style={{ width: "100%" }}
            />
            <Grid container>
              <Grid item xs={6}>
                <div style={{ border: "1px dotted blue", padding: "10px" }}>
                  <FileUploader
                    handleChange={(file) => handleUploadChange("Pricing", file)}
                    name="file"
                    multiple={true}
                    children={
                      <div
                        style={{
                          textAlign: "left",
                          color: "#2C90D8",
                          padding: 0,
                        }}
                      >
                        {files?.Pricing?.length > 0 ? (
                          Object.keys(files?.Pricing).map((v) => {
                            const uploadedFile = files?.Pricing[v];
                            return <p key={v}>{uploadedFile.name}</p>;
                          })
                        ) : (
                          <p>
                            <CloudUploadOutlinedIcon /> Arraste e solte o
                            arquivo preenchido aqui
                          </p>
                        )}
                      </div>
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={special === "no"}
                        onChange={handleChangeSpecial}
                      />
                    }
                    label="Não possui condição especial"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justifySelf="flex-end">
            {(pricingPartner?.[0]?.analysis?.pricingType === "Padrão" ||
              pricingPartner?.[0]?.analysis?.pricingType === null) && (
              <>
                <Typography>Desconto Comercial</Typography>
                <FormControl size="small">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    placeholder="Desconto de Retenção"
                    variant="outlined"
                    value={retentionFee}
                    onChange={onChangeRetentionFee}
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <Button
                    sx={{
                      backgroundColor: "navy",
                      borderRadius: "10px",
                      ml: 2,
                    }}
                    variant="contained"
                    disabled={loading}
                    onClick={onClickDiscountFee}
                  >
                    Salvar
                  </Button>
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
        <Footer>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickSendProposal}
              >
                Enviar para Proposta Comercial
              </Button>
            </Grid>
          </Grid>
        </Footer>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default StandardPricing;
