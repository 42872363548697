import React, { useState } from "react";
import { DataTable } from "elements/dataTable";

import dayjs from "dayjs";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { formatDocument } from "shared/utils/formatDocument";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

const codProdPayments = require("shared/constants/codProdBtg.json");

export const Liquidante = () => {
  // token da autenticação
  const { getToken } = useAuthMethod();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // Tipos de mensagem
  const [valueTipoMensagem, setTipoMensagem] = useState("SLC0001"); // Tipo Mensagem
  const tiposMensagem = [
    { label: "SLC0001", value: "SLC0001" },
    { label: "SLC0002", value: "SLC0002" },
    { label: "SLC0005", value: "SLC0005" },
  ];
  const handleChangeTipoMensagem = (event) => {
    setTipoMensagem(event.target.value);
    setData([]);
  };

  /* // Arranjo de pagamento (bandeira). usado apenas no SLC0001
    const [arranjo, setArranjo] = useState('VCC');
    const handleChangeArranjo = (e) => {
      setArranjo(e.target.value);
      console.log(e.target.value)
    }; */

  // Data Início
  const [valueDataInicio, setDataInicio] = React.useState(dayjs()); // Data Inicio
  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue);
  };

  // Data Fim
  const [valueDataFim, setDataFim] = React.useState(dayjs()); // Data Fim
  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue);
  };

  // Lista de transações
  const [transacoes, setTransacoes] = React.useState([]); // Data Fim

  ///DATA GRID
  const columnsSLC0001 = [
    {
      field: "clientRequestId",
      headerName: "clientRequestId",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "vlrLanc",
      headerName: "vlrLanc",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
      renderCell: (params) => {
        const value = params.formattedValue || "";
        return (
          <Typography style={{ textDecoration: "underline" }}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: "createTimestamp",
      headerName: "Data",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cnpjLiqdantCredtd",
      headerName: "cnpjLiqdantCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueFormatter: (value) => {
        return formatDocument(value);
      },
    },
    {
      field: "amount",
      headerName: "amount",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nuop",
      headerName: "NUOp",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdLinhaBilatBody",
      headerName: "IdentdLinhaBilat",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "movementId",
      headerName: "MovementId",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "bankBranchAccount",
      headerName: "BankBranchAccount",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cashDirection",
      headerName: "CashDirection",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdPessoaCliCredtd",
      headerName: "identdPessoaCliCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdPessoaCliDebtd",
      headerName: "identdPessoaCliDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cnpjLiqdantDebtd",
      headerName: "cnpjLiqdantDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codProdt",
      headerName: "CodProdt",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "dtMovtoBody",
      headerName: "dtMovtoBody",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "entity",
      headerName: "entity",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdLinhaBilatMessage",
      headerName: "identdLinhaBilatMessage",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ispbifCredtd",
      headerName: "ispbifCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ispbifDebtd",
      headerName: "ispbifDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nomCliCredtd",
      headerName: "nomCliCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nomCliDebtd",
      headerName: "nomCliDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tpDeb_Cred",
      headerName: "tpDeb_Cred",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tptranscslc",
      headerName: "tptranscslc",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorCode",
      headerName: "errorCode",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorDescription",
      headerName: "errorDescription",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codigoBanco",
      headerName: "codigoBanco",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "contaCorrente",
      headerName: "contaCorrente",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "agencia",
      headerName: "agencia",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const columnsSLC0002 = [
    {
      field: "createTimestamp",
      headerName: "Data",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "entity",
      headerName: "entity",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "pactualId",
      headerName: "pactualId",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "idMovtoBody",
      headerName: "IdMovto",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdLinhaBilatMessage",
      headerName: "identdLinhaBilatMessage",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdLinhaBilatBody",
      headerName: "IdentdLinhaBilat",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "bankBranchAccount",
      headerName: "BankBranchAccount",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cashDirection",
      headerName: "CashDirection",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codProdt",
      headerName: "codProdt",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "dtMovtoBody",
      headerName: "dtMovtoBody",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nuop",
      headerName: "NUOp",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "origin",
      headerName: "Origin",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorCode",
      headerName: "errorCode",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorDescription",
      headerName: "errorDescription",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codigoBanco",
      headerName: "codigoBanco",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "contaCorrente",
      headerName: "contaCorrente",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "agencia",
      headerName: "agencia",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "amount",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  const columnsSLC0005 = [
    {
      field: "clientRequestId",
      headerName: "clientRequestId",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "movementId",
      headerName: "MovementId",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "vlrLanc",
      headerName: "Valor",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
      renderCell: (params) => {
        const value = params.formattedValue || "";
        return (
          <Typography style={{ textDecoration: "underline" }}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: "createTimestamp",
      headerName: "Data",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cnpjLiqdantCredtd",
      headerName: "cnpjLiqdantCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "amount",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nuop",
      headerName: "NUOp",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdLinhaBilatBody",
      headerName: "IdentdLinhaBilat",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "origin",
      headerName: "Origin",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "bankBranchAccount",
      headerName: "BankBranchAccount",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cashDirection",
      headerName: "CashDirection",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdPessoaCliCredtd",
      headerName: "identdPessoaCliCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "identdPessoaCliDebtd",
      headerName: "identdPessoaCliDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "cnpjLiqdantDebtd",
      headerName: "cnpjLiqdantDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codMsg",
      headerName: "codMsg",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "descAtv",
      headerName: "descAtv",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "indrDevLiquid",
      headerName: "indrDevLiquid",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ispbif",
      headerName: "indrDevLiquid",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nomArqSLC",
      headerName: "nomArqSLC",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "numCtrlEmissorArq",
      headerName: "numCtrlEmissorArq",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "numCtrlLTROr",
      headerName: "numCtrlLTROr",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "numCtrlSLC",
      headerName: "numCtrlSLC",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "subTpAtv",
      headerName: "subTpAtv",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "dtHrSLC",
      headerName: "dtHrSLC",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codProdt",
      headerName: "CodProdt",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "codigoBanco",
      headerName: "codigoBanco",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "contaCorrente",
      headerName: "contaCorrente",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "agencia",
      headerName: "agencia",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "dtMovtoBody",
      headerName: "dtMovtoBody",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "entity",
      headerName: "entity",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ispbifCredtd",
      headerName: "ispbifCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ispbifDebtd",
      headerName: "ispbifDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nomCliCredtd",
      headerName: "nomCliCredtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "nomCliDebtd",
      headerName: "nomCliDebtd",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tpDeb_Cred",
      headerName: "tpDeb_Cred",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorCode",
      headerName: "errorCode",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "errorDescription",
      headerName: "errorDescription",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
  const getPaymentNetworkName = (id) => {
    for (const i of codProdPayments) {
      if (id === i.value) {
        return i.label;
      }
    }
    return "";
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      // Pega o token do login para passar na chamada
      const token = await getToken();
      const resultSettlementService = await sendRequest(token);
      console.log("RESULT SETTLEMENT SERVICE:", resultSettlementService);

      if (valueTipoMensagem === "SLC0002") {
        setData(resultSettlementService);
      } else {
        // Criar um objeto para rastrear o objeto mais recente para cada movementId
        const latestObjects = {};

        // Iterar sobre a array e atualizar o objeto
        for (const obj of resultSettlementService) {
          const clientRequestId = obj.clientRequestId;
          if (
            !latestObjects[clientRequestId] ||
            new Date(obj.lastUpdateTimestamp) >
              new Date(latestObjects[clientRequestId].lastUpdateTimestamp)
          ) {
            latestObjects[clientRequestId] = obj;
          }
        }

        // Criar a nova array com os objetos mais recentes
        const newArray = Object.values(latestObjects);
        console.log(newArray);

        // Iterar sobre a nova matriz e aplicar o processo de split
        const newArrayWithSplit = newArray.map((obj) => {
          const bankBranchAccountParts = obj.bankBranchAccount.split("_");

          // Criar novas propriedades
          obj.codigoBanco = bankBranchAccountParts[0];
          obj.agencia = bankBranchAccountParts[1];
          obj.contaCorrente = bankBranchAccountParts[2];

          return obj;
        });

        console.log(newArrayWithSplit);

        setData(newArrayWithSplit);
      }

      /* resultFetchContracts.forEach(dataContratos => {
              // Verificar se há um registro correspondente em resultFetchLimiteCessionario
              const matchingCessionarioData = resultFetchLimiteCessionario.filter(item =>
                (item.cessionario === dataContratos.cessionario && item.emissor === dataContratos.emissor) ||
                (item.cessionario === dataContratos.cessionario && item.emissor === "Todos")
              );

              if (matchingCessionarioData.length > 0) {
                // Encontrou correspondência, encontre o objeto com lastUpdateTimestamp mais recente
                const latestCessionarioData = matchingCessionarioData.reduce((prev, current) =>
                  (prev.lastUpdateTimestamp > current.lastUpdateTimestamp) ? prev : current
                );

                console.log(latestCessionarioData)

                const novoObjetoData = {
                  cessionario: dataContratos.cessionario,
                  ica: dataContratos.emissor,
                  emissor: dataContratos.emissor,
                  limite: latestCessionarioData.limite,
                  observacao: latestCessionarioData.observacao,
                  disponivel: latestCessionarioData.limite - dataContratos.reservado,
                  reservado: dataContratos.reservado,
                };

                arrayResultados.push(novoObjetoData);
              } else {
                // Se não houver correspondência, adicionar diretamente
                const novoObjetoData = {
                  cessionario: dataContratos.cessionario,
                  ica: dataContratos.emissor,
                  emissor: dataContratos.emissor,
                  reservado: dataContratos.reservado,
                };

                arrayResultados.push(novoObjetoData);
              }
            }); */
      // setTransacoes(result.body.items);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // TODO: mudar e pegar do nosso banco de dados, alimentado pelo webhook
  const sendRequest = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];

      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity: "listSettlementService" + process.env.REACT_APP_ENVIROMENT,
            slc:
              valueTipoMensagem === "SLC0002"
                ? "'SLC0002R1' or origin = 'SLC0002R2'"
                : `"${valueTipoMensagem}"`,
            dataInicial: valueDataInicio.format("'YYYY-MM-DD'"),
            dataFinal: valueDataFim.format("'YYYY-MM-DDT23:59:59[Z]'"),
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      return newArray;
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //   const handleUpdateSettlementService = async (e) => {
  //     try {
  //         const token = await getToken();
  //         let boolean = false;
  //         for (let i = 0; i < 2; i++) {
  //             setLoading(true);
  //             const params = {
  //                 method: 'PUT',
  //                 /* url: 'https://backend.entrepay.com.br/btg-homolog/cm/webhook/notification/SettlementService/replay', */
  //                 url: process.env.REACT_APP_CLIENT_BTG + '/cm/webhook/notification/SettlementService/replay',
  //                 params: {
  //                     dateFrom: dayjs(valueDataInicio).format('YYYY-MM-DD'),
  //                     dateTo: dayjs(valueDataFim).format('YYYY-MM-DD'),
  //                     onlyNotDelivered: true,
  //                 },
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     Authorization: `Bearer ${token}`,
  //                 },
  //             };
  //             const result = await axios(params); // Use await para aguardar a resposta

  //             boolean = true;
  //             if (i === 1 && boolean === true) { // Correção aqui
  //                 toast.success("Pedido enviado!");
  //             }
  //         }
  //     } catch (error) {
  //         toast.error(error.message);
  //         console.error(error);
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  const handleUpdateSettlementService = async (e) => {
    try {
      const token = await getToken();
      for (let i = 0; i < 2; i++) {
        setLoading(true);
        const params = {
          method: "PUT",
          /* url: 'https://backend.entrepay.com.br/btg-homolog/cm/webhook/notification/SettlementService/replay', */
          url:
            process.env.REACT_APP_CLIENT_BTG +
            "/cm/webhook/notification/SettlementService/replay",
          params: {
            dateFrom: dayjs().format("YYYY-MM-DD"),
            dateTo: dayjs().add(1, "day").format("YYYY-MM-DD"),
            onlyNotDelivered: true,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const result = await axios(params); // Use await para aguardar a resposta
        if (result.status === 200) {
          toast.success("Pedido enviado!");
        }
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      id="CONTAINER"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        padding: 2,
        borderBottom: `dashed 1px `,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        id="CONTROLE"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "left",
          textAlign: "left",
          padding: 2,
          borderBottom: `dashed 1px `,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
            padding: 2,
            width: "100%",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Tipo de Mensagem</InputLabel>
            <Select
              value={valueTipoMensagem}
              label="Tipo de Mensagem"
              onChange={(e) => handleChangeTipoMensagem(e)}
              sx={{
                width: 200,
              }}
            >
              {tiposMensagem.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                label="Data de início"
                value={valueDataInicio}
                onChange={(value) => handleChangeDataInicio(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                value={valueDataFim}
                label="Data final"
                onChange={(value) => handleChangeDataFim(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>

          {/* {valueTipoMensagem === 'SLC0001' && (
            <FormControl fullWidth>
              <InputLabel>Arranjo de Pagamento</InputLabel>
              <Select
                value={arranjo}
                label="Arranjo de pagamento"
                onChange={(e) => handleChangeArranjo(e)}
                sx={{
                  width: 240,
                }}
              >
                {codProd.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )} */}
        </Box>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            m: 1,
            minWidth: 50,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "secondary.contrastText",
            backgroundColor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
        >
          Pesquisar
        </Button>

        <Button
          sx={{
            m: 1,
            minWidth: 50,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "secondary.contrastText",
            backgroundColor: "secondary.main",
            "&:hover": { backgroundColor: "secondary.light" },
          }}
          variant="contained"
          onClick={handleUpdateSettlementService}
        >
          Reestabelecer Webhooks de hoje
        </Button>
      </Box>

      {/* tabela com as transações */}
      <Card
        sx={{
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          padding: 2,
          borderRadius: "16px",
          width: "100%",
          height: "100%",
        }}
      >
        <DataTable
          rows={
            data.map((item, index) => ({
              ...item,
              clientRequestId: item.clientRequestId,
              amount: item.amount,
              bankBranchAccount: item.bankBranchAccount,
              cashDirection: item.cashDirection,
              cnpjLiqdantCredtd: item.cnpjLiqdantCredtd,
              cnpjLiqdantDebtd: item.cnpjLiqdantDebtd,
              codProdt: item.codProdt,
              createTimestamp: dayjs(item.createTimestamp).format("DD-MM-YYYY"),
              description: item.description,
              dtMovtoBody: item.dtMovtoBody,
              entity: item.entity,
              idMovtoBody: item.idMovtoBody,
              identdLinhaBilatBody: item.identdLinhaBilatBody,
              BankBranchAccount: item.bankBranchAccount,
              CashDirection: item.cashDirection,
              CodProdt: item.CodProdt,
              DtMovtoBody: item.dtMovtoBody,
              SitLancSLC: item.sitLancSLC,
              NUOp: item.nuop,
              Origin: item.origin,
              identdLinhaBilatMessage: item.identdLinhaBilatMessage,
              ispbifCredtd: item.ispbifCredtd,
              tpDeb_Cred: item.tpDeb_Cred,
              nomCliDebtd: item.nomCliDebtd,
              tptranscslc: item.tptranscslc,
              nomCliCredtd: item.nomCliCredtd,
              ispbifDebtd: item.ispbifDebtd,
              entity: item.entity,
              cnpjLiqdantDebtd: item.cnpjLiqdantDebtd,
              identdPessoaCliDebtd: item.identdPessoaCliDebtd,
              identdPessoaCliCredtd: item.identdPessoaCliCredtd,
              codMsg: item.codMsg,
              descAtv: item.descAtv,
              indrDevLiquid: item.indrDevLiquid,
              ispbif: item.ispbif,
              lastUpdateTimestamp: item.lastUpdateTimestamp,
              nomArqSLC: item.nomArqSLC,
              numCtrlEmissorArq: item.numCtrlEmissorArq,
              numCtrlLTROr: item.numCtrlLTROr,
              numCtrlSLC: item.numCtrlSLC,
              subTpAtv: item.subTpAtv,
              dtHrSLC: item.dtHrSLC,
              errorCode: item.errorCode,
              errorDescription: item.errorDescription,
              contaCorrente: item.contaCorrente,
              agencia: item.agencia,
              codigoBanco: item.codigoBanco,
            })) ?? []
          }
          columnVisibilityModel={{
            clientRequestId: true,
            NUOp: false,
            nuop: false,
            identdLinhaBilat: false,
            bankBranchAccount: false,
            cashDirection: true,
            cnpjLiqdantCredtd: true,
            cnpjLiqdantDebtd: false,
            codProdt: true,
            createTimestamp: true,
            description: true,
            dtMovtoBody: false,
            entity: false,
            idMovtoBody: false,
            identdLinhaBilatBody: false,
            NUOp: false,
            identdPessoaCliDebtd: false,
            identdPessoaCliCredtd: false,
            movementId: true,
            cnpjLiqdantDebtd: false,
            entity: false,
            ispbifDebtd: false,
            nomCliCredtd: false,
            tptranscslc: false,
            nomCliDebtd: false,
            tpDeb_Cred: true,
            description: valueTipoMensagem === "SLC0005" ? true : false,
            ispbifCredtd: false,
            identdLinhaBilatMessage: false,
            SitLancSLC: false,
            DtMovtoBody: false,
            amount: true,
            dtMovtoBody: false,
            codMsg: false,
            descAtv: false,
            indrDevLiquid: false,
            ispbif: false,
            lastUpdateTimestamp: false,
            nomArqSLC: false,
            numCtrlEmissorArq: false,
            numCtrlLTROr: false,
            numCtrlSLC: false,
            subTpAtv: false,
            dtHrSLC: false,
            errorCode: true,
            errorDescription: true,
            contaCorrente: true,
            agencia: true,
            codigoBanco: true,
          }}
          columns={
            valueTipoMensagem === "SLC0002"
              ? columnsSLC0002
              : valueTipoMensagem === "SLC0005"
              ? columnsSLC0005
              : valueTipoMensagem === "SLC0001"
              ? columnsSLC0001
              : null
          }
          getRowId={(row) => generateRandom()}
          pageSize={15}
        />
      </Card>
    </Box>
  );
};
