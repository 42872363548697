import React, { useState } from "react";
import axios from "axios";

import { Box, Button, FormControl, TextField } from "@mui/material";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { DataTable } from "elements/dataTable";

export const ConsultaSaldo = () => {
  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [valueAccountId, setValueAccountId] = useState("");
  const handleChangeValueAccountId = (event) => {
    console.log(event.target.value);
    setValueAccountId(event.target.value);
  };

  const [data, setData] = useState([]);

  const columns = [
    {
      field: "account",
      headerName: "account",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountId",
      headerName: "accountId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "balance",
      headerName: "balance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "blockedAmount",
      headerName: "blockedAmount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "availableBalance",
      headerName: "availableBalance",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "searchDate",
      headerName: "searchDate",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
  ];

  const fetchData = async (aUrl = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const handleSearch = async () => {
    if (valueAccountId.length <= 0) {
      toast.error("Campo accountId vazio!");
    } else {
      try {
        setLoading(true);

        const result = await fetchData(
          /* `https://backend.entrepay.com.br/btg-homolog/cm/account/${valueAccountId}/balance` */
          process.env.REACT_APP_CLIENT_BTG +
            `/cm/account/${valueAccountId}/balance`
        );
        console.log(result);
        setData([result]);
      } catch (error) {
        console.log(error.message);
        if (error.response.data.errors) {
          toast.error(error.response.data.errors[0].message);
        } else {
          toast.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "90%",
            }}
            label="Account Id"
            variant="outlined"
            value={valueAccountId}
            onChange={handleChangeValueAccountId}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Consultar
          </Button>
        </FormControl>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <DataTable
        rows={
          data.map((item, index) => ({
            ...item,
            account: item.account,
            accountId: item.accountId,
            availableBalance: item.availableBalance,
            balance: item.balance,
            blockedAmount: item.blockedAmount,
            searchDate: item.searchDate,
          })) ?? []
        }
        getRowId={(row) => generateRandomId()}
        columns={columns}
      />
    </Box>
  );
};
