import React, { useState } from "react";

import clsx from "clsx";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import { Sidebar } from "./SideBar";

import { Detail } from "./Detail";

// const repo = require("shared/constants/report.json");

const DetailViewWrapper = styled(Box)(({ theme }) => {
  return {
    transition: "all 0.5s ease",
    width: "100%",
    height: "100%",
    opacity: 0,
    visibility: "hidden",
    backgroundColor: theme.palette.background.paper,
    "&.show": {
      transform: "translateX(0)",
      opacity: 1,
      visibility: "visible",
    },
  };
});

const BigDataReport = ({ report }) => {
  const [currentTopic, setCurrentTopic] = useState(``);
  const [currentEntityIndex, setCurrentEntityIndex] = useState(0);

  const handleChangeTopic = function (aTopic, id) {
    setCurrentTopic(aTopic);
    setCurrentEntityIndex(id ?? -1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "left",
        textAlign: "left",
        // padding: 2,
        width: "100%",
        // gap: 2,
      }}
    >
      <Sidebar
        report={report}
        changeTopic={handleChangeTopic}
        currentTopic={currentTopic}
      />
      <DetailViewWrapper className={clsx({ show: report })}>
        <>
          <Detail
            report={report}
            currentTopic={currentTopic}
            currentEntity={currentEntityIndex}
          />
        </>
      </DetailViewWrapper>
    </Box>
  );
};

export default BigDataReport;

BigDataReport.defaultProps = {};
BigDataReport.propTypes = {
  report: PropTypes.object,
};
