import React, { Fragment, useEffect, useState } from "react";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { DataTable } from "elements/dataTable";
import { NumericFormat } from "react-number-format";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

import AmericanExpressIcon from "./bandeiras/AmericanExpress.png"; // Importe sua imagem aqui
import CabalIcon from "./bandeiras/Cabal.png"; // Importe sua imagem aqui
import EloIcon from "./bandeiras/Elo.png"; // Importe sua imagem aqui
import HiperCardIcon from "./bandeiras/Hipercard.png"; // Importe sua imagem aqui
import MasterCardIcon from "./bandeiras/Mastercard.png"; // Importe sua imagem aqui
import VisaIcon from "./bandeiras/Visa.png"; // Importe sua imagem aqui

export const Taxas = () => {
  const [expanded, setExpanded] = useState(false);

  const tipoPagamentos = [
    { label: "Crédito", value: "Credito" },
    { label: "Débito", value: "Debito" },
  ];

  const tipoParcelamento = [
    { label: "A vista", value: "Avista" },
    { label: "Parcelado", value: "Parcelado" },
  ];

  const tipoCobranca = [{ label: "Padrão", value: "Padrao" }];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Função para concatenar os valores selecionados
  const getSelectedValues = () => {
    return Object.entries(checkboxes)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);
  };

  // DATAGRID
  const [dataGridOpen, setDataGridOpen] = useState(false);

  const handleOpenDataGrid = () => {
    setDataGridOpen((prevOpen) => !prevOpen); // Alternar o estado entre true e false
  };

  const handleDataGridClose = () => {
    setDataGridOpen(false);
  };

  const columnsWithDivider = (columns, dividerComponent, startIndex = 1) => {
    const columnsWithDivider = [];
    for (let i = 0; i < columns.length; i++) {
      columnsWithDivider.push(columns[i]);
      if (
        i >= startIndex &&
        (i - startIndex + 1) % 3 === 0 &&
        i + 1 !== columns.length
      ) {
        columnsWithDivider.push(dividerComponent);
      }
    }
    return columnsWithDivider;
  };

  const DividerComponent = () => (
    <Divider
      orientation="vertical"
      flexItem
      style={{ marginLeft: "5px", marginRight: "5px" }}
    />
  );

  const rows = [
    {
      id: 1,
      Tipo: "Débito",
      PrazoAmerican: "-",
      TaxaAmerican: "-",
      TarifaAmerican: "-",
      PrazoCabal: "1",
      TaxaCabal: "2,50%",
      TarifaCabal: "-",
      PrazoElo: "1",
      TaxaElo: "2,50%",
      TarifaElo: "-",
      PrazoHipercard: "-",
      TaxaHipercard: "-",
      TarifaHipercard: "-",
      PrazoMastercard: "1",
      TaxaMastercard: "1,70%",
      TarifaMastercard: "-",
      PrazoVisa: "1",
      TaxaVisa: "1,70%",
      TarifaVisa: "-",
    },
    {
      id: 2,
      Tipo: "Cŕedito a vista",
      PrazoAmerican: "30",
      TaxaAmerican: "3,55%",
      TarifaAmerican: "-",
      PrazoCabal: "30",
      TaxaCabal: "3,55%",
      TarifaCabal: "-",
      PrazoElo: "30",
      TaxaElo: "3,55%",
      TarifaElo: "-",
      PrazoHipercard: "30",
      TaxaHipercard: "3,35%",
      TarifaHipercard: "-",
      PrazoMastercard: "30",
      TaxaMastercard: "2,75%",
      TarifaMastercard: "-",
      PrazoVisa: "30",
      TaxaVisa: "2,75%",
      TarifaVisa: "-",
    },
    {
      id: 3,
      Tipo: "Parcelado Emissor",
      PrazoAmerican: "30",
      TaxaAmerican: "3,55%",
      TarifaAmerican: "-",
      PrazoCabal: "30",
      TaxaCabal: "3,55%",
      TarifaCabal: "-",
      PrazoElo: "30",
      TaxaElo: "3,55%",
      TarifaElo: "-",
      PrazoHipercard: "30",
      TaxaHipercard: "3,35",
      TarifaHipercard: "-",
      PrazoMastercard: "30",
      TaxaMastercard: "2,75%",
      TarifaMastercard: "-",
      PrazoVisa: "30",
      TaxaVisa: "2,75%",
      TarifaVisa: "-",
    },
  ];

  const columns = columnsWithDivider(
    [
      {
        field: "Tipo",
        headerName: "",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoAmerican",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaAmerican",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaAmerican",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoCabal",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaCabal",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaCabal",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoElo",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaElo",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaElo",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoHipercard",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaHipercard",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaHipercard",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoMastercard",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaMastercard",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaMastercard",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "PrazoVisa",
        headerName: "Prazo",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TaxaVisa",
        headerName: "Taxa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "TarifaVisa",
        headerName: "Tarifa",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
    ],
    DividerComponent,
    1
  );

  //ANCORA
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);

  // SELECTS
  const [displayParcelas, setDisplayParcelas] = useState("none");

  const [selectTipoPagamento, setTipoPagamento] = useState("");
  const handleChangeTipoPagamento = (event) => {
    setTipoPagamento(event.target.value);
  };

  const [selectParcelamento, setSelectedParcelamento] = useState("");
  const handleChangeParcelamento = (event) => {
    setSelectedParcelamento(event.target.value);
  };

  const [selectCobranca, setSelectedCobranca] = useState("");
  const handleChangeCobranca = (event) => {
    setSelectedCobranca(event.target.value);
  };

  const [selectBandeiras, setSelectedBandeiras] = useState([]);
  const handleChangeBandeiras = (event) => {
    setSelectedBandeiras({
      ...selectBandeiras,
      [event.target.name]: event.target.checked,
    });
  };

  //CHECKBOXES
  const [checkboxesBandeiras, setCheckboxesBandeiras] = React.useState({
    AmericanExpress: false,
    Cabal: false,
    Elo: false,
    HiperCard: false,
    MasterCard: false,
    Visa: false,
  });
  const handleChangeBandeirasCheckbox = (event) => {
    setCheckboxesBandeiras({
      ...checkboxesBandeiras,
      [event.target.name]: event.target.checked,
    });
  };

  const [checkboxPlusPrice, setCheckboxPlusPrice] = React.useState(false);
  const handleChangePlusPrice = () => {
    setCheckboxPlusPrice(!checkboxPlusPrice);
  };

  // SLIDER
  const [valueSlider, setSlider] = React.useState([1, 12]);
  const handleChangeSlider = (event, newValues) => {
    setSlider(newValues);
  };

  // TEXTFIELDS
  const [amount, setAmount] = useState(0);

  const [taxasMin, setTaxasMin] = useState(0);

  const [taxasMax, setTaxasMax] = useState(0);

  //BUTTONS
  const [buttonAdicionarClicked, setButtonAdicionarClicked] = useState(false);
  const handleButtonAdicionar = () => {
    setButtonAdicionarClicked(!buttonAdicionarClicked);
  };

  const handleIconButtonClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedPopoverId(id);
  };

  const numbers = Array.from({ length: 31 }, (_, index) => index); // Cria um array com números de 0 a 30

  const [selectedNumber, setSelectedNumber] = useState(null);
  const handleNumberSelect = (selectedNumber) => {
    setSelectedNumber(selectedNumber);
    setAnchorEl(null); // Fechar o popover após a seleção do número
  };

  //USEEFFECTS
  useEffect(() => {
    if (selectParcelamento === "Parcelado") {
      setDisplayParcelas("flex");
    } else {
      setDisplayParcelas("none");
    }
  }, [selectParcelamento]);

  useEffect(() => {
    console.log(buttonAdicionarClicked);
    if (!selectParcelamento || !selectCobranca || !selectTipoPagamento) {
      toast.warning("Os campos não foram preenchidos");
    }
  }, [buttonAdicionarClicked]);

  return (
    <Fragment>
      <Box>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="expand"
          size="small"
        >
          <ExpandMoreIcon />
        </IconButton>
        <Collapse in={expanded}>
          <FormControl sx={{ width: "100%" }}>
            <IconButton onClick={handleOpenDataGrid}>
              <QuestionMarkIcon />
            </IconButton>
            {dataGridOpen && (
              <Box style={{ height: "auto", width: "100%" }}>
                <Box
                  class="bandeiras"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div></div>
                  <div>
                    <img
                      src={AmericanExpressIcon}
                      alt="AmericanExpressIcon"
                      width={100}
                      height={70}
                    />
                  </div>
                  <div style={{ width: 1 }}></div>
                  <div>
                    <img
                      src={CabalIcon}
                      alt="CabalIcon"
                      width={100}
                      height={70}
                    />
                  </div>
                  <div style={{ width: 1 }}></div>
                  <div>
                    <img src={EloIcon} alt="EloIcon" width={100} height={70} />
                  </div>
                  <div style={{ width: 1 }}></div>
                  <div>
                    <img
                      src={HiperCardIcon}
                      alt="HiperCardIcon"
                      width={100}
                      height={70}
                    />
                  </div>
                  <div style={{ width: 1 }}></div>
                  <div>
                    <img
                      src={MasterCardIcon}
                      alt="MasterCardIcon"
                      width={100}
                      height={70}
                    />
                  </div>
                  <div style={{ width: 1 }}></div>
                  <div>
                    <img
                      src={VisaIcon}
                      alt="VisaIcon"
                      width={100}
                      height={70}
                    />
                  </div>
                </Box>
                <DataTable
                  rows={rows}
                  columns={columns}
                  autoPageSize
                  hideFooterSelectedRowCount
                  hideFooterRowCount
                  hideFooterPagination
                />
              </Box>
            )}
          </FormControl>
          <Box
            style={{
              width: "100%",
              height: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormGroup
                sx={{
                  display: "flex",
                  flexDirection: "initial",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.AmericanExpress}
                      onChange={handleChangeBandeirasCheckbox}
                      name="AmericanExpress"
                    />
                  }
                  label={
                    <img
                      src={AmericanExpressIcon}
                      alt="AmericanExpressIcon"
                      width={50}
                      height={70}
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.Cabal}
                      onChange={handleChangeBandeirasCheckbox}
                      name="Cabal"
                    />
                  }
                  label={
                    <img
                      src={CabalIcon}
                      alt="CabalIcon"
                      width={50}
                      height={70}
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.Elo}
                      onChange={handleChangeBandeirasCheckbox}
                      name="Elo"
                    />
                  }
                  label={
                    <img src={EloIcon} alt="EloIcon" width={50} height={70} />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.HiperCard}
                      onChange={handleChangeBandeirasCheckbox}
                      name="HiperCard"
                    />
                  }
                  label={
                    <img
                      src={HiperCardIcon}
                      alt="HiperCardIcon"
                      width={50}
                      height={70}
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.MasterCard}
                      onChange={handleChangeBandeirasCheckbox}
                      name="MasterCard"
                    />
                  }
                  label={
                    <img
                      src={MasterCardIcon}
                      alt="MasterCardIcon"
                      width={50}
                      height={70}
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesBandeiras.Visa}
                      onChange={handleChangeBandeirasCheckbox}
                      name="Visa"
                    />
                  }
                  label={
                    <img src={VisaIcon} alt="VisaIcon" width={50} height={70} />
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Collapse>
        <Box>
          <Card
            class="content"
            style={{
              width: "100%",
              height: "auto",
              overflow: "hidden",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Box sx={{ width: "100%", height: "auto" }}>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxPlusPrice}
                      onChange={handleChangePlusPrice}
                      name="Plus Price"
                    />
                  }
                  label="Plus Price"
                />
                <IconButton
                  onClick={(event) => handleIconButtonClick(event, "data")}
                >
                  <CalendarMonthIcon />
                </IconButton>
                <Popover
                  id={"data"}
                  open={open && selectedPopoverId === "data"}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center", // Ajuste a posição horizontal do popover
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center", // Ajuste a posição horizontal do popover
                  }}
                >
                  <ButtonGroup>
                    {numbers.map((number) => (
                      <Button
                        key={number}
                        variant={
                          selectedNumber === number ? "contained" : "outlined"
                        }
                        onClick={() => handleNumberSelect(number)}
                      >
                        {number}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Popover>
                <IconButton
                  style={{ display: displayParcelas }}
                  onClick={(event) => handleIconButtonClick(event, "parcela")}
                >
                  <PriceChangeIcon />
                </IconButton>
                <Popover
                  id={"parcela"}
                  open={open && selectedPopoverId === "parcela"}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center", // Ajuste a posição horizontal do popover
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center", // Ajuste a posição horizontal do popover
                  }}
                >
                  <Box style={{ padding: "16px" }}>
                    <Typography id="range-slider" gutterBottom>
                      Parcelas:
                    </Typography>
                    <Slider
                      value={valueSlider}
                      onChange={handleChangeSlider}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={(value) => `${value}`}
                      min={1}
                      max={21}
                      sx={{ width: 300 }}
                    />
                    <Typography>
                      De: {valueSlider[0]} - Até: {valueSlider[1]}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              <Box>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <InputLabel>Tipo de Pagamento</InputLabel>
                  <Select
                    required
                    value={selectTipoPagamento}
                    label="Bandeira"
                    onChange={(e) => handleChangeTipoPagamento(e)}
                    sx={{
                      width: "200px", // Ajuste o tamanho do Select aqui
                    }}
                  >
                    {tipoPagamentos.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <InputLabel>Tipo de Parcelamento</InputLabel>
                  <Select
                    required
                    value={selectParcelamento}
                    label="Bandeira"
                    onChange={(e) => handleChangeParcelamento(e)}
                    sx={{
                      width: "200px", // Ajuste o tamanho do Select aqui
                    }}
                  >
                    {tipoParcelamento.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <InputLabel>Tipo de Cobrança</InputLabel>
                  <Select
                    required
                    value={selectCobranca}
                    label="Bandeira"
                    onChange={(e) => handleChangeCobranca(e)}
                    sx={{
                      width: "200px", // Ajuste o tamanho do Select aqui
                    }}
                  >
                    {tipoCobranca.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <NumericFormat
                    required
                    label="Valor"
                    customInput={TextField}
                    decimalScale={2}
                    fixedDecimalScale
                    value={amount}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    prefix="R$ "
                    sx={{
                      width: "100%",
                    }}
                    onValueChange={(values, sourceInfo) => {
                      setAmount(values.floatValue);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <NumericFormat
                    required
                    label="Taxas Min"
                    customInput={TextField}
                    decimalScale={2}
                    fixedDecimalScale
                    value={taxasMin}
                    decimalSeparator=","
                    allowNegative={false}
                    suffix="% "
                    sx={{
                      width: "100%",
                    }}
                    onValueChange={(values, sourceInfo) => {
                      setTaxasMin(values.floatValue);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <NumericFormat
                    required
                    label="Taxas Max"
                    customInput={TextField}
                    decimalScale={2}
                    fixedDecimalScale
                    value={taxasMax}
                    decimalSeparator=","
                    allowNegative={false}
                    suffix="% "
                    sx={{
                      width: "100%",
                    }}
                    onValueChange={(values, sourceInfo) => {
                      setTaxasMax(values.floatValue);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1, margin: 2 }}>
                  <Button
                    variant="contained"
                    onClick={handleButtonAdicionar}
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "primary.contrastText",
                      bgcolor: "primary.main",
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                  >
                    Adicionar
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Fragment>
  );
};
