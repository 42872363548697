import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { useCallback } from "react";
import request from "../../shared/utils/request";
import { toast } from "react-toastify";
import { useAuthMethod } from "../../hooks/AuthHooks";

export default function Row(props) {
  const { row } = props;
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const { getToken } = useAuthMethod();

  const queryDetails = useCallback(async () => {
    console.log("queryDetails");
    setLoading(true);
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/portfolio_management/file/${row.id}`,
        "get",
        token
      );

      console.log("result", result);
      setDetails(result);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao listar dados");
    } finally {
      setLoading(false);
    }
  }, []);

  function handleExpandButton() {
    if (open) {
      setOpen(false);
      return;
    }

    queryDetails();
    setOpen(true);
  }

  async function handleDownload() {
    const signedUrl = await request(
      process.env.REACT_APP_API_URL +
        `/portfolio_management/file/${row.id}/signedUrl`,
      "get",
      await getToken()
    );

    window.open(signedUrl, "_blank");
  }

  function handleExport() {
    const url = row.protein;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleExpandButton}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fileName}
        </TableCell>
        <TableCell align="right">{row.fileRecords}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">
          <Button onClick={handleDownload}>Download</Button>{" "}
          <Button style={{ display: "none" }} onClick={handleExport}>
            Exportar
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {loading || details.length < 1 ? (
                <Typography variant="h6" gutterBottom component="div">
                  Carregando...
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Registros
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Documento</TableCell>
                        <TableCell>Documento Pai</TableCell>
                        <TableCell>Razão Social</TableCell>
                        <TableCell align="right">Resultado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.map((detail) => (
                        <TableRow key={detail.document}>
                          <TableCell component="th" scope="row">
                            {detail.document}
                          </TableCell>
                          <TableCell>{detail.documentFather}</TableCell>
                          <TableCell>{detail.socialReason}</TableCell>
                          <TableCell align="right">{detail.message}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
