import React, { useEffect, useState } from "react";
import uuid4 from "uuid4";

import axios from "axios";

import { Box, CircularProgress } from "@mui/material";
import { DataTable } from "elements/dataTable";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";

export const ConsultaPlanilha = () => {
  // token da autenticação
  const { getToken } = useAuthMethod();

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "totalObjects",
      headerName: "Contratos Restantes",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isProcessing",
      headerName: "Status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.value == false) {
          return "";
        }
        return <CircularProgress size={20} color="primary" />;
      },
    },
  ];

  const [rows, setRows] = useState([]);

  const fetchData = async (aUrl, aParams = {}) => {
    const token = await getToken();
    setLoading(true);

    const params = {
      method: "GET",
      url: process.env.REACT_APP_CLIENT_BTG_SERVER + "/planilha/queueStatus",
      headers: 
        {
          'Authorization': `Bearer ${token}`,
        },
    };
    const result = await axios(params);
    console.log(result);
    if (result.status === 200) {
      const d = result.data.map((item) => {
        return {
          id: uuid4(),
          totalObjects: item.totalObjects,
          isProcessing: item.isProcessing,
        };
      });
      setRows(d);
    } else {
      toast.error("Erro ao buscar status!");
    }
    setLoading(false);
  };

  /**
   * useEffect
   */
  useEffect(() => {
    fetchData();
  }, []);

  // UseEffect para chamar fetchEvery3Seconds a cada 3 segundos
  useEffect(() => {
    const interval = setInterval(fetchData, 3000); // Chama a função a cada 3 segundos (3000 ms)

    // Retorno da função de limpeza para parar o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <DataTable rows={rows} columns={columns} />
      <Hover loading={loading} />
    </Box>
  );
};
