import * as React from "react";
import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Hover } from "elements/hover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NumericFormat } from "react-number-format";

const TextFieldForm = styled(TextField)({
  borderRadius: "9px",
  backgroundColor: "#FFFFFF",
});
const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

function getQuestionAnswer(answers, questionId) {
  return (
    answers.find((v) => v?.id === questionId)?.questionAnswer?.toLowerCase() ??
    null
  );
}

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

function ComercialForm(props) {
  const [questions, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ranges, setRanges] = useState([]);
  const [numRanges, setNumRanges] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const getQuestions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/questions/comercial/" + id,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );

      setQuestion(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar perguntas");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const onChangeAnswers = (id, answer, questionIdentifier) => {
    const temp = answers.map((v) => v);
    temp[id] = {
      ...temp[id],
      id,
      questionAnswer: answer,
    };
    setAnswers(temp);
    if (questionIdentifier === "QTD_FAIXAS") {
      setNumRanges(Array(Number(answer)).fill(1));
    }

    if (questionIdentifier === "TIPO_COMISSAO" && answer !== "faixas") {
      setNumRanges([]);
    }
  };

  const onChangeRanges = (index, parameter, value) => {
    const temp = ranges.map((v) => v);
    temp[index] = {
      ...temp[index],
      [parameter]: value,
    };
    setRanges(temp);
  };

  const onClickNext = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/form/comercial/" + id,
        answers,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        process.env.REACT_APP_API_URL + "/form/comercialdata/" + id,
        ranges,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Formulario preenchido com sucesso.");
      navigate("/onboarding/proposals/liststatus");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao salvar perguntas");
    } finally {
      setLoading(false);
    }
  };
  const getAnswers = (id) => {
    return !!answers && !!answers[id] && !!answers[id].questionAnswer
      ? answers[id].questionAnswer
      : "";
  };

  const renderOptions = (options, id, onChange, value, label = null) => {
    return (
      <FormControl fullWidth>
        {label && (
          <FormLabel id={`remuneration-label-${id}`}>{label}</FormLabel>
        )}
        <RadioGroup
          aria-label="question"
          id=""
          name={"question" + id}
          aria-labelledby={`remuneration-label-${id}`}
          row
          onChange={onChange}
          value={value}
          required={true}
        >
          {options?.map((option, index) => {
            return (
              <FormControlLabel
                key={`option-${id}-${index}`}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <>
      <main>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Divider />
          <Container>
            <h2>Formulario</h2>

            <br />
            <Hover loading={loading} />
            {questions?.length > 0 && (
              <form onSubmit={onClickNext}>
                {questions?.map((question, index) => {
                  const dependencyQuestionId = question?.dependencyQuestionId;
                  const answer = getQuestionAnswer(
                    answers,
                    dependencyQuestionId
                  );
                  const questionAnswer = getQuestionAnswer(
                    answers,
                    question.id
                  );
                  const shouldRender =
                    !question?.dependencyAnswer ||
                    (question?.dependencyAnswer !== null &&
                      question?.dependencyAnswer?.toLowerCase() === answer);
                  const showMessage =
                    question?.uploadMessageAnswer?.toLowerCase() ===
                      questionAnswer ||
                    question?.uploadMessageAnswerSecondary?.toLowerCase() ===
                      questionAnswer;
                  const options =
                    (question?.questionType === "checkbox" ||
                      question?.questionType === "radioGroup") &&
                    question.options
                      ? JSON.parse(question.options).options
                      : [];
                  return (
                    <div key={question.id}>
                      {shouldRender && <h4>{question?.question}</h4>}
                      {(question?.questionType === "text" ||
                        question?.questionType === "number") &&
                        shouldRender && (
                          <>
                            <TextFieldForm
                              key={question.id}
                              id="outlined-basic"
                              label=""
                              variant="outlined"
                              size="small"
                              required={question?.required}
                              type={question.questionType}
                              value={getAnswers(question.id)}
                              onChange={(event) =>
                                onChangeAnswers(
                                  question.id,
                                  event.target.value,
                                  question.questionIdentifier
                                )
                              }
                              style={{
                                width: "80%",
                              }}
                            />
                            <Divider sx={{ mb: 3 }} />
                          </>
                        )}
                      {question?.questionType === "radioGroup" &&
                        shouldRender && (
                          <>
                            {renderOptions(
                              options,
                              question.id,
                              (event) =>
                                onChangeAnswers(
                                  question.id,
                                  event.target.value,
                                  question.questionIdentifier
                                ),
                              getQuestionAnswer(answers, question.id)
                            )}
                            <Divider />
                          </>
                        )}
                      {question?.questionType === "textArea" &&
                        shouldRender && (
                          <>
                            <Textarea
                              placeholder="Type in here…"
                              defaultValue=""
                              minRows={2}
                              maxRows={4}
                              onChange={(event) =>
                                onChangeAnswers(
                                  question.id,
                                  event.target.value,
                                  index
                                )
                              }
                              style={{
                                width: "80%",
                              }}
                            />
                            <Divider />
                          </>
                        )}

                      {question?.questionIdentifier === "QTD_FAIXAS" && (
                        <>
                          {numRanges?.map((value, index) => {
                            return (
                              <Accordion key={`range-${index}`} expanded={true}>
                                <AccordionSummary
                                  sx={{
                                    backgroundColor: "navy",
                                    color: "white",
                                  }}
                                  expandIcon={
                                    <ExpandMoreIcon sx={{ color: "white" }} />
                                  }
                                  aria-controls={`panel1a-content-${index}`}
                                  id={`panel1a-header-${index}`}
                                >
                                  <Typography>{index + 1}º Faixa</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <NumericFormat
                                    required
                                    label="Faturamento Mínimo"
                                    customInput={TextFieldForm}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={ranges?.[index]?.minIncome}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    allowNegative={false}
                                    prefix="R$ "
                                    style={{
                                      width: "100%",
                                    }}
                                    onValueChange={(values, sourceInfo) => {
                                      onChangeRanges(
                                        index,
                                        "minIncome",
                                        values.floatValue
                                      );
                                    }}
                                  />
                                  <NumericFormat
                                    required
                                    label="Faturamento Máximo"
                                    customInput={TextFieldForm}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={ranges?.[index]?.maxIncome}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    allowNegative={false}
                                    prefix="R$ "
                                    style={{
                                      width: "100%",
                                    }}
                                    onValueChange={(values) => {
                                      onChangeRanges(
                                        index,
                                        "maxIncome",
                                        values.floatValue
                                      );
                                    }}
                                  />
                                  {renderOptions(
                                    [
                                      {
                                        value: "ITC + Fee",
                                        label: "ITC + Fee",
                                      },
                                      { value: "MDR", label: "MDR" },
                                    ],
                                    `remuneration-${index}`,
                                    (event) =>
                                      onChangeRanges(
                                        index,
                                        "remunerationType",
                                        event.target.value
                                      ),
                                    ranges?.[index]?.remunerationType,
                                    "Remuneração será em *"
                                  )}

                                  <NumericFormat
                                    required
                                    label="Fee"
                                    customInput={TextFieldForm}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={ranges?.[index]?.fee}
                                    decimalSeparator="."
                                    allowNegative={false}
                                    suffix="%"
                                    style={{
                                      width: "100%",
                                    }}
                                    onValueChange={(values, sourceInfo) => {
                                      onChangeRanges(
                                        index,
                                        "fee",
                                        values.floatValue
                                      );
                                    }}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}

                          <Divider />
                        </>
                      )}
                    </div>
                  );
                })}

                <Footer>
                  <Button
                    sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                    variant="contained"
                    disabled={loading}
                    type="submit"
                  >
                    Continuar
                  </Button>
                </Footer>
              </form>
            )}
          </Container>
        </Box>
      </main>
      <Divider />
      <br />
    </>
  );
}

export default ComercialForm;
