import React, {useEffect, useState} from "react";
import request from "../../../../../shared/utils/request";
import {toast} from "react-toastify";
import {useAuthMethod} from "../../../../../hooks/AuthHooks";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

function SetDomainModal(props) {
  const { id, onUpdate } = props;
  const { getToken } = useAuthMethod();
  const [category, setCategory] = useState(undefined);
  const [value, setValue] = useState('');

  async function handleOpen() {
    try {
      const token = await getToken();
      const result = await request(process.env.REACT_APP_API_URL + `/section/${id}`, "get", token);
      console.log(result);
      setValue(result.domain)
      setCategory(result);
    } catch (error) {
      toast.error("Erro ao buscar categoria");
    }
  }

  function handleClose() {
    setCategory(undefined);
  }

  async function handleConfirmAndClose() {
    const token = await getToken();
    try {
      await request(process.env.REACT_APP_API_URL + `/section/${id}`, "put", token, {
        ...category,
        domain: value,
      });
    } catch (error) {
      toast.error("Erro ao atualizar categoria");
    }
    if (onUpdate && typeof onUpdate === 'function') {
      onUpdate();
    }
    setCategory(undefined);
  }

  return (
    <>
      <Button variant='outlined' onClick={handleOpen}>Configurar Dominio</Button>
      <Dialog open={!!category} onClose={handleClose}>
        <DialogTitle>Dominio Esteira Flex</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insira o dominio que deseja configurar para a fila { category?.description }
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="domain"
            name="domain"
            label="dominio"
            type="text"
            fullWidth
            variant="standard"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirmAndClose}>{
            (!value || value.length === 0) && category?.domain ? 'Remover' :
            category?.domain ? 'Atualizar' : 'Definir'
          } Dominio</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SetDomainModal;
