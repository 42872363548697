import Box from "@mui/material/Box";
// import { DataGrid } from '@mui/x-data-grid';
import { Button, FormControl, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import React, { useCallback, useState } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import { DataTable } from "elements/dataTable";
import { Hover } from "elements/hover";

function Consult() {
  const [listTransaction, setListTransaction] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const onClickSearch = () => {
    listTransactions();
  };

  const handleChangeDataInicio = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeDataFim = (newValue) => {
    setEndDate(newValue);
  };

  const listTransactions = useCallback(async () => {
    setLoading(true);
    const token = await getToken();

    try {
      const result = await request(
        `${
          process.env.REACT_APP_BCODEX_API_URL
        }/data-from-bigquery?startDate=${startDate.format(
          "YYYY-MM-DD"
        )}&endDate=${endDate.format("YYYY-MM-DD")}`,
        "get",
        token
      );

      setListTransaction(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    listTransactions();
  }, []);

  const columns = [
    {
      field: "ecTransaçãoOrigemName",
      headerName: "EC da transação de origem",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    {
      field: "ecTransaçãoFinal",
      headerName: "EC da transação final",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    {
      field: "idQRCode",
      headerName: "Id do QR Code.",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    // {
    //   field: "status_qr_code.",
    //   headerName: "Status do QR Code.",
    //   type: "number",
    //   flex: 1,
    // },
    {
      field: "idTransacaoPix",
      headerName: "Id da transação PIX",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    {
      field: "valorCustoPix",
      headerName: "Valor de custo do PIX.",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    {
      field: "statusPix",
      headerName: "Status do PIX.",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },

    {
      field: "dataHoraTransacaoFeita",
      headerName: "Data de pagamento/hora",
      align: "center",
      alignHeader: "center",
      flex: 1,
      width: "100%",
    },
    // {
    //   field: "if_origem",
    //   headerName: "IF origem",
    //   flex: 1,
    // },

    // {
    //   field: "documento_origem.",
    //   headerName: "Documento origem.",
    //   type: "number",
    //   flex: 1,
    // },
    // {
    //   field: "tipo_documento_origem.",
    //   headerName: "Tipo do documento do origem.",
    //   flex: 1,
    // },

    // {
    //   field: "nome_final.",
    //   headerName: "Nome Final.",
    //   type: "number",
    //   flex: 1,
    // },
    // {
    //   field: "documento_final",
    //   headerName: "Documento Final",
    //   flex: 1,
    // },

    // {
    //   field: "tipo_documento_final.",
    //   headerName: "Tipo do Documento Final.",
    //   type: "number",
    //   flex: 1,
    // },
    // {
    //   field: "if_final.",
    //   headerName: "IF final.",
    //   flex: 1,
    // },

    // {
    //   field: "cod_aut.",
    //   headerName: "Cod aut.",
    //   type: "number",
    //   flex: 1,
    // },
  ];

  function MyExportButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 15, fontWeight: "bold", mb: 3, mt: 3 }}>
            Consulta
          </Typography>

          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                label="Data de início"
                value={startDate}
                onChange={(value) => handleChangeDataInicio(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
                sx={{ mr: 3 }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: "0.1em", width: 100 } }}
                label="Data de final"
                value={endDate}
                onChange={(value) => handleChangeDataFim(value)}
                slotProps={{ textField: { variant: "outlined" } }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ flexGrow: 1, margin: 2 }}>
            <Button
              variant="contained"
              onClick={onClickSearch}
              sx={{
                m: 1,
                width: "100%",
                color: "primary.contrastText",
                bgcolor: "primary.main",
                "&:hover": { backgroundColor: "primary.light" },
              }}
            >
              Buscar
            </Button>
          </FormControl>

          <DataTable
            rows={listTransaction}
            columns={columns}
            components={{
              Toolbar: MyExportButton,
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
}

export default Consult;
