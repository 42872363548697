import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Hover } from "elements/hover";
import formatCnpj from "shared/utils/formatCnpj";
import { PatternFormat } from "react-number-format";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

// import AppTextField from "@crema/core/AppFormComponents/AppTextField";

const columns = [
  { id: "CNPJ", label: "CNPJ", minWidth: 170 },
  { id: "Nome", label: "Nome", minWidth: 100 },
  { id: "Comercial", label: "Comercial", minWidth: 100 },
  { id: "Status", label: "Status", minWidth: 100 },
  { id: "clientType", label: "Tipo cliente", minWidth: 100 },
  { id: "Ações", label: "Ações", minWidth: 100 },
];

const ListStatus = () => {
  const [proposalList, setProposalList] = useState([]);
  const [countPage, setCountPage] = React.useState({ length: 0 });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    listProposal(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  // vai buscar na api a lista das análises de pricing
  const getSearchProposal = async function () {
    let leads;
    const token = await getToken();
    try {
      if (search == "") {
        listProposal(0);
      } else {
        setLoading(true);
        setPage(0);
        const result = await request(
          process.env.REACT_APP_API_URL + `/cnpj/${search}/${"onboarding"}`,
          "get",
          token
        );
        leads = result;
        setProposalList(leads);
      }
    } catch (error) {
      console.error(error);
      setProposalList([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataForPage = async (page) => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/leads/onboading?page=${page}`,
        "get",
        token
      );
      ///const leads = sortBy(result, (v) => v.analysis?.statusRisk);
      const resultCount = await request(
        process.env.REACT_APP_API_URL + `/leads/onboarding/count`,
        "get",
        token
      );
      setCountPage({ length: resultCount });
      setProposalList(result);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  // vai buscar na api a lista das análises de pricing
  const listProposal = async function (page) {
    const token = await getToken();
    try {
      setLoading(true);
      await fetchDataForPage(page);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setProposalList([]);
    } finally {
      setLoading(false);
    }
  };

  const resendEmail = async (aId) => {
    try {
      const token = await getToken();
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + "/terms/resend/" + aId,
        "post",
        token
      );

      toast.success("Email reenviado com sucesso.");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao reenviar email");
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal(0);
  }, []);

  const handleRowClickForm = function (aId) {
    navigate(`/onboarding/proposals/comercialform/${aId}`);
  };

  const handleRowClick = function (aId) {
    navigate(`/onboarding/proposals/detail/${aId}`);
  };

  const handleRowClickRelatorio = function (aId) {
    navigate(`/onboarding/proposals/liststatus/detail/${aId}`);
  };

  const handleRowClickSend = function (aId) {
    resendEmail(aId);
    setId(aId);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Consultar Status das Analises
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ justifyContent: "end", display: "flex" }}>
            <PatternFormat
              required
              customInput={TextField}
              style={{ height: "30px", marginRight: "10px" }}
              value={search}
              allowLeadingZeros
              format="##.###.###/####-##"
              allowEmptyFormatting
              onValueChange={(values, sourceInfo) => {
                setSearch(values.value);
              }}
              mask="_"
            />

            <Button
              onClick={getSearchProposal}
              sx={{ backgroundColor: "#000536", mb: 5, mt: 1 }}
              variant="contained"
              small
            >
              Buscar
            </Button>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: "#000536", color: "#ffffff" }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalList
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const analysis = row?.analysis;
                    let status = "";
                    if (analysis?.clientType !== "VAR") {
                      if (analysis?.acceptTerms?.status === "Pendente") {
                        status = "Termos";
                      } else if (analysis?.statusForm === null) {
                        status = "Formulario";
                      } else if (analysis?.statusForecastCompleted === null) {
                        status = "Forecast";
                      } else if (analysis?.statusUpload === null) {
                        status = "Upload";
                      } else if (analysis?.statusRisk === "Em analise") {
                        status = "Risco";
                      } else if (
                        analysis?.guarantee?.statusGuarantee === null
                      ) {
                        status = "Garantia";
                      } else if (analysis?.statusPricing === "Em analise") {
                        status = "Pricing";
                      } else if (analysis?.statusCompliance === null) {
                        status = "Compliance";
                      } else if (analysis?.statusCsv === null) {
                        status = "Juridico";
                      } else {
                        status = "Finalizado";
                      }
                    } else {
                      if (analysis?.acceptTerms?.status === "Pendente") {
                        status = "Termos";
                      } else if (analysis?.statusForm === null) {
                        status = "Formulario";
                      } else if (analysis?.statusForecastCompleted === null) {
                        status = "Forecast";
                      } else if (analysis?.statusUpload === null) {
                        status = "Upload";
                      } else if (analysis?.statusRisk === "Em analise") {
                        status = "Risco";
                      } else if (analysis?.statusPricing === "Em analise") {
                        status = "Pricing";
                      } else if (analysis?.statusCsv === null) {
                        status = "Juridico";
                      } else {
                        status = "Finalizado";
                      }
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell>{formatCnpj(row?.cnpj)}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{analysis?.salesName}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{analysis?.clientType}</TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            onClick={() => handleRowClick(row?.analysis?.id)}
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            size="small"
                            disabled={loading}
                          >
                            Visualizar
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                              ml: 5,
                            }}
                            variant="contained"
                            size="small"
                            onClick={() =>
                              handleRowClickSend(row?.analysis?.id)
                            }
                            disabled={
                              analysis?.acceptTerms?.status === "Aprovado" ||
                              loading
                            }
                          >
                            Reenviar T&C
                            {loading === true && id == row.id && (
                              <CircularProgress />
                            )}
                          </Button>
                          <Button
                            onClick={() =>
                              handleRowClickRelatorio(row?.analysis?.id)
                            }
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                              ml: 5,
                            }}
                            variant="contained"
                            size="small"
                            disabled={false}
                          >
                            Relatorio
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={countPage.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Hover loading={loading} />
      </Box>
    </Fragment>
  );
};

export default ListStatus;
