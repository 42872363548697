import React, { Fragment, useEffect, useState } from "react";
import ListItem from "./ListItem";
import Box from "@mui/material/Box";
import { Fonts } from "shared/constants/AppEnums";
import AppList from "@crema/core/AppList";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { BsGraphUp } from "react-icons/bs";
import { GiArcheryTarget } from "react-icons/gi";
import { TbReport } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { Hover } from "elements/hover";
import { toast } from "react-toastify";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const Report = ({ id }) => {
  const { proposalId } = useParams();

  const [proposal, setProposal] = useState({ title: null, description: null });
  const [scoreColor, setScoreColor] = useState();
  const [score, setScore] = useState([]);
  const [totalScore, setTotalScore] = useState();
  const [criteriaList, setCriteriaList] = useState([]);
  const [pathFinder, setPathFinder] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { getToken } = useAuthMethod();

  const onChangePathFinder = (event) => setPathFinder(event.target.value);

  const updatePathFinder = async () => {
    setLoading(true);
    const token = await getToken();
    try {
      await request(
        process.env.REACT_APP_API_URL +
          `/analysis/${proposalId ? proposalId : id}`,
        "put",
        token,
        { pathFinder }
      );
      toast.success("Path finder atualizado com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao atualizar o pathfinder.");
    } finally {
      setLoading(false);
    }
  };

  // vai buscar na api a lista das propopstas (TO DO pode passar algum filtro)
  const getProposal = async function () {
    const token = await getToken();
    try {
      setLoading(true);
      if (typeof proposalId === "undefined" && id === null) {
        toast.warning("Id da proposta não encontrado");
        console.log("proposal id undefined");
        navigate("/");
        return <Box></Box>;
      }

      const [
        result,
        resultForecast,
        resultAnalysisScore,
        scoreSections,
        resultSections,
      ] = await Promise.all([
        request(
          process.env.REACT_APP_API_URL +
            `/analysis/${proposalId ? proposalId : id}`,
          "get",
          token
        ),
        request(
          process.env.REACT_APP_API_URL +
            `/forecast/${proposalId ? proposalId : id}`,
          "get",
          token
        ),
        request(
          process.env.REACT_APP_API_URL +
            `/analysisscore/${proposalId ? proposalId : id}`,
          "get",
          token
        ),
        request(process.env.REACT_APP_API_URL + `/totalscore/`, "get", token),
        request(process.env.REACT_APP_API_URL + `/section/`, "get", token),
      ]);
      const totalScore = resultAnalysisScore?.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      const sections = resultSections?.reduce((acc, curr) => {
        acc[curr.description] = 0;
        return acc;
      }, {});
      const totalPerSection = resultAnalysisScore?.reduce((acc, curr) => {
        if (acc[curr.rulesSection.description] !== null) {
          acc[curr.rulesSection.description] += curr.score;
        }

        return acc;
      }, sections);
      const resultScore = await request(
        process.env.REACT_APP_API_URL + `/totalscore/${totalScore}`,
        "get",
        token
      );

      setProposal(result);
      setPathFinder(result.pathFinder);
      setScore(scoreSections);
      setTotalScore(totalScore);
      // setAnalysisScore(resultAnalysis.data);

      const criterias = [
        {
          name: `Atividades - Score ${totalPerSection["Atividades"]}`,
          information: (
            <>
              {resultForecast?.map((data) => {
                return (
                  <>
                    <Typography key={data.id}>
                      {data?.mcc} - {data?.mccDescription?.mccName} -{" "}
                      {data?.mccDescription?.riskRating}
                    </Typography>
                  </>
                );
              })}
            </>
          ),
          image: <BsGraphUp alt="criteria" className="crUserImage" />,
          show: true,
        },
        {
          name: `Beneficiarios - Score ${totalPerSection["Beneficiarios"]}`,
          information:
            result?.acceptTerms?.status === "Aprovado"
              ? totalPerSection["Beneficiarios"] > 0
                ? "BENEFICIARIO(S) NAO IDENTIFICADO(S)"
                : "BENEFICIARIO(S) IDENTIFICADO(S)"
              : "",
          image: <GiArcheryTarget alt="criteria" className="crUserImage" />,
          show: result?.clientType === "VAR" ? false : true,
        },
        {
          name: `Reputação - Score ${totalPerSection["Reputação"]}`,
          information: (
            <>
              {resultAnalysisScore
                ?.filter((v) => v.rules != null)
                .map((score) => (
                  <Typography key={score.id}>
                    {score?.rules?.description} - Pontuação: {score?.score} (
                    {score?.ruleInformation})
                  </Typography>
                ))}
            </>
          ),
          image: <TbReport alt="criteria" className="crUserImage" />,
          show: true,
        },
      ];
      setCriteriaList(criterias);

      // const score = result.data?.report?.overallScore;
      const riskLevel = resultScore?.riskLevel;

      if (riskLevel == "Baixo") {
        setScoreColor("green");
      } else if (riskLevel == "Medio") {
        setScoreColor("yellow");
      } else if (riskLevel == "Alto") {
        setScoreColor("orange");
      } else if (riskLevel == "Critico") {
        //terminar depois
        setScoreColor("red");
      } else {
        setScoreColor("red");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProposal();
  }, [proposalId]);

  return (
    <Fragment>
      <Hover loading={loading} />

      <Card
        sx={{
          p: 5,
          mb: 5,
        }}
        className="item-hover"
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Relatorio
            </Typography>
          </Toolbar>
        </AppBar>
        <Typography>Nome: {proposal?.dataCnpj?.name}</Typography>
        <Typography>
          Data:{" "}
          {proposal?.analysisDate
            ? format(parseISO(proposal?.analysisDate), "dd-MM-yyyy HH:mm:ss")
            : ""}
        </Typography>
        <Typography>Indentificacao: {proposal?.dataCnpj?.cnpj}</Typography>
      </Card>

      <Grid container sx={{ marginBottom: 5 }}>
        <Grid item xs={9}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#000536" }}>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{ color: "#ffffff" }}
                  >
                    Classificacao de Cliente em Categoria
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={{ color: "#ffffff" }}
                  >
                    Criterio de Decisao Acoes Aplicaveis
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Grau de Risco</TableCell>
                  <TableCell align="left">Pontuacao</TableCell>
                  <TableCell align="left">Acao</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {score?.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ backgroundColor: row?.color }}
                      component="th"
                      scope="row"
                    >
                      {row?.riskLevel}
                    </TableCell>
                    <TableCell align="left">
                      {row?.minScore} até {row?.maxScore}
                    </TableCell>
                    <TableCell align="left">{row?.action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          xs={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Card
            sx={{
              p: 5,
              mb: 5,
              ml: 5,
              height: 310,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="item-hover"
          >
            <Box
              sx={{
                backgroundColor: scoreColor,
                textAlign: "center",

                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Typography sx={{ fontSize: 100, width: "100%" }}>
                {totalScore}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>

      {criteriaList ? (
        <AppList
          data={criteriaList}
          renderRow={(criteria) => {
            return <ListItem criteria={criteria} key={criteria?.name} />;
          }}
        />
      ) : null}
      {proposal?.statusRisk === "Em analise" && (
        <ListItem
          criteria={{
            name: `PathFinder`,
            information: (
              <>
                {" "}
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Descricao do pathfinder."
                  onChange={onChangePathFinder}
                  style={{ width: 500 }}
                  value={pathFinder}
                />
                ,
                <Button
                  sx={{
                    backgroundColor: "navy",
                    borderRadius: "10px",
                    ml: 5,
                    mb: 10,
                  }}
                  variant="contained"
                  disabled={loading}
                  onClick={updatePathFinder}
                >
                  Salvar
                </Button>
              </>
            ),
            image: <FaRegListAlt alt="criteria" className="crUserImage" />,
          }}
          key={"Path finder"}
        />
      )}
    </Fragment>
  );
};

export default Report;

Report.defaultProps = {
  id: null,
};
Report.propTypes = {
  id: PropTypes.number,
};
