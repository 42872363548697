import React, { Fragment } from "react";
import { Box, Typography, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fonts } from "shared/constants/AppEnums";
import { ReactComponent as Logo } from "assets/icon/404.svg";
import { useTheme } from "@mui/material";
import { useRouteError } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const theme = useTheme();
  const error = useRouteError();
  const navigate = useNavigate();

  const onGoBackToHome = () => {
    navigate("/");
  };

  return (
    <Fragment>
      <Box
        sx={{
          py: { xl: 8 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            mb: { xs: 4, xl: 8 },
            width: "100%",
            maxWidth: { xs: 200, sm: 300, xl: 706 },
            "& svg": {
              width: "100%",
              maxWidth: 400,
            },
          }}
        >
          <Logo fill={theme.palette.primary.main} />
        </Box>
        <Box
          sx={{
            mb: { xs: 4, xl: 5 },
          }}
        >
          <Box
            variant="h3"
            sx={{
              mb: { xs: 3, xl: 4 },
              fontSize: { xs: 20, md: 24 },
              fontWeight: Fonts.MEDIUM,
            }}
          >
            <Typography>{error?.message}</Typography>
          </Box>
          <Box
            sx={{
              mb: { xs: 4, xl: 5 },
              color: grey[600],
              fontSize: 16,
              fontWeight: Fonts.MEDIUM,
            }}
          >
            <Button onClick={onGoBackToHome}>Voltar para o início</Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
