// remove acentos, ç, etc e coloca tudo em minúscula
export const plain = function (str) {
  if (str) {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  } else {
    return "";
  }
};
