import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Button, styled, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Divider from "@mui/material/Divider";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import TableBody from "@mui/material/TableBody";

const StyledTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: 15,
  flexGrow: 1,
  mt: 1,
});

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const ComercialSummary = () => {
  const [loading, setLoading] = useState(false);
  const [bigData, setBigData] = useState();
  const [mdr, setMdr] = useState();
  const [analysis, setAnalysis] = useState();
  const [analysisScore, setAnalysisScore] = useState();
  const [pricingPartner, setPricingPartner] = useState();
  const [totalScore, setTotalScore] = useState();
  const [scoreColor, setScoreColor] = useState();
  const [score, setScore] = useState([]);

  const { getToken } = useAuthMethod();

  let { id } = useParams();

  const listProposal = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${id}`,
        "get",
        token
      );
      const resultMdr = await request(
        process.env.REACT_APP_API_URL + `/feemdr/${id}`,
        "get",
        token
      );
      const resultAnalysis = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );
      const resultAnalysisScore = await request(
        process.env.REACT_APP_API_URL + `/analysisscore/${id}`,
        "get",
        token
      );
      const scoreSections = await request(
        process.env.REACT_APP_API_URL + `/totalscore/`,
        "get",
        token
      );

      const totalScore = resultAnalysisScore?.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      const resultScore = await request(
        process.env.REACT_APP_API_URL + `/totalscore/${totalScore}`,
        "get",
        token
      );

      const riskLevel = resultScore?.riskLevel;

      if (riskLevel == "Baixo") {
        setScoreColor("green");
      } else if (riskLevel == "Medio") {
        setScoreColor("yellow");
      } else if (riskLevel == "Alto") {
        setScoreColor("orange");
      } else if (riskLevel == "Critico") {
        setScoreColor("red");
      } else {
        setScoreColor("red");
      }

      setScore(scoreSections);
      setTotalScore(totalScore);
      setAnalysisScore(resultAnalysisScore);
      setAnalysis(resultAnalysis);
      setMdr(resultMdr);
      setPricingPartner(result);
      setBigData(
        resultAnalysis?.bigData?.bigData?.bigData?.dadosCadastrais &&
          JSON.parse(resultAnalysis?.bigData?.bigData?.bigData?.dadosCadastrais)
      );
    } catch (error) {
      console.log(error);
      toast.error("Erro ao listar dados");
    }
  }, []);

  React.useEffect(() => {
    listProposal();
  }, []);


  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);  };


  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              RESUMO CLIENTE - {bigData?.Result[0]?.BasicData?.OfficialName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 15,
                flexGrow: 1,
                textAlign: "center",
                mt: 5,
              }}
            >
              Pré Analise Risco
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#000536" }}>
                    <TableCell
                      align="center"
                      colSpan={2}
                      sx={{ color: "#ffffff" }}
                    >
                      Classificacao de Cliente em Categoria
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={3}
                      sx={{ color: "#ffffff" }}
                    >
                      Criterio de Decisao Acoes Aplicaveis
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Grau de Risco</TableCell>
                    <TableCell align="left">Pontuacao</TableCell>
                    <TableCell align="left">Acao</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {score?.map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{ backgroundColor: row?.color }}
                        component="th"
                        scope="row"
                      >
                        {row?.riskLevel}
                      </TableCell>
                      <TableCell align="left">
                        {row?.minScore} até {row?.maxScore}
                      </TableCell>
                      <TableCell align="left">{row?.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                p: 5,
                mb: 5,
                ml: 5,
                height: 310,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="item-hover"
            >
              <Box
                sx={{
                  backgroundColor: scoreColor,
                  textAlign: "center",

                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Typography sx={{ fontSize: 100, width: "100%" }}>
                  {totalScore}
                </Typography>
              </Box>
            </Card>
          </Grid>
         
        
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#000536",
              borderRadius: "10px",
            }}
            variant="contained"
            size="medium"
            onClick={handleBack}
          >
            Voltar
          </Button>
        </div>
      </Card>
      <Hover loading={loading} />
     
    </Box>
  );
};

export default ComercialSummary;
