import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import dayjs from "dayjs";
import uuid4 from "uuid4";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { DataTable } from "elements/dataTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { plain } from "shared/utils/plain";
import { formatDocument } from "shared/utils/formatDocument";

export const EmpilhamentoCessao = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [tokenPagination, setTokenPagination] = useState("");

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState("");
  const handleChangeCurrentPaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
  };

  const [cardIssuers, setCardIssuers] = useState([]);
  const [currentCardIssuer, setCurrentCardIssuer] = useState("");
  const handleChangeCurrentCardIssuer = (event) => {
    setCurrentCardIssuer(event.target.value);
  };

  const [assignees, setAssignees] = useState([]);

  const [dateFrom, setDateFrom] = useState(dayjs());
  const handleChangeDateFrom = (newValue) => {
    setDateFrom(dayjs(newValue));
  };

  const [dateTo, setDateTo] = React.useState(dayjs()); // Data Inicio
  const handleChangeDate = (newValue) => {
    setDateTo(newValue);
  };

  const [data, setData] = useState([]);

  const columns = [
    {
      field: "x1",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cessionario",
      headerName: "Cessionário",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nomeCedente",
      headerName: "Nome Do Cedente",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cnpjCedente",
      headerName: "CNPJ Do Cedente",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "x2",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bandeira",
      headerName: "Bandeira",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dataEmissao",
      headerName: "Dt Emissão",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "valor",
      headerName: "Valor Titulo",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        let valor = value / 100;

        return valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "x3",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dataLiquidacao",
      headerName: "Dt Vencto",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).utc().format("DD-MM-YYYY");
      },
    },
    {
      field: "x4",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "x5",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "x6",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "prazo",
      headerName: "Prazo",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nomeSacado",
      headerName: "Nome do Sacado",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cnpjSacado",
      headerName: "CNPJ/CPF Sacado",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ica",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "idContrato",
      headerName: "Registro do Contrato",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "endereco",
      headerName: "Endereço",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bairro",
      headerName: "Bairro",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cidade",
      headerName: "Cidade",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "uf",
      headerName: "UF",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cep",
      headerName: "CEP",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "x7",
      headerName: "x",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const fetchData = async (aUrl, aParams = {}) => {
    try {
      setLoading(true);
      // token do ad
      const token = await getToken();

      const params = {
        method: "GET",
        url: aUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: aParams,
      };
      const res = await axios(params);
      return res.data.body;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchContracts = async (aToken, aPage = 0) => {
    try {
      setLoading(true);
      let array = [];

      // Realiza a chamada à API
      const response = await fetch(
        process.env.REACT_APP_CLIENT_BTG_SERVER + "/bigquery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${aToken}`,
          },
          body: JSON.stringify({
            entity: "listCreditReservation" + process.env.REACT_APP_ENVIROMENT,
            filterCreditReservation: "dataLiquidacao",
            dataInicial: dateFrom.format("'YYYY-MM-DD'"),
            dataFinal: dateTo.format("'YYYY-MM-DDT23:59:59[Z]'"),
            paymentNetwork: !currentPaymentNetwork
              ? "'%'"
              : `"${currentPaymentNetwork}"`,
          }),
        }
      );

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error("Erro ao obter dados");
      }

      // Cria um reader para o corpo da resposta
      const reader = response.body.getReader();

      let ArrayGlobalParsed = [];
      let newArray = [];

      // Função para processar os chunks de dados
      const processChunks = async () => {
        let result;
        const decoder = new TextDecoder("utf-8");
        let combinedChunks = "";

        while (true) {
          result = await reader.read();
          console.log(result);
          if (result.done) break;

          const chunk = decoder.decode(result.value);
          combinedChunks += chunk;
          // Processa os dados recebidos
        }

        console.log(combinedChunks);
        console.log(typeof combinedChunks);
        console.log(JSON.parse(`[${combinedChunks}]`));
        ArrayGlobalParsed = JSON.parse(`[${combinedChunks}]`);
        // Atualiza o estado com os novos dados
      };

      // Processa os chunks de dados
      await processChunks();

      for (array of ArrayGlobalParsed) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
          newArray.push(array[i]);
        }
      }
      console.log("array final:", newArray);

      const d = newArray.map((item) => {
        return {
          id: uuid4(),
          idCessionario: item.assigneeAccountId,
          cessionario: getName(item.assigneeAccountId, assignees),
          dataVencimento: item.reservationDate,
          valorTitulo: item.amount,
          bandeira: getName(item.paymentNetwork, paymentNetworks),
          idEmissor: item.cardIssuerId,
          ica: item.cardIssuerId,
          nomeSacado: getName(item.cardIssuerId, cardIssuers),
          cnpjSacado: formatDocument(
            getCardIssuerCNPJ(item.cardIssuerId, cardIssuers)
          ),
          idContrato: item.pactualId,
          status: item.status,
          dataEmissao: item.createTimestamp,
          codErro: item.errorCode,
          descErro: item.errorDescription,
        };
      });

      return newArray;
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // na documentação não traz nada sobre paginação, nem no retorno...
  const fetchCardIssuers = async (aPaymentNetwork) => {
    try {
      setLoading(true);
      console.log(aPaymentNetwork);

      /* const result = await fetchData(
                'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
                {
                paymentNetwork: aPaymentNetwork,
                }
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/card-issuer/search",
        {
          paymentNetwork: aPaymentNetwork,
        }
      );

      const d = result.map((item) => {
        return {
          value: item.cardIssuerId,
          label: item.name,
          cnpjEmissor: item.taxId,
        };
      });

      console.log(d);

      return d;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      /* const result = await fetchData(
                'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks'
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      /* const result = await fetchData(
              'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees'
            ); */
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees"
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      console.log(d);
      setAssignees(d);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  // const handleSearch = async () => {
  //     setData([])
  //     // Pega o token do login para passar na chamada
  //     const token = await getToken();
  //     /* const saldo = await handleSearchSaldo(); */
  //     const resultFetchContracts = await fetchContracts(token);

  //     let arrayResultados = []

  //     const enrichedResult = resultFetchContracts.map(dataContratos => {
  //         const key = `${dataContratos.cessionario}-${dataContratos.emissor}`;
  //         const findBateu = cardIssuers.find(dataEmissor =>
  //         dataEmissor.value === dataContratos.ica
  //         );

  //          console.log(findBateu)
  //         if (findBateu) {
  //             let novoObjetoData = {}
  //             novoObjetoData.idCessionario = dataContratos.assigneeId,
  //             novoObjetoData.cessionario = dataContratos.cessionario,
  //             novoObjetoData.dataVencimento = dataContratos.dataVencimento,
  //             novoObjetoData.valorTitulo = dataContratos.valorTitulo,
  //             novoObjetoData.bandeira = dataContratos.bandeira,
  //             novoObjetoData.idEmissor = dataContratos.idEmissor,
  //             novoObjetoData.ica = dataContratos.ica,
  //             novoObjetoData.cnpjSacado = formatDocument(findBateu.cnpjEmissor),
  //             novoObjetoData.nomeSacado = findBateu.label,
  //             novoObjetoData.idContrato = dataContratos.idContrato,
  //             novoObjetoData.status = dataContratos.status,
  //             novoObjetoData.dataEmissao = dataContratos.dataEmissao,
  //             console.log("As propriedades batem:", dataContratos)
  //             arrayResultados.push(novoObjetoData)
  //         } else {
  //             console.log('Propriedades não batem para:', dataContratos);
  //             arrayResultados.push(dataContratos)
  //         }
  //         return dataContratos
  //     });
  //     console.log(arrayResultados)
  //     const dadosFiltrados = arrayResultados.filter(objeto => objeto.status === "COMPLETED" || objeto.status === "CREATED");
  //     setData(dadosFiltrados);
  // };

  const handleSearch = async () => {
    setData([]);
    // Pega o token do login para passar na chamada
    const token = await getToken();
    /* const saldo = await handleSearchSaldo(); */
    const resultFetchContracts = await fetchContracts(token);

    function filterCancelled() {
      // Primeiro, encontrar todos os pactualId com status "CANCELLED"
      const cancelledIds = new Set(
        resultFetchContracts
          .filter((item) => item.status === "CANCELLED")
          .map((item) => item.pactualId)
      );

      // Agora, filtrar a array para remover todos os objetos com pactualId que está em cancelledIds
      return resultFetchContracts.filter(
        (item) => !cancelledIds.has(item.pactualId)
      );
    }

    const result = filterCancelled();

    console.log("RESULT COMPLETO:", resultFetchContracts);
    console.log("RESULT SEM OS CANCELADOS", result);

    const dadosFiltrados = result.filter(
      (objeto) => objeto.status === "COMPLETED" || objeto.status === "CREATED"
    );
    setData(dadosFiltrados);
  };

  function compare(a, b) {
    if (plain(a.label) < plain(b.label)) {
      return -1;
    }
    if (plain(a.label) > plain(b.label)) {
      return 1;
    }
    return 0;
  }

  const getCardIssuerName = (id) => {
    for (const i of cardIssuers) {
      if (id === i.value) {
        return i.label;
      }
    }
    return "";
  };

  const getCardIssuerCNPJ = (id) => {
    for (const i of cardIssuers) {
      if (id === i.value) {
        return i.cnpjEmissor;
      }
    }
    return "";
  };

  const getName = (aId, aList) => {
    for (const a of aList) {
      if (a.value === aId) {
        return a.label;
      }
    }
    return "";
  };

  const updateCardIssuers = async () => {
    try {
      // // se não há dados, não precisa puxar os emissores
      // if (data.length === 0) {
      //   return;
      // }

      // // se tem bandeira selecionada, então puxa os emissores dessa bandeira
      // if (currentPaymentNetwork.length > 0) {
      //   const d = await fetchCardIssuers(currentPaymentNetwork);
      //   setCardIssuers(d);
      // } else {
      // se não seleciona bandeira

      // Tem que trazer a lista completa de todos os emissores
      const issuers = [];
      for (const pn of paymentNetworks) {
        const d = await fetchCardIssuers(pn.value);

        // Para não adicionar os repetidos
        for (const i of d) {
          let found = false;
          for (const issuer of issuers) {
            if (i.value === issuer.value) {
              found = true;
            }
          }
          if (!found) {
            issuers.push(i);
          }
        }
      }
      setCardIssuers(issuers);
      console.log(issuers);
      // }
    } catch (error) {
      console.log(error.response);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      }
    }
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // atualiza lista de emissores quando busca dados
  useEffect(() => {
    updateCardIssuers();
  }, [paymentNetworks]);

  useEffect(() => {
    setCurrentCardIssuer("");
  }, [cardIssuers]);

  // pega lista de bandeiras
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 220 } }}
              label={"De (Data de Liquidação)*"}
              value={dateFrom}
              onChange={handleChangeDateFrom}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: "0.1em", width: 120 } }}
              inputFormat="DD/MM/YYYY"
              label="Até (Data de Liquidação)*"
              value={dateTo}
              onChange={(value) => handleChangeDate(value)}
              slotProps={{ textField: { variant: "outlined" } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Bandeira</InputLabel>
          <Select
            value={currentPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangeCurrentPaymentNetwork(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {paymentNetworks.sort(compare).map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Consultar
          </Button>
        </FormControl>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <DataTable
        rows={
          data.map((item, index) => ({
            ...item,
            cessionario: item.assigneeAccountId,
            nomeCedente: "ENTREPAY INSTITUICAO DE PAGAMENTOS S.A",
            cnpjCedente: "17887874/0001-05",
            dataLiquidacao: item.reservationDate,
            valor: item.amount,
            bandeira: getName(item.paymentNetwork, paymentNetworks),
            nomeSacado: getName(item.cardIssuerId, cardIssuers),
            cnpjSacado: formatDocument(
              getCardIssuerCNPJ(item.cardIssuerId, cardIssuers)
            ),
            status: item.status,
            dataRegistro: item.createTimestamp,
            data: item.data,
            provisionado: item.provisionado,
            aberto: item.aberto,
            liquidado: item.liquidado,
            codErro: item.errorCode,
            descErro: item.errorDescription,
            ica: item.cardIssuerId,
            idContrato: item.pactualId,
            dataEmissao: item.createTimestamp,
          })) ?? []
        }
        getRowId={(row) => generateRandomId()}
        columns={columns}
        columnVisibilityModel={{
          x1: false,
          x2: false,
          x3: false,
          x4: false,
          x5: false,
          x6: false,
          x7: false,
          endereco: false,
          bairro: false,
          cidade: false,
          uf: false,
          cep: false,
          prazo: false,
        }}
      />
    </Box>
  );
};
