import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const columns = [
  { id: "Perguntas", label: "Perguntas", minWidth: 170 },
  { id: "Departamento", label: "Departamento", minWidth: 170 },
  { id: "Ordem", label: "Ordem", minWidth: 170 },
  { id: "Status", label: "Status", minWidth: 170 },
  { id: "Acoes", label: "Acoes", minWidth: 170 },
  { id: "", label: "", minWidth: 170 },
];

const ListQuestions = () => {
  const [questionsList, setQuestionsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const listQuestions = async function () {
    const token = await getToken();
    try {
      setLoading(true);

      const result = await request(
        process.env.REACT_APP_API_URL + `/questions`,
        "get",
        token
      );

      setQuestionsList(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (id, status) => {
    setLoading(true);
    const token = await getToken();
    try {
      await request(
        process.env.REACT_APP_API_URL + `/questions/${id}`,
        "put",
        token,
        {
          active: status,
        }
      );
      toast.success("Status atualizado com sucesso.");
      listQuestions();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao atualizar status");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listQuestions();
  }, []);

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Consultar Perguntas
            </Typography>
          </Toolbar>
        </AppBar>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#000536",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questionsList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                      <TableCell align="center">{row?.question}</TableCell>
                      <TableCell align="center">{row?.clientType}</TableCell>
                      <TableCell align="center">{row?.questionOrder}</TableCell>
                      <TableCell align="center">
                        {row?.active === true ? "Ativado" : "Desativado"}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Button
                          onClick={() => handleChangeStatus(row?.id, true)}
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                        >
                          Ativado
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Button
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                          onClick={() => handleChangeStatus(row?.id, false)}
                        >
                          Desativado
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          s
          component="div"
          count={questionsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default ListQuestions;
