import React, { useEffect, useState } from "react";
import axios from "axios";

import { Box } from "@mui/material";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import { DataTable } from "elements/dataTable";

export const ConsultaAccountId = () => {
  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [valuePactualId, setValuePactualId] = useState("");
  const handleChangeValuePactualId = (event) => {
    console.log(event.target.value);
    setValuePactualId(event.target.value);
  };

  const [data, setData] = useState([]);

  const columns = [
    {
      field: "account",
      headerName: "account",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountId",
      headerName: "accountId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bank",
      headerName: "bank",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "branch",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "clientId",
      headerName: "clientId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createTimestamp",
      headerName: "createTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "defaultAccount",
      headerName: "defaultAccount",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastUpdateTimestamp",
      headerName: "lastUpdateTimestamp",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      field: "name",
      headerName: "name",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "personType",
      headerName: "personType",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "status",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "taxId",
      headerName: "taxId",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const fetchData = async (aUrl = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      //Alteração para a correção da consulta
      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG + `/cm/account`
        /* `https://backend.entrepay.com.br/btg/cm/account` */
      );
      console.log(result);
      setData(result.items);
    } catch (error) {
      console.log(error.message);
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  /** useEffect */
  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        {/* <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: '90%',
            }}
            label="Pactual Id"
            variant="outlined"
            value={valuePactualId}
            onChange={handleChangeValuePactualId}
          />
        </FormControl>
 */}
        {/* <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              width: '100%',
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
          >
            Consultar
          </Button>
        </FormControl> */}
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <DataTable
        rows={
          data.map((item, index) => ({
            ...item,
            account: item.account,
            accountId: item.accountId,
            accountType: item.accountType,
            bank: item.bank,
            branch: item.branch,
            clientId: item.clientId,
            createTimestamp: item.createTimestamp,
            defaultAccount: item.defaultAccount,
            lastUpdateTimestamp: item.lastUpdateTimestamp,
            name: item.name,
            personType: item.personType,
            status: item.status,
            taxId: item.taxId,
          })) ?? []
        }
        getRowId={(row) => generateRandomId()}
        columns={columns}
      />
    </Box>
  );
};
