import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import { checkCnpj } from "shared/utils/cnpj";
import Modal from "@mui/material/Modal";
import { format, parseISO } from "date-fns";
import { Hover } from "elements/hover";
import formatCnpj from "shared/utils/formatCnpj";
import FormikPatternFormat from "elements/inputs/FormikPatternFormat";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";

const validationSchema = yup.object({
  cnpj: yup
    .string()
    .matches(/^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/, {
      message: <em>CNPJ inválido</em>,
    })
    //  .matches(/^[0-9]+$/)
    .min(14)
    .max(19)
    .test("cnpj", "CNPJ inválido", function (value) {
      return checkCnpj(value);
    }),
  reason: yup.string().required(""),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BlacklistCnpj = () => {
  const [BlacklistCnpj, setBlacklistCnpj] = useState();
  const [blacklist, setBlacklist] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const listBlacklist = async function () {
    const token = await getToken();

    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/blacklist/cnpj`,
        "get",
        token
      );
      setBlacklistCnpj(result);
    } catch (error) {
      console.error(error);
      setBlacklistCnpj([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listBlacklist();
  }, []);

  const handleOnClick = async function (id) {
    confirmAlert({
      title: "Confirme para deletar do blacklist",
      message: "Clique em Sim para deletar CNPJ do blacklist",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const token = await getToken();

            try {
              await request(
                process.env.REACT_APP_API_URL + `/blacklist/cnpj/${id}`,
                "delete",
                token
              );

              toast.success("CNPJ deletado do blacklist!");
              listBlacklist();
            } catch (error) {
              console.error(error);
              toast.error("Erro ao deletar CNPJ do blacklist");
            }
          },
        },
        {
          label: "Não",
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
          padding: { xs: 8, md: 12 },
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Blacklist CNPJ
              </Typography>
            </Toolbar>
          </AppBar>
          <Button
            variant="contained"
            component="label"
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              padding: "9px 12px",
              lineHeight: 1,
              width: 96,
              fontWeight: Fonts.MEDIUM,
              backgroundColor: "#000536",
            }}
            onClick={() => setOpen(true)}
          >
            Adicionar
          </Button>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">CNPJ</TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Data</TableCell>
                  <TableCell align="center">Motivo</TableCell>
                  {/* <TableCell align="center">Ban</TableCell> */}
                  <TableCell align="center">Acoes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BlacklistCnpj?.map((blacklist) => {
                  return (
                    <TableRow
                      key={blacklist.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {" "}
                      <TableCell align="center">
                        {formatCnpj(blacklist?.cnpj?.cnpj)}
                      </TableCell>
                      <TableCell align="center">
                        {blacklist?.cnpj?.name}
                      </TableCell>
                      <TableCell align="center">
                        {format(
                          parseISO(blacklist?.banDate),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell align="center">{blacklist?.reason}</TableCell>
                      {/* <TableCell align="center">
                        {blacklist?.banUntil}
                      </TableCell> */}
                      <TableCell align="center">
                        {/* <Button
                            variant="contained"
                            component="label"
                            sx={{
                              marginRight: 5,
                              marginTop: 2,
                              padding: "9px 12px",
                              lineHeight: 1,
                              width: 96,
                              fontWeight: Fonts.MEDIUM,
                              backgroundColor: "#000536",
                            }}
                          >
                            Editar
                          </Button> */}
                        <Button
                          variant="contained"
                          component="label"
                          sx={{
                            marginRight: 5,
                            marginTop: 2,
                            padding: "9px 12px",
                            lineHeight: 1,
                            width: 96,
                            fontWeight: Fonts.MEDIUM,
                            backgroundColor: "#000536",
                          }}
                          onClick={() => handleOnClick(blacklist.id)}
                        >
                          Deletar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Hover loading={loading} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 id="parent-modal-title">Adicionar ao blacklist</h2>
          <Formik
            validateOnChange={true}
            initialValues={{
              // name: "",
              cnpj: "",
              reason: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              try {
                const result = await axios.post(
                  process.env.REACT_APP_API_URL + `/blacklist/cnpj`,
                  {
                    ...data,
                    cnpj: data.cnpj.replace(/[^\d]+/g, ""),
                  }
                );

                toast.success("CNPJ adicionado ao blacklist!");
                listBlacklist();
                setOpen(false);
              } catch (error) {
                console.error(error);
                toast.error("Erro ao adicionar ao blacklist.");
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form
                sx={{
                  textAlign: "left",
                }}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <FormikPatternFormat
                    required
                    format="##.###.###/####-##"
                    allowEmptyFormatting
                    mask="_"
                    label="Cnpj"
                    name="cnpj"
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <AppTextField
                    label="Motivo"
                    name="reason"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    backgroundColor: "#000536",
                    height: 44,
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default BlacklistCnpj;
