import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { NumericFormat } from "react-number-format";
import { useAuthMethod } from "hooks/AuthHooks";
import { DataTable } from "elements/dataTable";

export const RegistroLimite = () => {
  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [quantidadeLimite, setQuantidadeLimite] = useState(0);

  const [filterCardIssuer, setFilterCardIssuer] = useState("");
  const handleChangeFilterCardIssuer = (e) => {
    setFilterCardIssuer(e.target.value.toLowerCase());
  };

  const [createDate, setCreateDate] = useState(dayjs());
  const handleChangeDate = (newValue) => {
    setCreateDate(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  /* const [selectedPaymentNetwork, setSelectedPaymentNetwork] = useState('');
    const handleChangePaymentNetwork = (event) => {
      setSelectedPaymentNetwork(event.target.value);
    }; */

  const [objetoLimites, setObjetosLimites] = useState([]);

  const [observation, setObservation] = useState("");
  const handleChangeObservation = (event) => {
    console.log(event.target.value);
    setObservation(event.target.value);
  };

  const [issuers, setIssuers] = useState([]);
  const [selectedCardIssuer, setSelectedCardIssuer] = useState("");
  const handleChangeCardIssuer = (event) => {
    console.log(event.target.value);
    setSelectedCardIssuer(event.target.value);
  };

  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const handleChangeAssignee = (event) => {
    console.log(event.target.value);
    setSelectedAssignee(event.target.value);
  };

  const columns = [
    {
      field: "cessionario",
      headerName: "Cessionário",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ica",
      headerName: "Ica",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "emissor",
      headerName: "Emissor",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "limite",
      headerName: "Limite",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value == null) {
          return "";
        }
        return value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "observacao",
      headerName: "Observação",
      width: "100%",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={() => handleExcluirLimite(params.row, params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== "AMEX";
  }

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        process.env.REACT_APP_CLIENT_BTG +
          "/tr/depositary-bank/payment-networks"
        /* 'https://backend.entrepay.com.br/btg/tr/depositary-bank/payment-networks' */
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        /* 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees' */
        process.env.REACT_APP_CLIENT_BTG + "/tr/depositary-bank/assignees"
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      setAssignees(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores da bandeira selecionada
  const fetchCardcardIssuers = async () => {
    try {
      let responseGetCardIssuers = [];
      setLoading(true);

      for (let i = 0; i < paymentNetworks.length; i++) {
        console.log("payment network varrido:", paymentNetworks[i]);
        /* const result = await fetchData(
                  'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
                  {
                    paymentNetwork: paymentNetworks[i].value,
                  }
                ); */
        const result = await fetchData(
          process.env.REACT_APP_CLIENT_BTG +
            "/tr/depositary-bank/card-issuer/search",
          {
            paymentNetwork: paymentNetworks[i].value,
          }
        );
        responseGetCardIssuers.push(result);
        console.log(responseGetCardIssuers);
      }
      for (let i = 0; i < responseGetCardIssuers.length; i++) {
        const d = responseGetCardIssuers[i].map((item) => {
          return {
            value: item?.cardIssuerId ?? "",
            label: `${item?.name ?? ""} - ${item?.paymentNetwork ?? ""}`,
          };
        });

        // Ordenar o array d em ordem alfabetica
        d.sort((a, b) => a.label.localeCompare(b.label));

        setIssuers((prevCardIssuers) => [...prevCardIssuers, ...d]);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      setIssuers([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: "GET",
      url: aUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = "";
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + " ";
      }
      throw msg;
    }
  };

  const handleRegister = async (event) => {
    if (!selectedCardIssuer || !selectedAssignee) {
      toast.error("Campos invalidos");
    } else {
      try {
        setLoading(true);
        const params = {
          method: "POST",
          url: process.env.REACT_APP_CLIENT_CLOUND + "/registrolimite",
          /* url: 'https://southamerica-east1-entrepayments-system-prd.cloudfunctions.net/btg-planilhaCessionario/registrolimitehomolog', */
          headers: {
            "Content-Type": "application/json",
          },
          data: objetoLimites /* {
              createTimestamp: createDate.format('YYYY-MM-DD'),
              bandeira: selectedPaymentNetwork,
              emissor: selectedCardIssuer,
              cessionario: selectedAssignee,
              limite: quantidadeLimite,
          }, */,
        };
        const res = await axios(params);
        if (res.status === 200) {
          toast.success(`Dados inseridos com sucesso!`);
          setObjetosLimites([]);
        }
      } catch (error) {
        console.log(error.response);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAdicionarLimite = async (event) => {
    let newObjetoLimite = {
      id: generateRandomId(),
      cessionario: selectedAssignee,
      emissor: selectedCardIssuer,
      limite: quantidadeLimite,
      observacao: observation,
    };

    // Verifique se já existe um objeto com o mesmo cessionario e emissor
    const index = objetoLimites.findIndex(
      (item) =>
        item.cessionario ===
        selectedAssignee /* && item.emissor === selectedCardIssuer */
    );

    if (index !== -1) {
      // Se já existe, substitua o objeto antigo pelo novo
      setObjetosLimites((prevObjetosLimites) => {
        const newObjetosLimites = [...prevObjetosLimites];
        newObjetosLimites[index] = newObjetoLimite;
        return newObjetosLimites;
      });
    } else {
      // Caso contrário, adicione o novo objeto à array
      setObjetosLimites((prevObjetosLimites) => [
        ...prevObjetosLimites,
        newObjetoLimite,
      ]);
    }
    console.log(objetoLimites);
  };

  // Função para excluir uma linha da tabela
  const handleExcluirLimite = (rowClicada, idRow) => {
    console.log("ROW:", rowClicada);
    setObjetosLimites((prevData) => {
      const novaArray = prevData.filter((item) => item.id !== idRow);
      return novaArray;
    });
  };

  const getName = (aId, aList) => {
    for (const a of aList) {
      if (a.value === aId) {
        return a.label;
      }
    }
    return "";
  };

  function generateRandomId() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const filteredIssuers = issuers.filter((item) =>
    item.label.toLowerCase().includes(filterCardIssuer)
  );

  /*** useEffect */
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardcardIssuers();
  }, [paymentNetworks]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        {/* <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Bandeira</InputLabel>
          <Select
            required
            value={selectedPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: '90%',
            }}
          >
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Cessionário</InputLabel>
          <Select
            required
            value={selectedAssignee}
            label="Emissor"
            onChange={(e) => handleChangeAssignee(e)}
            sx={{
              width: "90%",
            }}
          >
            {assignees.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Emissor</InputLabel>
          <Select
            required
            value={selectedCardIssuer}
            label="Emissor"
            onChange={handleChangeCardIssuer}
            sx={{
              width: "90%",
            }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {filteredIssuers.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "90%",
            }}
            label="Filtro Emissor"
            variant="outlined"
            value={filterCardIssuer}
            onChange={handleChangeFilterCardIssuer}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="Limite"
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            value={quantidadeLimite}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix="R$ "
            sx={{
              width: "90%",
            }}
            onValueChange={(values, sourceInfo) => {
              setQuantidadeLimite(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "90%",
            }}
            label="Observação"
            variant="outlined"
            value={observation}
            onChange={handleChangeObservation}
          />
        </FormControl>

        {/* <FormControl sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              required
              inputProps={{ style: { height: '0.1em', width: 220 } }}
              label={'Data'}
              value={createDate}
              onChange={handleChangeDate}
              slotProps={{ textField: { variant: 'outlined' } }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </FormControl> */}

        <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleAdicionarLimite}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Adicionar
          </Button>
        </FormControl>
      </Box>
      {objetoLimites.length === 0 ? null : (
        <Button
          variant="contained"
          onClick={handleRegister}
          sx={{
            m: 1,
            width: "100%",
            color: "primary.contrastText",
            bgcolor: "primary.main",
            "&:hover": { backgroundColor: "primary.light" },
          }}
        >
          Registrar
        </Button>
      )}
      <Box
        sx={{
          marginTop: "20px",
          borderTop: "solid",
          width: "100%",
        }}
      ></Box>
      <DataTable
        rows={
          objetoLimites.map((item, index) => ({
            ...item,
            id: item.id,
            cessionario: getName(item.cessionario, assignees),
            ica: item.emissor === "Todos" ? item.emissor : item.emissor,
            emissor:
              item.emissor === "Todos"
                ? item.emissor
                : getName(item.emissor, issuers),
            limite: item.limite,
            observacao: item.observacao,
          })) ?? []
        }
        columns={columns}
      />
    </Box>
  );
};
