import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Fonts } from "shared/constants/AppEnums";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const MdrPreview = ({ data, onCancel, onSave }) => {
  console.log("AAA", data);
  const columns = [
    { field: "Subclasse", headerName: "Subclasse" },
    { field: "MCC", headerName: "MCC" },
    { field: "TPV", headerName: "TPV" },
    { field: `TICKET MÉDIO`, headerName: "TM" },
    { field: "ANTECIPAÇÃO", headerName: "ANTECIPAÇÃO" },

    {
      field: "VisaDebito",
      headerName: "VisaDebito",
      valueFormatter: (params) => ` ${params.value * 100} %`,
    },
    {
      field: "VisaCredito a Vista",
      headerName: "VisaCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "VisaParc. 2 a 6",
      headerName: "VisaParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "VisaParc. 7 a 12",
      headerName: "VisaParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "VisaParc. Emissor",
      headerName: "VisaParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },

    {
      field: "MasterDebito",
      headerName: "MasterDebito",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "MasterCredito a Vista",
      headerName: "MasterCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "MasterParc. 2 a 6",
      headerName: "MasterParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "MasterParc. 7 a 12",
      headerName: "MasterParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "MasterParc. Emissor",
      headerName: "MasterParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },

    {
      field: "EloDebito",
      headerName: "EloDebito",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "EloCredito a Vista",
      headerName: "EloCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "EloParc. 2 a 6",
      headerName: "EloParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "EloParc. 7 a 12",
      headerName: "EloParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "EloParc. Emissor",
      headerName: "EloParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },

    {
      field: "HiperCredito a Vista",
      headerName: "HiperCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "HiperParc. 2 a 6",
      headerName: "HiperParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "HiperParc. 7 a 12",
      headerName: "HiperParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "HiperParc. Emissor",
      headerName: "HiperParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },

    {
      field: "CabalDebito",
      headerName: "CabalDebito",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "CabalCredito a Vista",
      headerName: "CabalCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "CabalParc. 2 a 6",
      headerName: "CabalParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "CabalParc. 7 a 12",
      headerName: "CabalParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "CabalParc. Emissor",
      headerName: "CabalParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },

    {
      field: "AmexCredito a Vista",
      headerName: "AmexCredito a Vista",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "AmexParc. 2 a 6",
      headerName: "AmexParc. 2 a 6",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "AmexParc. 7 a 12",
      headerName: "AmexParc. 7 a 12",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
    {
      field: "AmexParc. Emissor",
      headerName: "AmexParc. Emissor",
      valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %`,
    },
  ];

  const onClickSave = () => {
    const save = [];

    const convertData = (tpv, tm, prepayment) => {
      const data = {
        ticketMedioFrom: 0,
        ticketMedioTo: 0,
        tpvFrom: 0,
        tpvTo: 0,
        prepaymentMoreThan50: 0,
      };
      if (tpv === "até 50 mil") {
        data.tpvFrom = 0;
        data.tpvTo = 50_000;
      } else if (tpv === "50 mil a 250 mil") {
        data.tpvFrom = 50_001;
        data.tpvTo = 250_000;
      } else if (tpv === "250 mil a 1MM") {
        data.tpvFrom = 250_001;
        data.tpvTo = 1_000_000;
      } else if (tpv === "1MM a 5MM") {
        data.tpvFrom = 1_000_001;
        data.tpvTo = 5_000_000;
      } else if (tpv === "5MM a 10MM") {
        data.tpvFrom = 5_000_001;
        data.tpvTo = 10_000_000;
      }

      if (tm === "até 50,00") {
        data.ticketMedioFrom = 0;
        data.ticketMedioTo = 50;
      } else if (tm === "50,00 a 100,00") {
        data.ticketMedioFrom = 51;
        data.ticketMedioTo = 100;
      } else if (tm === "100,00 a 250,00") {
        data.ticketMedioFrom = 101;
        data.ticketMedioTo = 250;
      } else if (tm === "acima de 250,00") {
        data.ticketMedioFrom = 251;
        data.ticketMedioTo = 0;
      }

      if (prepayment === "abaixo de 50% do volume") {
        data.prepaymentMoreThan50 = 0;
      } else {
        data.prepaymentMoreThan50 = 1;
      }

      return data;
    };

    const cardBrand = {
      visa: [
        {
          product: "Debito",
          field: "VisaDebito",
        },
        {
          product: "A Vista",
          field: "VisaCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "VisaParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "VisaParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "VisaParc. Emissor",
        },
      ],
      mastercard: [
        {
          product: "Debito",
          field: "MasterDebito",
        },
        {
          product: "A Vista",
          field: "MasterCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "MasterParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "MasterParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "MasterParc. Emissor",
        },
      ],
      elo: [
        {
          product: "Debito",
          field: "EloDebito",
        },
        {
          product: "A Vista",
          field: "EloCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "EloParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "EloParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "EloParc. Emissor",
        },
      ],
      hipercard: [
        {
          product: "A Vista",
          field: "HiperCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "HiperParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "HiperParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "HiperParc. Emissor",
        },
      ],
      amex: [
        {
          product: "A Vista",
          field: "AmexCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "AmexParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "AmexParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "AmexParc. Emissor",
        },
      ],
      cabal: [
        {
          product: "Debito",
          field: "CabalDebito",
        },
        {
          product: "A Vista",
          field: "CabalCredito a Vista",
        },
        {
          product: "Parcelado 2 a 6",
          field: "CabalParc. 2 a 6",
        },
        {
          product: "Parcelado 7 a 12",
          field: "CabalParc. 7 a 12",
        },
        {
          product: "Parcelado Emissor",
          field: "CabalParc. Emissor",
        },
      ],
    };
    const cache = {};
    for (const value of data) {
      const response = convertData(
        value.TPV,
        value[`TICKET MÉDIO`],
        value[`ANTECIPAÇÃO`]
      );
      const cardBrands = Object.keys(cardBrand);
      for (const cardBrandValue of cardBrands) {
        for (const cardData of cardBrand[cardBrandValue]) {
          const cacheKey = `${value.MCC}-${cardBrandValue}-${cardData.product}-${response.ticketMedioFrom}-${response.ticketMedioTo}-${response.tpvFrom}-${response.tpvTo}-${response.prepaymentMoreThan50}`;
          if (!cache[cacheKey]) {
            const mdr = {
              mcc: value.MCC,
              cnae: value.Subclasse,

              ticketMedioFrom: response.ticketMedioFrom,
              ticketMedioTo: response.ticketMedioTo,
              tpvFrom: response.tpvFrom,
              tpvTo: response.tpvTo,
              prepaymentMoreThan50: response.prepaymentMoreThan50,
              cardBrand: cardBrandValue,
              product: cardData.product,
              mdr: Number((value[cardData.field] * 100).toFixed(2)),
            };
            save.push(mdr);
            cache[cacheKey] = true;
          }
        }
      }
    }
    onSave(save);
  };
  return (
    <Box>
      <Box sx={{ justifyContent: "end", display: "flex", mb: 5 }}>
        <Button
          onClick={onCancel}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Salvar
        </Button>
      </Box>
      <Box style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[30, 60, 100]}
          checkboxSelection={false}
          getRowId={(row) => row["__rowNum__"]}
        />
      </Box>
    </Box>
  );
};

MdrPreview.defaultProps = {
  data: [],
};
MdrPreview.propTypes = {
  data: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default MdrPreview;
