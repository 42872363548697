import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import React, { useState, useCallback } from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Icon, Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import { BsUpload, BsFileSpreadsheet } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import CollapsibleTable from "../../../../elements/portfolioManagementColapsibleTable";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

const PortfolioManagement = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [data, setData] = useState([]);

  const { getToken } = useAuthMethod();
  const listFiles = useCallback(async () => {
    setLoading(true);
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/portfolio_management/files`,
        "get",
        token
      );

      console.log("result", result);
      setData(result);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao listar dados");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    listFiles();
  }, []);

  async function handleUpload() {
    if (!selectedFile) {
      errorToast("Nenhum arquivo selecionado", "");
      return;
    }

    try {
      const formData = new FormData();
      const token = await getToken();
      formData.append("file", selectedFile);

      const { data } = await request(
        process.env.REACT_APP_API_URL + `/portfolio_management/files`,
        "post",
        token,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
            );
            setUploadProgress(progress);
          },
        }
      );
      clear();
      console.debug(data);
    } catch (error) {
      const data = error.response?.data;
      console.error(data);
      errorToast(
        "Erro ao enviar arquivo",
        "Verifique se o arquivo está no formato correto."
      );
      clear();
    }
    await listFiles();
  }

  function clear() {
    setSelectedFile(null);
    setUploadProgress(0);
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        setSelectedFile(file);
      } catch (e) {
        errorToast("Erro ao selecionar planilha", e.message);
      }
    };
    reader.readAsArrayBuffer(file);
  });

  function errorToast(title, message) {
    toast.error(
      <Box>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{message}</Typography>
      </Box>
    );
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
        ".xlsm",
      ],
    },
    onError: (error) => {
      console.error(error);
    },
    onDropRejected: ([
      {
        file,
        errors: [{ code }],
      },
    ]) => {
      if (code.toLowerCase().includes("file-invalid-type")) {
        errorToast(
          `Erro ao enviar arquivo ${file.name}`,
          `A extensão ${file.name.split(".").pop()} não é suportada no momento!`
        );
      }
    },
  });

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Gestão de Carteira
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ mt: 3, fontWeight: 400, fontSize: 15 }}>
            {selectedFile ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  padding: "16px",
                  width: "100%",
                  height: "100%",
                  background: "#FAFAFA",
                  borderRadius: "8px",
                }}
              >
                <Icon as={BsFileSpreadsheet} mr="8px" w="16px" h="16px" />
                <Typography m="0 auto">
                  Arquivo selecionado: {selectedFile.name}
                </Typography>
                <LoadingButton
                  loading={uploading}
                  loadingIndicator="Enviando…"
                  style={{
                    margin: "0 auto",
                    background: "#1D458B",
                    color: "#FEFEFE",
                    padding: "8px",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                  startIcon={<BsUpload />}
                  onClick={handleUpload}
                >
                  Enviar
                </LoadingButton>
              </Box>
            ) : (
              <Box
                {...getRootProps()}
                style={{
                  display: "flex",
                  padding: "16px",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  background: "#FAFAFA",
                }}
              >
                <input {...getInputProps()} />
                <Box>
                  <Typography m="0 auto">
                    Arraste o arquivo em .xls, .xlsx ou .xlsm
                  </Typography>
                  <Button
                    style={{
                      margin: "0 auto",
                      background: "#1D458B",
                      color: "#FEFEFE",
                      padding: "8px",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                    startIcon={<BsUpload />}
                  >
                    Ou clique aqui
                  </Button>
                </Box>
              </Box>
            )}

            <Box marginTop={4}>
              {data.length > 0 ? (
                <CollapsibleTable data={data} />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#FAFAFA",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography m="0 auto">Nenhum arquivo carregado</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default PortfolioManagement;
