import React, { useEffect, useState } from "react";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import uuid4 from "uuid4";
import { formatDocumentInput } from "shared/utils/formatDocument";
import { cnpj, cpf } from "cpf-cnpj-validator";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod, useAuthUser } from "hooks/AuthHooks";
import dayjs from "dayjs";

export const Ted = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const { user } = useAuthUser();

  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [amount, setAmount] = useState(0);

  const [branchFrom, setBranchFrom] = useState(0);

  const [accountFrom, setAccountFrom] = useState(0);

  const [accountTo, setAccountTo] = useState(0);

  const [bankTo, setBankTo] = useState(0);

  const [branchTo, setBranchTo] = useState(0);

  const [nameTo, setNameTo] = useState("");
  const handleChangeNameTo = (e) => {
    setNameTo(e.target.value.toLowerCase());
  };

  const [taxIdTo, setTaxIdTo] = useState("");
  const handleChangeTaxIdTo = (e) => {
    setTaxIdTo(formatDocumentInput(e.target.value));
  };

  const [accountTypeTo, setAccountTypeTo] = useState("");
  const handleChangeAccountTypeTo = (event) => {
    setAccountTypeTo(event.target.value);
  };

  const [nameFrom, setNameFrom] = useState("");
  const handleChangeNameFrom = (e) => {
    setNameFrom(e.target.value.toLowerCase());
  };

  const handleSearch = async (event) => {
    if (
      amount.length <= 0 ||
      amount === 0 ||
      bankTo.length <= 0 ||
      bankTo === 0 ||
      branchFrom.length <= 0 ||
      branchFrom === 0 ||
      accountFrom.length <= 0 ||
      accountFrom === 0
    ) {
      toast.error(
        "Os campos não podem ser vazios ou possuir valores igual a zero!"
      );
    } else if (
      !cpf.isValid(taxIdTo.replace(/\D/g, "")) &&
      !cnpj.isValid(taxIdTo.replace(/\D/g, ""))
    ) {
      toast.error("Documento CNPJ/CPF invalido!");
    } else {
      handleRegisterExternalTransfer();
    }
  };

  const handleRegisterExternalTransfer = async (event) => {
    setLoading(true);
    // token do ad
    const token = await getToken();

    let dataToSend = {
      clientRequestId: uuid4(),
      branchFrom: branchFrom,
      accountFrom: accountFrom,
      accountTo: accountTo,
      accountTypeTo: accountTypeTo,
      amount: amount * 100,
      bankTo: bankTo,
      branchTo: branchTo,
      nameTo: nameTo,
      taxIdTo:
        taxIdTo.length === 11
          ? cpf.format(taxIdTo).replace(/\D/g, "")
          : taxIdTo.length === 14
          ? cnpj.format(taxIdTo).replace(/\D/g, "")
          : null,
      tags: [],
      nameFrom: nameFrom,
      email: user.email,
      createTimestamp: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"),
    };

    const params = {
      method: "POST",
      url: process.env.REACT_APP_CLIENT_BTG_SERVER + "/ted",
      data: JSON.stringify(dataToSend),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios(params)
      .then((res) => {
        console.log(res);
        toast.success(res.data);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error("Erro ao realizar a chamada ao backend");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: 2,
        width: "100%",
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "100%",
            }}
            required
            label="nameTo"
            variant="outlined"
            value={nameTo}
            inputProps={{ maxLength: 50 }}
            onChange={handleChangeNameTo}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "100%",
            }}
            label="nameFrom"
            variant="outlined"
            value={nameFrom}
            inputProps={{ maxLength: 50 }}
            onChange={handleChangeNameFrom}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            sx={{
              width: "100%",
            }}
            required
            label="taxIdTo"
            variant="outlined"
            value={taxIdTo}
            inputProps={{ maxLength: 18 }}
            onChange={handleChangeTaxIdTo}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>accountTypeTo</InputLabel>
          <Select
            required
            value={accountTypeTo}
            label="accountTypeTo"
            onChange={(e) => handleChangeAccountTypeTo(e)}
            sx={{
              width: "100%",
            }}
          >
            <MenuItem value="CC">
              <em>CC</em>
            </MenuItem>
            <MenuItem value="CP">
              <em>CP</em>
            </MenuItem>
            <MenuItem value="PG">
              <em>PG</em>
            </MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            width: 0,
            flexBasis: "100%",
          }}
        />

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="branchFrom"
            customInput={TextField}
            fixedDecimalScale
            value={branchFrom}
            allowNegative={false}
            inputProps={{ maxLength: 4 }}
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setBranchFrom(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="accountFrom"
            customInput={TextField}
            fixedDecimalScale
            value={accountFrom}
            allowNegative={false}
            inputProps={{ maxLength: 4 }}
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setAccountFrom(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="accountTo"
            customInput={TextField}
            fixedDecimalScale
            value={accountTo}
            allowNegative={false}
            inputProps={{ maxLength: 4 }}
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setAccountTo(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="bankTo"
            customInput={TextField}
            fixedDecimalScale
            value={bankTo}
            allowNegative={false}
            inputProps={{ maxLength: 3 }}
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setBankTo(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="branchTo"
            customInput={TextField}
            fixedDecimalScale
            value={branchTo}
            allowNegative={false}
            inputProps={{ maxLength: 4 }}
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setBranchTo(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="Valor"
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            value={amount}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix="R$ "
            sx={{
              width: "100%",
            }}
            onValueChange={(values, sourceInfo) => {
              setAmount(values.floatValue);
            }}
          />
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              width: "100%",
              color: "secondary.contrastText",
              bgcolor: "secondary.main",
              "&:hover": { backgroundColor: "secondary.light" },
            }}
          >
            Registrar
          </Button>
        </FormControl>
      </Box>

      <Box
        sx={{
          borderTop: "solid",
          width: "100%",
        }}
      />
    </Box>
  );
};
